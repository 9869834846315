import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Form, Table } from 'react-bootstrap';
import { confirmAlert } from "react-confirm-alert";
import '../../formBuilder/css/table.css';
import { FaList, FaArrowUp } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdSearch } from 'react-icons/io';
import { deleteLead } from "../../redux/crm-features/lead/leadSlice";
import { getPos, checkArea, fileDelete, getObjValue } from '../../formBuilder/FormHelper';
import { destPix, destFile } from '../../formBuilder/srcSet';

class LocalTable extends Component {

  state = {
    totalRow: this.props.body.length,
    selectedRow: 0,
    headerDone: 0,
    bodyDone: 0,
    hiddenArr: [],
    searchValue: '',
  }

  componentDidMount = async() => {
    window.addEventListener('scroll', this.handleScroll)
    document.addEventListener('click', this.onAction)
    this.setTable()
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onAction);
  }

  componentDidUpdate = async(prevProps) => {
    if (this.props.header !== prevProps.header) {
      await this.setState({
        totalRow: this.props.body.length,
        selectedRow: 0,
        headerDone: 0,
        bodyDone: 0,    
      })
      await this.setTable()
      var headerCheckbox = document.getElementById('headerCheckbox')
      headerCheckbox.checked = false

      var bodyData = this.state.bodyData
      for(let i=0; i<bodyData.length; i++) {
        var elm = document.getElementById(`row${i}`)
        var checkCell = document.getElementById(`checkCell${i}`)
        var checkbox = document.getElementById(`checkbox${i}`)

        elm.style.backgroundColor = '#ffffff'
        checkCell.style.backgroundColor = '#ffffff'
        checkbox.checked = false
      }
    }
  }

  handleScroll = async () => {
    this.setPosition('moreList', this.state.selectedId);
    // this.setPosition('columnList', 'columnShowBtn');
  }

  setPosition = (elementId, referenceId) => {
    const element = document.getElementById(elementId);
    const reference = document.getElementById(referenceId);
    if (element && reference) {
      const rect = reference.getBoundingClientRect();
      element.style.top = `${rect.bottom + 5}px`;
    }
  }

  setTable = () => {
    var headerData = this.props.header
    var bodyData = this.props.body

    console.log(9999 , bodyData)
    this.setHeader(headerData)
    this.setBody(bodyData)
    this.setState({
      headerData, bodyData,
      dataLength: headerData.length
    })
  }

  setHeader = async (headerData) => {
    const { headerDone, hiddenArr } = this.state
    if(headerDone===0) {
      const checkboxHeader = {
        key: 'checkbox',
        label: <Form.Check id='headerCheckbox' type="checkbox" onChange={(e) => this.setSelectAll(e)}/>,
        value: false
      }
      headerData.unshift(checkboxHeader)
    }

    var visibility
    const header = headerData.map(
      (item, i) => (
        // console.log('item: ', item),
        visibility = hiddenArr.includes(i) ? false : true,
        <th key={i} className={i===0 ? 'cardShadow' : ''}
          style={{
            minWidth:i===0 ? '' : '180px',
            position:i===0 ? 'sticky' : '',
            left: i===0 ? 0 : '',
            fontWeight:900,
            backgroundColor:'#ffffff',
            borderBottom:'2px solid #99999950',
            borderRadius:'0px',
            verticalAlign:'top',
            padding:'15px 20px',
            display:visibility ? '' : 'none',
          }}
        >
          <div className='d-flex' style={{alignItems:'center'}}>
            <div style={{whiteSpace:'nowrap'}}>{item.label}</div>
            { i!==0 &&
              <div className='d-flex' style={{alignItems:'center'}}>
                <div className='center column-sort-btn'>
                  <FaArrowUp />
                </div>
                <div className='center column-menu-btn'>
                  <BsThreeDotsVertical />
                </div>
              </div>
            }
          </div>
        </th>
      )
    )
    this.setState({
      header,
      headerDone: this.state.headerDone + 1,
    })
  }

  setBody = (bodyData) => {
    const { bodyDone, hiddenArr, searchValue } = this.state
    function addCheckbox(item) {
      if(bodyDone===0) {
        const checkboxBody = {
          key: 'checkbox',
          value: <Form.Check type="checkbox" />,
        }
        item.unshift(checkboxBody)
      }
    }

    var visibility, checkValue, highlightArr=[]

    function highlight(checkValue, id) {
      if(checkValue) highlightArr.push(id)
    }

    const body = bodyData.map(
      (item, i) => (
        // console.log('item: ', item),
        item.sort((a, b) => a.order - b.order),
        addCheckbox(item),
        <tr key={i} id={`row${i}`}
          onMouseEnter={() => this.rowHover(i, 'in')}
          onMouseLeave={() => this.rowHover(i, 'out')}
          className='pointer obj-parent' style={{ backgroundColor:'#ffffff' }}>
          {
            item.map(
              (itemx, ix) => (
                // console.log(888, searchValue),
                checkValue = (searchValue && itemx.value && typeof itemx.value === 'string' && itemx.value.toLowerCase().includes(searchValue)),
                highlight(checkValue, `body-${i}-${ix}`),
                visibility = !hiddenArr.includes(ix),
                ix===0
                  ?
                  <td key={ix} id={`checkCell${i}`} className='cardShadow'
                    style={{
                      position:'sticky',
                      left: 0,
                      alignItems:'center',
                      whiteSpace:'wrap',
                      borderBottom:'1px solid #99999950',
                      borderRadius:'0px',
                      backgroundColor:'#ffffff',
                      padding:'15px 20px',
                      display:visibility ? '' : 'none',
                    }}
                  >
                    <div className='d-flex' style={{width:'100%', height:'100%', alignItems:'center'}}>
                      <Form.Check id={`checkbox${i}`} className='checkboxX' type="checkbox" onChange={(e) => this.setSelected(e, bodyData, i)}/>&nbsp;&nbsp;
                      <div id={`more-${i}`} name='more' className='table-more obj-child'
                        onClick={() => this.onMore(`more-${i}`, i, bodyData)}
                      >
                        <p id={`more-${i}`} name='more' >...</p>
                      </div>
                      
                    </div>
                  </td>
                  :
                  <td key={ix}
                    style={{
                      maxWidth:'100px',
                      overflow:'hidden',
                      whiteSpace:'wrap',
                      borderBottom:'1px solid #99999950',
                      borderRadius:'0px',
                      padding:'15px 20px',
                      display:visibility ? '' : 'none',
                    }}
                  >
                    <span id={`body-${i}-${ix}`}>{itemx.value}</span>
                  </td>
              )
            )
          }
        </tr>
      )
    )
    console.log(highlightArr)

    this.setState({
      body,
      bodyDone: this.state.bodyDone + 1,
    })

    this.setHighlight(highlightArr)
  }

  setSelected = (e, bodyData, i) => {
    const checked = e.target.checked
    var elm = document.getElementById(`row${i}`)
    var checkCell = document.getElementById(`checkCell${i}`)
    if(checked) {
      elm.style.backgroundColor = '#9ad2fc30'
      checkCell.style.backgroundColor = '#ecf6ff'
      this.setState({selectedRow: this.state.selectedRow + 1})
    } else {
      elm.style.backgroundColor = '#ffffff'
      checkCell.style.backgroundColor = '#ffffff'
      this.setState({selectedRow: this.state.selectedRow - 1})
    }

  }

  setSelectAll = (e) => {
    var bodyData = this.state.bodyData
    const checked = e.target.checked

    for(let i=0; i<bodyData.length; i++) {
      var elm = document.getElementById(`row${i}`)
      var checkCell = document.getElementById(`checkCell${i}`)
      var checkbox = document.getElementById(`checkbox${i}`)
      if(checked) {
        elm.style.backgroundColor = '#9ad2fc30'
        checkCell.style.backgroundColor = '#ecf6ff'
        checkbox.checked = true
        this.setState({selectedRow: bodyData.length})
      } else {
        elm.style.backgroundColor = '#ffffff'
        checkCell.style.backgroundColor = '#ffffff'
        checkbox.checked = false
        this.setState({selectedRow: 0})
      }
    }
    var headerCheckbox = document.getElementById('headerCheckbox')
    headerCheckbox.checked = checked
  }

  rowHover = (i, type) => {
    var elm = document.getElementById(`row${i}`)
    var checkCell = document.getElementById(`checkCell${i}`)
    var checkbox = document.getElementById(`checkbox${i}`)
    var checked = checkbox.checked

    if(type==='in') {
      if(checked) {
        elm.style.backgroundColor = '#d9e9f7'
        checkCell.style.backgroundColor = '#d9e9f7'
      } else {
        elm.style.backgroundColor = '#f5f5f5'
        checkCell.style.backgroundColor = '#f5f5f5'
      }
    } else if(type==='out'){
      if(checked) {
        elm.style.backgroundColor = '#ecf6ff'
        checkCell.style.backgroundColor = '#ecf6ff'
      } else {
        elm.style.backgroundColor = '#ffffff'
        checkCell.style.backgroundColor = '#ffffff'  
      }
    }
  }

  onMore = async (id, i, bodyData) => {
    if(i>=0) {
      // console.log(i)
      this.setState({
        row: bodyData[i],
        rowIndex: i
      })
    }
    const more = document.getElementById(id);
    more.classList.remove('obj-child');

    const rect = more.getBoundingClientRect();

    const moreList = document.getElementById('moreList');

    moreList.style.display = "block";
    moreList.style.borderRadius = "5px";
    moreList.style.position = 'fixed';
    moreList.style.top = `${rect.bottom+5}px`;
    moreList.style.left = `${rect.left}px`;

    const rectMoreList = moreList.getBoundingClientRect();
    console.log(window.innerHeight, rectMoreList.bottom)
    if(rectMoreList.bottom + 40 > window.innerHeight) {
      var diff = rectMoreList.bottom - 40 - window.innerHeight
      console.log(diff)

      moreList.style.top = `${rect.bottom + 5 + diff}px`;
    }

    await this.setState((prevState) => {
      return { preSelectedId: prevState.selectedId };
    });
    await this.setState({
      selectedId: id,
    })
    this.onCloseColumnList()
  }

  onAction = async (e) => {
    const { columnShowPos, columnListPos, columnListDisplay } = this.state
    this.onMoreAction(e)
    if((columnShowPos)) {
      checkArea(columnListPos).then(res => {
        if(res===false && columnListDisplay) this.onCloseColumnList()
      })
    }

  }

  onMoreAction = async (e) => {
    const { preSelectedId, selectedId, targetDate } = this.state
    const name = document.getElementById(e.target.id)?.getAttribute('name')
    await this.onCloseMoreList(e)
    if(name === 'more') {
      if(preSelectedId!==selectedId) {
        this.onMore(e.target.id, targetDate)
      } else {
        this.setState({
          selectedId: undefined,
        })
      }
    }
  }


  onCloseMoreList = async (e) => {
    const moreList = document.getElementById('moreList');
    if(moreList) moreList.style.display = "none";
    const more = document.getElementById(this.state.preSelectedId);
    if(more) more.classList.add('obj-child');
  }

  onCloseColumnList = async () => {
    const columnList = document.getElementById('columnList');
    if(columnList) columnList.style.display = "none";
    await this.setState({
      columnListDisplay: false,
    })
  }

  columnListMap = async (headerData) => {
    const { hiddenArr } = this.state
    var visibility
    const columnList = headerData.map(
      (item, i) => (
        // console.log('item: ', item.label, item.visible, ),
        visibility = hiddenArr.includes(i) ? false : true,
        <div key={i} className='d-flex hoverGreyColumnList'
          style={{
            fontSize:'14px',
            fontWeight:'bold',
            alignItems:'flex-start',
            padding:'10px 0px'
          }}
          onClick={() => this.setVisibility(i)}
        >
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              checked={visibility}
              id="11"
              style={{ width: '38px', height: '20px', backgroundColor:visibility ? '#0273F0' : '' }}
              onChange={() => null}
            />
          </div>
          <span style={{margin:'3px 10px'}}>{item.label}</span>
        </div>
      )
    )
    this.setState({
      columnList,
    })
  }

  toggleColumnShow = async () => {
    const columnShowPos = await getPos('columnShowBtn');
    const {columnListDisplay} = this.state
    var headerData = this.props.header

    await this.columnListMap(headerData)

    this.setState({
      columnShowPos,
    })

    if(!columnListDisplay) {
      const modalColumnList = document.getElementById('columnList');
      const columnShow = document.getElementById('columnShowBtn');  
      const rect = columnShow.getBoundingClientRect();

      modalColumnList.style.display = "block";
      modalColumnList.style.borderRadius = "5px";
      modalColumnList.style.position = 'fixed';
      modalColumnList.style.bottom = '10px';
      modalColumnList.style.top = '70px';
      modalColumnList.style.right = '10px';
      modalColumnList.style.overflow = 'scroll';

        var columnListPos = await getPos('columnList');
        // set position
        var transform = -40 // css: fadeInTopLeft
        columnListPos.top = columnListPos.top - transform
        columnListPos.bottom = columnListPos.bottom - transform
        columnListPos.left = columnListPos.left + transform
        columnListPos.right = columnListPos.right + transform
  
        await this.setState({
          columnListDisplay: true,
          columnListPos
        })

    } else {
      // this.onCloseColumnList()
    }
  }

  setVisibility = async (i) => {
    const { headerData, bodyData, hiddenArr } = this.state
    if(i>=0) {
      if(!hiddenArr.includes(i)) {
        hiddenArr.push(i)
      } else {
        let index = hiddenArr.indexOf(i);
        if (index !== -1) {
          hiddenArr.splice(index, 1);
        }
      }
    }

    this.columnListMap(headerData)
    this.setHeader(headerData)
    this.setBody(bodyData)
  }

  onShowAll = async () => {
    await this.setState({
      hiddenArr: []
    })
    this.setVisibility()
  }

  onHideAll = async () => {
    let x = this.state.dataLength;
    let array = [];
    for (let i = 1; i <= x; i++) {
        array.push(i);
    }
    await this.setState({
      hiddenArr: array
    })
    this.setVisibility()
  }

  confirmDelete = (row) => {
    confirmAlert({
      title: "Delete This Lead",
      message: "Are you sure to do delete this lead?",
      buttons: [
        {
          label: "Delete",
          onClick: () => this.removeProfile(row),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  removeProfile = async (data) => {
		for(let i=0; i<data.length; i++) {
			var type = data[i].type
			if(['imageUpload', 'fileUpload'].includes(type)) {
				var value = data[i].value
				for(let i2=0; i2<value.length; i2++) {
					if(type==='imageUpload'){
						const name = value[i2].id + '.jpeg'
						await fileDelete({dest: destPix + "/" + name}, this.props.dispatch)
					} else if(type==='fileUpload') {
						const name = `${value[i2].id}.${value[i2].type}`
						console.log(name)
						await fileDelete({dest: destFile + "/" + name}, this.props.dispatch)
					}
				}
			}
		}
	    await this.props.dispatch(deleteLead(data._id));
      const row = document.getElementById(`row${this.state.rowIndex}`)
      row.style.display = 'none'
	};

  onEdit = (row) => {
    window.history.pushState({}, '', `/crm/edit-record/${row._id}`)
  }

  searchHandler = async (e) => {
    var tx = e.target.value
    await this.setState({
        searchValue: e.target ? tx.toLowerCase() : e,
    })

    const { bodyData } = this.state

    var data = []
    for (let i = 0; i < bodyData.length; i++) {
      for (let j = 0; j < bodyData[i].length; j++) {
        const obj = bodyData[i][j];
        const objV = getObjValue(obj.value)
        if (objV && objV.toLowerCase().includes(tx)) {
          data.push(bodyData[i])
          break;
        }
      }
    }

    this.setBody(data)

  }

  setHighlight = async (arr) => {
    const { searchValue } = this.state
    // console.log(11, searchValue)
    for(let i=0; i<arr.length; i++) {
      const textElement = document.getElementById(arr[i]);
      if(textElement) {
        const text = textElement.textContent;
        const highlightedText = text.replace(new RegExp(`\\b(${searchValue})\\b`, 'g'), '<span class="highlight">$1</span>');
        textElement.innerHTML = highlightedText

        // const range = document.createRange();
        // range.selectNodeContents(textElement);
        // range.deleteContents();
        // const fragment = range.createContextualFragment(highlightedText);
        // textElement.appendChild(fragment);
        // console.log(66, textElement, text, fragment, range)
        // console.log(77, text, highlightedText)

      }
    }
  }

  onResize = () => {
    this.setState({
      w: document.body.clientWidth,
      h: document.body.clientHeight
    })
  }

  render() {
    const { searchValue, header, body, totalRow, selectedRow, columnList, hiddenArr, dataLength, row } = this.state
    const { editUrl, loading } = this.props

    const moreList = (
      <div id='moreList'
        className='popupContainer zoomInTopLeft table-more-list'>
        <div><Link to={`${editUrl}${row ? row._id : ''}`}><div style={{width:'100%', height:'100%'}}>Edit</div></Link></div>
        <div onClick={() => this.confirmDelete(row)}>Delete</div>
      </div>
    )

    const disableStyle = { color: '#959595', backgroundColor: '#ffffff', cursor: 'default' }
    const modalColumnList = (
      <div id='columnList'
        className='popupContainer animated fadeInTopRight'>
        <div className='column-list-header'>
          <div style={hiddenArr.length===dataLength ? disableStyle : {}}
            onClick={() => this.onHideAll()}>
            HIDE ALL
          </div>
          <div style={hiddenArr.length===0 ? disableStyle : {}}
            onClick={() => this.onShowAll()}>
            SHOW ALL
          </div>
        </div>
        {columnList}
      </div>
    )

    const searchTape = (
      <div style={{ position:'relative', width:'200px', margin:'', padding:'0px', fontSize:'13px', borderRadius:'3px' }}>
          <input id='searchInput' type="text" value={searchValue} placeholder='search' name='searchValue' autoComplete="off"
            className="form-control"
            style={{textAlign:'left', paddingLeft:`45px`, mainrg:'', width:'100%', height:'30px', fontSize:'12px'}}
            onChange={this.searchHandler} onKeyDown={this.startSearch} onFocus={this.startSearch}/>
          {/* <IoMdSearch className='' color='' size="1.6em" style={{position:'absolute', top:5, left:5}}/> */}
      </div>
    )

    return (
      (
        <div style={{color:'#000000'}}>
          <div className='d-flex justify-content-between' style={{height:'', margin:'10px', alignItems:'center'}}>
            <span style={{display:!loading ? '' : 'none'}}>Loading . . .</span>
            <span style={{display:selectedRow>0 ? '' : 'none'}}>{`${selectedRow} of ${totalRow} row(s) selected`}</span>
            <span style={{display:!selectedRow>0 ? '' : 'none', color:'#ffffff'}}>.</span>
            <div className='center'>
              {searchTape}
              <div id='columnShowBtn' name='columnShowBtn' className='hoverGrey' onClick={() => this.toggleColumnShow()}>
                <FaList/>
              </div>
            </div>
          </div>
          <div className='cardShadow' style={{ border:'0px solid #99999999', borderRadius:'10px', overflow:'hidden' }}>
            <Table responsive style={{ margin:'0px', }}>
              <thead>
                <tr className='' >
                  {header}
                </tr>
              </thead>
              <tbody>
                {body}
              </tbody>
            </Table>
          </div>
          {moreList}
          {modalColumnList}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    targetModule: state.moduleItem.targetModule,
    moduleItems: state.moduleItem.moduleItems,
  }
}

export default connect (mapStateToProps)(LocalTable);
