import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/course/crm/`;

// Validate courseTitle
// export const validateTitle = (courseTitle) => {
//   return courseTitle.match();
// };

// add course
const addCourse = async(courseData) =>{
	const response = await axios.post(API_URL + "addCourse", courseData);
	return response.data;
};

// Get all courses
const getCourses = async () => {
  const response = await axios.get(API_URL + "getCourses");
  return response.data;
};

// get course
const getCourse = async(id) =>{
	const response = await axios.get(`${API_URL}/course-detail/${id}`);
	return response.data;
};

// get course by title
const getCourseByTitle = async(title) =>{
	const response = await axios.get(API_URL + title);
	return response.data;
};

// upgrade course
const upgradeCourse = async(courseData) =>{
	const response = await axios.put(API_URL + "upgradeCourse", courseData);
	return response.data.message;
};

// update course
const updateCourse = async(id, courseData) =>{
	const response = await axios.patch(`${API_URL}${id}`, courseData);
	return response.data;
};

// update course section
const updateCourseSection = async(courseData) =>{
	const response = await axios.patch(API_URL + "updateCourseSection", courseData);
	return response.data;
};

// addLocationClassSection
const addLocationClassSection = async(locationClassData) =>{
	const response = await axios.post(API_URL + "addLocationClassSection", locationClassData);
	return response.data;
};

// delete course
const deleteCourse = async(id) =>{
	const response = await axios.delete(API_URL + id);
	return response.data.message;
};


const courseService = {
	addCourse,
	getCourses,
	getCourse,
	getCourseByTitle,
	upgradeCourse,
	updateCourse,
	deleteCourse,
	updateCourseSection,
	addLocationClassSection,
}

export default courseService;