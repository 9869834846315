import React,{useState,useEffect,useReducer} from 'react';
import ReactDOM from "react-dom";
import { Link,useNavigate,useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Breadcrumb from '../components/Breadcrumb';
import { toast } from "react-toastify";
import Sidebar from '../components/Sidebar';
import { AiOutlineEllipsis,AiOutlineSearch } from "react-icons/ai";
import Footer from '../components/Footer';
import DropdownButton from 'react-bootstrap/DropdownButton';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCourse,
  getCourseByTitle,
  updateCourseSection,
}   from "../../redux/crm-features/course/courseSlice";

const initialState ={
	courseSection : '',
}

const CourseFromScratch = () => {

	useRedirectLoggedOutUser("/crm/login");
	
	const { courseTitle } = useParams();
	const [courseData, setCourseData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const {courseSection} = courseData;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { course } = useSelector(
      (state) => state.course
  	);

  	useEffect(() => {
      dispatch(getCourseByTitle(courseTitle)).then(res => {
		// console.log(res.payload)
		setCourseData(res.payload)
		setSections(res.payload.courseSection)
	  });
  	}, [dispatch, courseTitle]);

	const [sections, setSections] = useState([]);

	useEffect(() => {
	    // Initialize with one section when the component mounts
	    if(sections.length===0) {
			addSection();
		} else {

		}
	}, []);

	const addSection = () => {
	    const newSection = {
	      id: sections.length,
	      panels: [] // Each section starts with an empty list of panels
	    };
	    setSections([...sections, newSection]);
	};

	const sectionHandler = (e, i) => {
		var vx = e.target.value
		var sectionX = JSON.parse(JSON.stringify(sections));

		sectionX[i].title = vx
	    // console.log(sectionX[i])
	    setSections(sectionX);
	};

	const lessonHandler = (e, i, ix) => {
		var vx = e.target.value
		var sectionX = JSON.parse(JSON.stringify(sections));
		sectionX[i].panels[ix].title = vx
	    // console.log(sections[i])
	    setSections(sectionX);
	};

	const deleteSection = (sectionId) => {
    	setSections(prevSections => prevSections.filter(section => section.id !== sectionId));
  	};

	const addPanel = (sectionId, panelContent) => {
	    setSections(prevSections => {
	      return prevSections.map(section => {
	        if (section.id === sectionId) {
	          // Add a new panel to the specified section
	          return {
	            ...section,
	            panels: [...section.panels, { id: section.panels.length, content: panelContent }]
	          };
	        }
	        return section;
	      });
	    });
	};


	const courseSectionAdd = async (e) => {
		e.preventDefault();
		var courseDataArr = JSON.parse(JSON.stringify(courseData));
		courseDataArr.courseSection = sections
		// console.log(90, courseDataArr);
		await dispatch(updateCourseSection(courseDataArr));
		
		forceUpdate();
	};

	const [isCancel, setIsCancel] = useState(true);

	const handleCancel = () => {
	    setIsCancel(false); 
  	};

  	const [isLesson, setIsLesson] = useState(true);

	const handleCancelLesson = () => {
	    setIsLesson(false); 
  	};

  	const handleDragStart = (event, section) => {
  event.dataTransfer.setData('section', JSON.stringify(section));
};

const handleDragOver = (event) => {
  event.preventDefault();
};

const handleDrop = (event, targetIndex) => {
  const droppedItem = JSON.parse(event.dataTransfer.getData('section'));
  const newSections = [...sections];
  const currentIndex = newSections.findIndex((section) => section.id === droppedItem.id);
  newSections.splice(currentIndex, 1);
  newSections.splice(targetIndex, 0, droppedItem);
  setSections(newSections);
};
	
	return (
		<>
		
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />

		                    <div className="row layout-top-spacing">

		                    	<div className="courseCurriculumHeading">
		                    		<span><Link to="/crm/all-courses">course </Link>/ {courseTitle}</span>
		                    		<div className="d-flex align-items-center justify-content-between">
		                    			<h3>Curriculum</h3>
		                    			<button type="button" className="btn btn-secondary">Preview</button>
		                    			<button type="button" className="btn btn-secondary" onClick={courseSectionAdd}>Save</button>
		                    		</div>
		                    	</div>

		                    	<div className="col-12">
		                    		<Link to="" className="btn btn-secondary _effect--ripple waves-effect waves-light">Bulk edit</Link>
		                    		<Link to="/crm/course/curriculum" className="btn btn-secondary _effect--ripple waves-effect waves-light mx-1">Import content</Link>		                    		
		                    	</div>

		                    	<div className="courseCurriculumSec row">

		                    		{/*-----------Sec Panel------*/}
		                    	{sections.map((section, i) => (
									<div key={section.id} className="section d-flex align-items-baseline" draggable
										onDragStart={(event) => handleDragStart(event, section)}
										onDragOver={(event) => handleDragOver(event)}
										onDrop={(event) => handleDrop(event, i)}>
        								<div>
		          							<button type="button" className="dndBtn"><i className="fa fa-th"></i></button>
							          	</div>
								        <div className="newSecPanel">
								        	<div className="newSecPanelHead d-flex align-items-center justify-content-between">
								        		<div className="col-8">
								        		{
								        			isCancel ? 
								        			<form className="d-flex align-items-center justify-content-between">
								        				<div className="form-group w-100">
									                        <input type="text" className="form-control" defaultValue={sections[i].title} name={`section-${i}`} placeholder="New section" onChange={(e) => sectionHandler(e, i)}/>
									                    </div>
									                    <div className="d-flex">
									                    	<button type="button" className="btn btn-secondary mx-1">Save</button>
								                    		<button onClick={handleCancel} type="button" className="btn btn-secondary">Cancel</button>
									                    </div>
								        			</form>
								        			:
								        			<h4>New Section</h4>
								        		}
								                </div>
								        		<div className="newSecPanelDropDown">
											        {['end'].map(
											          (direction) => (
											            <DropdownButton
											              key={direction}
											              id={`dropdown-button-drop-${direction}`}
											              drop={direction}
											              title={<i className="fa fa-ellipsis-v"></i>}
											            >
											            <li onClick={() => setIsCancel(true)}>
														    <span>Rename Section</span>
									              		</li>
									              		<li>
														    <span>Duplicate Section</span>
									              		</li>
									              		<li onClick={() => deleteSection(section.id)}>
														    <span>Delete Section</span>
									              		</li>
											              
											            </DropdownButton>
											          ),
											        )}
											    </div>
								        	</div>
								    		<div className="panels">
								            {section?.panels.map((panel, ix) => (
								              <div key={panel.id} className="panel d-flex align-items-center">
								                {/*{panel.content}*/}
								              <div>
			          							<button type="button" className="dndBtn"><i className="fa fa-th"></i></button>
								          	</div>
								              <div className="newLessonPanel d-flex align-items-center justify-content-between">
										    		<div className="col-8">
										    		{
									        			isLesson ? 
									        			<form className="d-flex align-items-center justify-content-between">
									        				<div className="form-group w-100">
										                        <input type="text" className="form-control" defaultValue={sections[i].panels[ix].title} name={`lesson-${i}-${ix}`} placeholder="New lesson" onChange={(e) => lessonHandler(e, i, ix)}/>
										                    </div>
										                    <div className="d-flex">
										                    	<button type="button" className="btn btn-secondary mx-1">Save</button>
									                    		<button onClick={handleCancelLesson} type="button" className="btn btn-secondary">Cancel</button>
										                    </div>
									        			</form>
									        			:
									        			<h6><Link to="/crm/lesson-content">New Lesson</Link></h6>
									        		}
										    			{/*<form className="d-flex align-items-center justify-content-between">
										    				<div className="form-group w-100">
										                        <input type="text" className="form-control" placeholder="New lesson"/>
										                    </div>
										                    <div className="d-flex">
										                    	<button type="submit" className="btn btn-secondary mx-1">Save</button>
										                		<button type="button" className="btn btn-secondary">Cancel</button>
										                    </div>
										    			</form>*/}
										            </div>
										            <div className="newSecPanelDropDown">
												        {['end'].map(
												          (direction) => (
												            <DropdownButton
												              key={direction}
												              id={`dropdown-button-drop-${direction}`}
												              drop={direction}
												              title={<i className="fa fa-ellipsis-v"></i>}
												            >
												            <li>
															    <span>Set as a public preview</span>
										              		</li>
										              		<li onClick={() => setIsLesson(true)}>
															    <span>Rename Section</span>
										              		</li>
										              		<li>
															    <span>Duplicate Section</span>
										              		</li>
										              		<li>
															    <span>Delete Section</span>
										              		</li>
												              
												            </DropdownButton>
												          ),
												        )}
												    </div>
										    	</div>
								              </div>
								            ))}
								          </div>
								        	<div className="newSecPanelBottom">
								        		<ul>
								        			<li onClick={() => addPanel(section.id, `Panel ${section.panels.length}`)}>
								        				<i className="fa fa-plus-circle"></i>
								        				<span>New lesson</span>
								        			</li>
								        			<li>
								        				<span>Bulk upload</span>
								        			</li>
								        			<li>
								        				<span>Section summary</span>
								        			</li>
								        		</ul>
								        	</div>
								        </div>
								        </div>
								      ))}
			                        {/*-----------Sec Panel------*/}

			                        <div className="addPanelSec" onClick={addSection}>
			                        	<i className="fa fa-plus-circle"></i>
			                        	<span>New section</span>
			                        </div>

		                    	</div>
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}	

		   
		</>
	)
}

export default CourseFromScratch