import React,{useState,useEffect,useRef,useReducer,useMemo,useLayoutEffect} from 'react';
import { Link,useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit,AiOutlineSearch,AiOutlineEllipsis } from "react-icons/ai";
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import ModuleSideBar from './ModuleSideBar';
import { toast } from "react-toastify";
import MaterialReactTable from 'material-react-table';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import {AiOutlineLeft} from 'react-icons/ai';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import { getFields } from '../../formBuilder/FormHelper';
import {
	getModule,
} 	from "../../redux/crm-features/moduleItem/moduleSlice";
import {
  	addLayout,
	getLayouts,
	deleteLayout,
} 	from "../../redux/crm-features/layout/layoutSlice";


const initialState ={
	name : '',
	status : '',
}

const layOutRule = [
	{
		
	},
];

const newValidRule = [
	{
		
	},
];

const ViewModule = () => {

	useRedirectLoggedOutUser("/crm/login");

	const [layoutData, setLayoutData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const {name,status} = layoutData;
	const [ViewPost, SetPostShow] = useState(false)
    const handlePostShow = () => { SetPostShow(true) }
    const hanldePostClose = () => { SetPostShow(false) }
    const [ViewLayoutRule, SetLayoutRuleShow] = useState(false)
    const handleLayoutRuleShow = () => { SetLayoutRuleShow(true) }
    const hanldeLayoutRuleClose = () => { SetLayoutRuleShow(false) }
    const [ViewValidRule, SetValidRuleShow] = useState(false)
    const handleValidRuleShow = () => { SetValidRuleShow(true) }
    const hanldeValidRuleClose = () => { SetValidRuleShow(false) }
    const [ViewCreateButton, SetCreateButtonShow] = useState(false)
    const handleCreateButtonShow = () => { SetCreateButtonShow(true) }
    const hanldeCreateButtonClose = () => { SetCreateButtonShow(false) }
    const [ViewCreateLink, SetCreateLinkShow] = useState(false)
    const handleCreateLinkShow = () => { SetCreateLinkShow(true) }
    const hanldeCreateLinkClose = () => { SetCreateLinkShow(false) }
    const dispatch = useDispatch();
	const { id } = useParams();

	const { moduleItem } = useSelector(
	    (state) => state.moduleItem
	);
	
	const [tabIndex, setTabIndex] = useState(0);
	const [tabIndexSec, setTabIndexSec] = useState(0);
	const [fields, setFields] = useState([]);

	 const { layouts, isLoading, isSuccess, message } = useSelector(
	    (state) => state.layout
	);

	useEffect(() => {
	    dispatch(getModule(id)).then(res => {
			const fields = getFields(res.payload.form)
			fields.sort((a, b) => a.label.localeCompare(b.label));
			setFields(fields)
		});
	}, [dispatch, id]);

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setLayoutData({...layoutData, [name]: value});
	}

	const layoutAdd = async (e) => {
		e.preventDefault();

		if (!name){
			return toast.error('All fields are required')
		}

		const layoutData = {name, status};
		// console.log(userData);
		await dispatch(addLayout(layoutData));
		setLayoutData({ name: "", status:"" });
		SetPostShow(false);
		forceUpdate();
		// navigate('/users-list');
		// window.location.reload();
		// await dispatch(sendVerificationEmail());
	};

	useEffect(() => {
	    dispatch(getLayouts());
	}, [dispatch, reducerValue]);

	const removeLayout = async (id) => {
	    await dispatch(deleteLayout(id));
	    dispatch(getLayouts());
	};

	const confirmDelete = (id) => {
	    confirmAlert({
	      title: "Delete This Layout",
	      message: "Are you sure to do delete this Layout?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeLayout(id),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};
	

const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'Name',
        Header: () => <span>Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          /*<Link to={`/crm`}><b>{renderedCellValue}</b></Link>*/
          <span className="d-flex align-items-center"><Link to={`/crm`}><b>{renderedCellValue}</b></Link>
		        <div className="dropdown">
						  <span className="dropdown-toggle mx-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						    <AiOutlineEllipsis />
						  </span>
						  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						    <li><Link className="dropdown-item" to={`/crm/edit-module-layout/${row.original._id}`}>Edit</Link></li>
						    <li><Link className="dropdown-item" to="#" onClick={() => confirmDelete(row.original._id)}>Delete</Link></li>
						    
						  </ul>
						</div>
		      </span>
        ),
      },
      {
        accessorFn: (row) => row.shareTo, //alternate way
        id: 'shareTo', //id required if you use accessorFn instead of accessorKey
        header: 'Shared To',
        Header: () => <span>Shared To</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.lastModify, //alternate way
        id: 'lastModify', //id required if you use accessorFn instead of accessorKey
        header: 'Last Modified',
        Header: () => <span>Last Modified</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.status, //alternate way
        id: 'status', //id required if you use accessorFn instead of accessorKey
        header: 'Status',
        Header: () => <span>Status</span>, //optional custom header render
      },
      // {
      //   accessorFn: (row) => <span>
      //                       <FaTrashAlt
      //                         size={18}
      //                         cursor="pointer"
      //                         color="rgb(0,0,0,.8)"
      //                         onClick={() => confirmDelete(row._id)}
      //                       />
      //                       &nbsp;
      //                       <Link to={`/crm/edit-module-layout/${row._id}`}>
	     //                        <AiFillEdit
	     //                          size={20}
	     //                          cursor="pointer"
	     //                          color="rgb(0,0,0,.8)"
	     //                        />
      //                       </Link>
      //                     </span>, //alternate way
      //   id: 'action', //id required if you use accessorFn instead of accessorKey
      //   header: 'Action',
      //   Header: () => <span>Action</span>, //optional custom header render
      // },
    ],
    [],
  );

const layoutRuleColumns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'Name',
        Header: () => <span>Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          /*<Link to={`/crm`}><b>{renderedCellValue}</b></Link>*/
          <span className="d-flex align-items-center"><Link to={`/crm`}><b>{renderedCellValue}</b></Link>
		        <div className="dropdown">
						  <span className="dropdown-toggle mx-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						    <AiOutlineEllipsis />
						  </span>
						  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						    <li><Link className="dropdown-item" to={`#`}>Edit</Link></li>
						    <li><Link className="dropdown-item" to="#">Delete</Link></li>
						    
						  </ul>
						</div>
		      </span>
        ),
      },
      {
        accessorFn: (row) => row.primaryField, //alternate way
        id: 'primaryField', //id required if you use accessorFn instead of accessorKey
        header: 'Primary Field',
        Header: () => <span>Primary Field</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.lastModify, //alternate way
        id: 'lastModify', //id required if you use accessorFn instead of accessorKey
        header: 'Last Modified',
        Header: () => <span>Last Modified</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.status, //alternate way
        id: 'status', //id required if you use accessorFn instead of accessorKey
        header: 'Status',
        Header: () => <span>Status</span>, //optional custom header render
      },
      // {
      //   accessorFn: (row) => <span>
      //                       <FaTrashAlt
      //                         size={18}
      //                         cursor="pointer"
      //                         color="rgb(0,0,0,.8)"
                              
      //                       />
      //                       &nbsp;
      //                       <Link to={`/crm`}>
	     //                        <AiFillEdit
	     //                          size={20}
	     //                          cursor="pointer"
	     //                          color="rgb(0,0,0,.8)"
	     //                        />
      //                       </Link>
      //                     </span>, //alternate way
      //   id: 'action', //id required if you use accessorFn instead of accessorKey
      //   header: 'Action',
      //   Header: () => <span>Action</span>, //optional custom header render
      // },
    ],
    [],
  );

const validRuleColumns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'Field Name',
        Header: () => <span>Field Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          /*<Link to={`/crm`}><b>{renderedCellValue}</b></Link>*/
          <span className="d-flex align-items-center"><Link to={`/crm`}><b>{renderedCellValue}</b></Link>
		        <div className="dropdown">
						  <span className="dropdown-toggle mx-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						    <AiOutlineEllipsis />
						  </span>
						  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						    <li><Link className="dropdown-item" to={`#`}>Edit</Link></li>
						    <li><Link className="dropdown-item" to="#">Delete</Link></li>
						    
						  </ul>
						</div>
		      </span>
        ),
      },
      {
        accessorFn: (row) => row.validateUsing, //alternate way
        id: 'validateUsing', //id required if you use accessorFn instead of accessorKey
        header: 'Validate Using',
        Header: () => <span>Validate Using</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.executesOn, //alternate way
        id: 'executesOn', //id required if you use accessorFn instead of accessorKey
        header: 'Executes On',
        Header: () => <span>Executes On</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.lastModify, //alternate way
        id: 'lastModify', //id required if you use accessorFn instead of accessorKey
        header: 'Last Modify',
        Header: () => <span>Last Modify</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.status, //alternate way
        id: 'status', //id required if you use accessorFn instead of accessorKey
        header: 'Status',
        Header: () => <span>Status</span>, //optional custom header render
      },
      // {
      //   accessorFn: (row) => <span>
      //                       <FaTrashAlt
      //                         size={18}
      //                         cursor="pointer"
      //                         color="rgb(0,0,0,.8)"
                              
      //                       />
      //                       &nbsp;
      //                       <Link to={`/crm`}>
	     //                        <AiFillEdit
	     //                          size={20}
	     //                          cursor="pointer"
	     //                          color="rgb(0,0,0,.8)"
	     //                        />
      //                       </Link>
      //                     </span>, //alternate way
      //   id: 'action', //id required if you use accessorFn instead of accessorKey
      //   header: 'Action',
      //   Header: () => <span>Action</span>, //optional custom header render
      // },
    ],
    [],
  );

const isFirstRender = useRef(true);
	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
	    const columnVisibility = localStorage.getItem(
	      'mrt_columnVisibility_table_1',
	    );
	    
	    if (columnVisibility) {
	      setColumnVisibility(JSON.parse(columnVisibility));
	    }
	    
	    isFirstRender.current = false;
	  }, []);

	useEffect(() => {
	    if (isFirstRender.current) return;
	    localStorage.setItem(
	      'mrt_columnVisibility_table_1',
	      JSON.stringify(columnVisibility),
	    );
	  }, [columnVisibility]);
	
	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		      <ModuleSideBar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">
					
		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">
		                    {
		                    	moduleItem && (
		                    			<div className="">
			                    		<h4>{moduleItem.title}</h4>
			                    	</div>
		                    		)
		                    }

		                    <div className="viewModule_rightSide">
		                    	{/*<h5>Lead</h5>*/}
		                    	<div className="custom_tabs mt-4">
			                    	<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
									    <TabList>
									    	<Tab className="btn btn-secondary">Layouts</Tab>
									    	<Tab className="btn btn-secondary">Layout Rules</Tab>
									    	<Tab className="btn btn-secondary">Validation Rules</Tab>
											<Tab className="btn btn-secondary">Fields</Tab>
											<Tab className="btn btn-secondary">Record Locking Configuration</Tab>
											<Tab className="btn btn-secondary">Links and Buttons</Tab>
											<Tab className="btn btn-secondary">Summary</Tab>
									    </TabList>
									    <TabPanel>
									    	<div className="tabContent">
									      		<div className="d-flex justify-content-between align-items-baseline">
															<div className="col-md-8 col-12">
																<p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly</p>
															</div>
															<Link to="/crm">Help</Link>
														</div>
												<div className="col-12 mt-3 text-end">
													<button onClick={() => { handlePostShow() }} type="button" className="btn btn-secondary">New Layout</button>
												</div>
												<div className="">
													<div className="col-12">
							                        	<div className="statbox widget box box-shadow moduleData">
							                                <div className="widget-content widget-content-area">
							                                {layouts.length === 0 ? (
							                                	<p>Layout not found...</p>
							                                ):(
							                                	<MaterialReactTable 
							                                	onColumnVisibilityChange={setColumnVisibility}
                                      state={{columnVisibility}}
							                                	columns={columns} data={layouts} enableRowSelection={true} 
							                                	// enableRowNumbers rowNumberMode="original"
							                                	/>
							                                )}
							                                </div>
							                            </div>
							                        </div>
												</div>
									      	</div>
									    </TabPanel>
									    <TabPanel>
									    	<div className="tabContent">
									      		<div className="d-flex justify-content-between align-items-baseline">
													<div className="col-md-8 col-12">
														<p>Layout rules allow you to manage sets of fields and sections. Define your own rules to match your layout with how you do business.</p>
													</div>
													
												</div>
												<div className="col-12 mt-3 text-end">
													<button onClick={() => { handleLayoutRuleShow() }} type="button" className="btn btn-secondary">New Layout Rule</button>
												</div>
												<div className="">
													<div className="col-12">
							                        	<div className="statbox widget box box-shadow moduleData">
							                                <div className="widget-content widget-content-area">
							                                {layOutRule.length === 0 ? (
							                                	<p>No Rules Configured.</p>
							                                ):(
							                                	<MaterialReactTable 
							                                	onColumnVisibilityChange={setColumnVisibility}
                                      							state={{columnVisibility}}
							                                	columns={layoutRuleColumns} data={layOutRule} enableRowSelection={true} 
							                                	// enableRowNumbers rowNumberMode="original"
							                                	/>
							                                )}
							                                </div>
							                            </div>
							                        </div>
												</div>
									      	</div>
									    </TabPanel>
									    <TabPanel>
									    	<div className="tabContent">
									      		<div className="d-flex justify-content-between align-items-baseline">
													<div className="col-md-8 col-12">
														<p>Set validation rules to verify data a user enters within a record before saving.</p>
													</div>
													
												</div>
												<div className="col-12 mt-3 text-end">
													<button onClick={() => { handleValidRuleShow() }} type="button" className="btn btn-secondary">Create New Validation Rule</button>
												</div>
												<div className="">
													<div className="col-12">
							                        	<div className="statbox widget box box-shadow moduleData">
							                                <div className="widget-content widget-content-area">
							                                {newValidRule.length === 0 ? (
							                                	<p>No Rules Configured.</p>
							                                ):(
							                                	<MaterialReactTable 
							                                	onColumnVisibilityChange={setColumnVisibility}
                                      							state={{columnVisibility}}
							                                	columns={validRuleColumns} data={newValidRule} enableRowSelection={true} 
							                                	// enableRowNumbers rowNumberMode="original"
							                                	/>
							                                )}
							                                </div>
							                            </div>
							                        </div>
												</div>
									      	</div>
									    </TabPanel>
									    <TabPanel>
									    <Tabs selectedIndex={tabIndexSec} onSelect={(index) => setTabIndexSec(index)}>
										    	<TabList>
											    	<Tab className="btn btn-secondary">Field Listing</Tab>
											    	<Tab className="btn btn-secondary">Field Permissions</Tab>
											    </TabList>
											    <TabPanel>
											    	<div className="tabContent">
										      		<div className="d-flex justify-content-between align-items-baseline flex-wrap">
																<div className="col-md-4 col-12">
																	<form className="custom-search">
																		<div className="position-relative">
																			<input type="text" placeholder="Search" className="form-control" />
																			<button><AiOutlineSearch size={18}/></button>
																		</div>
																	</form>
																</div>
																<button className="btn btn-secondary">Create and Edit Fields</button>
															</div>
														<table className="table table-bordered mt-5">
															<thead>
																<tr>
																	<th><b>Field Label</b></th>
																	<th><b>Data Type</b></th>
																	<th><b>Custom Field</b></th>
																</tr>
															</thead>
															<tbody>
																{
																	fields.map(
																		(item, i) => (
																			<tr key={i}>
																				<td>{item.label}</td>
																				<td>{item.type}</td>
																				<td></td>
																			</tr>
																		)
																	)
																}
															</tbody>
														</table>
											      	</div>
											    </TabPanel>
											    <TabPanel>
											    	<div className="tabContent">
											      		<div className="d-flex justify-content-between align-items-baseline flex-wrap">
											      			<div className="col-4">
											      				<select className="form-select" aria-label="Default select example">
																  <option>Select</option>
																  <option>Administrator</option>
																  <option>Standard</option>
															  	</select>
											      			</div>											      			
															<div className="col-md-4 col-12">
																<form className="custom-search">
																	<div className="position-relative">
																		<input type="text" placeholder="Search" className="form-control" />
																		<button><AiOutlineSearch size={18}/></button>
																	</div>
																</form>
															</div>
															<button className="btn btn-secondary">Create and Edit Fields</button>
														</div>
														<table className="table table-bordered mt-5">
															<thead>
																<tr>
																	<th><b>Fields</b></th>
																	<th><b>Read and Write</b></th>
																	<th><b>Read Only</b></th>
																	<th><b>Don't Show</b></th>
																</tr>
															</thead>
															<tbody>
																{
																	fields.map(
																		(item, i) => (
																			<tr key={i}>
																				<td>{item.label}</td>
																				<td><input checked={item.checked1} type="radio" id={`hw${i}`} name={`defaultAccess${i}`} onChange={()=>null}/></td>
																				<td><input checked={item.checked2} type="radio" id={`ho${i}`} name={`defaultAccess${i}`} /></td>
																				<td><input checked={item.checked3} type="radio" id={`ds${i}`} name={`defaultAccess${i}`} /></td>
																			</tr>
																		)
																	)
																}
															</tbody>
														</table>
											      	</div>
											    </TabPanel>
											</Tabs>
									      	
									    </TabPanel>
									    <TabPanel>
									    	<div className="tabContent">
									      		<div className="d-flex justify-content-between align-items-baseline">
													<div className="col-md-8 col-12">
														<p>Records can be locked to restrict further changes to the data. Add rules to lock records in the module automatically and configure actions allowed for locked records.</p>
													</div>
													
												</div>
												<div className="col-12 mt-3 text-end">
													<button type="button" className="btn btn-secondary">Configure now</button>
												</div>
												
									      	</div>
									    </TabPanel>
									    <TabPanel>
									    	<div className="tabContent">
									      		<div className="d-flex justify-content-between align-items-baseline">
													<div className="col-md-8 col-12">
														<p>Experience the power of customization by defining the action you want to be performed when a user clicks the link or button. Also, through a single-click action, you can connect Zoho CRM to third-party applications, other Zoho CRM modules, or other Zoho Apps.</p>
													</div>
													
												</div>
												<div className="col-12 mt-3 text-end">
													<button onClick={() => { handleCreateButtonShow() }} type="button" className="btn btn-secondary mx-2">Create Your First Button</button>
													<button onClick={() => { handleCreateLinkShow() }} type="button" className="btn btn-secondary">Create Your First Link</button>
												</div>
												
									      	</div>
									    </TabPanel>
									    <TabPanel>
									    	<div className="tabContent">
									      		<div className="d-flex justify-content-between align-items-baseline">
													<div className="col-md-8 col-12">
														<p>Summary of necessary information about this module</p>
													</div>
												</div>
												<div className="moduleSummaryTable">
													<h5>Primary</h5>
													<table>
														<tbody>
															<tr>
																<td><b>Module</b></td>
																<td>Lead</td>
															</tr>
															<tr>
																<td><b>API Name</b></td>
																<td>Lead</td>
															</tr>
														</tbody>
													</table>
												</div>
												<div className="moduleSummaryTable">
													<h5>Customization</h5>
													<table>
														<tbody>
															<tr>
																<td><b>Module Permission</b></td>
																<td>Administrator , Standard</td>
															</tr>
															<tr>
																<td><b>Layouts</b></td>
																<td>Standard</td>
															</tr>
															<tr>
																<td><b>System Fields</b></td>
																<td>37</td>
															</tr>
															<tr>
																<td><b>Custom Fields</b></td>
																<td>0 out of 512</td>
															</tr>
															<tr>
																<td><b>Buttons</b></td>
																<td>0</td>
															</tr>
															<tr>
																<td><b>Links</b></td>
																<td>0</td>
															</tr>
														</tbody>
													</table>
												</div>
												<div className="moduleSummaryTable">
													<h5>Automation</h5>
													<table>
														<tbody>
															<tr>
																<td><b>Workflows</b></td>
																<td>0</td>
															</tr>
															<tr>
																<td><b>Assignment Rules</b></td>
																<td>0</td>
															</tr>
															<tr>
																<td><b>Approvals</b></td>
																<td>0</td>
															</tr>
															<tr>
																<td><b>Templates</b></td>
																<td>0</td>
															</tr>
														</tbody>
													</table>
												</div>
									      	</div>
									    </TabPanel>
									</Tabs>
			                    </div>
		                    </div>
		                     		
		                     	
		                    	
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    {/*<!-- Modal -->*/}
		    <Modal show={ViewPost}
                    onHide={hanldePostClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
			
			            	<form className="form" onSubmit={layoutAdd}>
			            	<h5 className="modal-title">Add Layout</h5>
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control mb-3" id="name" placeholder="Name" name="name" value={name} onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                   <div className="col-md-12">
                                    	<select value={status} onChange={handleInputChange} name="status" className="form-select form-control-sm mb-3">
								     		<option>Select</option>
								     		<option value="true">True</option>
								     		<option value="false">False</option>
								     		
								     	</select>
                                    </div>
                                    <div className="col-md-12 mt-1">
                                        <div className="form-group text-end">
                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
			                				<button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldePostClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
			            
			</Modal>

			{/*<!-- Modal -->*/}
		    <Modal show={ViewLayoutRule}
                    onHide={hanldeLayoutRuleClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
			
			            	<form className="form">
			            	<h5 className="modal-title">New Layout Rule</h5>
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control mb-3" id="name" placeholder="Enter a rule name" name="name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea type="text" className="form-control mb-3" id="desc" placeholder="Describe your rule" name="desc"></textarea>
                                        </div>
                                    </div>
                                   <div className="col-md-12">
                                   		<label>Choose a primary field that controls this rule.</label>
                                    	<select name="status" className="form-select form-control-sm mb-3">
								     		<option>None</option>
								     		<option value="city">City</option>
								     		<option value="company">Company</option>
								     		<option value="email">Email</option>
								     		<option value="fax">Fax</option>
								     		<option value="fname">First Name</option>
								     		
								     	</select>
                                    </div>
                                    <div className="col-md-12 mt-1">
                                        <div className="form-group text-end">
                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
			                				<button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldeLayoutRuleClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
			            
			</Modal>

		{/*<!-- Modal -->*/}
		    <Modal show={ViewValidRule}
                    onHide={hanldeValidRuleClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
			
			            	<form className="form">
			            	<h5 className="modal-title">Create Validation Rule</h5>
                                <div className="row mt-4">
                                    
                                   <div className="col-md-12">
                                   		<label>Choose field to validate</label>
                                    	<select name="status" className="form-select form-control-sm mb-3">
								     		<option>None</option>
								     		<option value="city">City</option>
								     		<option value="company">Company</option>
								     		<option value="email">Email</option>
								     		<option value="fax">Fax</option>
								     		<option value="fname">First Name</option>
								     		
								     	</select>
                                    </div>
                                    <div className="col-md-12 mt-1">
                                        <div className="form-group text-end">
                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
			                				<button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldeValidRuleClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
			            
			</Modal>

			{/*<!-- Modal -->*/}
		    <Modal show={ViewCreateButton}
                    onHide={hanldeCreateButtonClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
			
			            	<form className="form">
			            	<h5 className="modal-title">Create Your Button</h5>
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                        	<label>What would you like to name the button?</label>
                                            <input type="text" className="form-control mb-3" name="name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                        	<label>Description:</label>
                                            <textarea type="text" className="form-control mb-3" name="desc"></textarea>
                                        </div>
                                    </div>
                                   <div className="col-md-12">
                                   		<label>Where would you like to place the button?</label>
                                    	<select name="status" className="form-select form-control-sm mb-3">
								     		<option>Select</option>
								     		<option >Edit page</option>
								     		<option >View page</option>
								     		<option >Wizards</option>
								     		<option >Related list</option>
								     		<option >First Name</option>
								     		
								     	</select>
                                    </div>
                                    <div className="col-md-12 mt-1">
                                        <div className="form-group text-end">
                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
			                				<button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldeCreateButtonClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
			            
			</Modal>

			{/*<!-- Modal -->*/}
		    <Modal show={ViewCreateLink}
                    onHide={hanldeCreateLinkClose}
                    backdrop="static"
                    keyboard={false}
                    className=""
                    >
			
			            	<form className="form">
			            	<h5 className="modal-title">Create New Link</h5>
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                        	<label>Label</label>
                                            <input type="text" className="form-control mb-3" name="name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                        	<label>Description</label>
                                            <input type="text" className="form-control mb-3" name="desc"/>
                                        </div>
                                    </div>
                                   <div className="col-md-12">
                                        <div className="form-group">
                                        	<label>Build your URL:</label>
                                            <textarea type="text" className="form-control mb-3" name="url"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-1">
                                        <div className="form-group text-end">
                                            <button type="submit" className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
			                				<button type="button" className="btn btn btn-light-dark mx-3" onClick={hanldeCreateLinkClose}><i className="flaticon-cancel-12"></i> Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
			            
			</Modal>

		</>
	);
}

export default ViewModule