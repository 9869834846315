import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {AiOutlineLeft} from 'react-icons/ai';
import Breadcrumb from '../components/Breadcrumb';
import Footer from '../components/Footer';
import {Switch} from 'antd';
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";

const ProfileSetting = () => {

	useRedirectLoggedOutUser("/crm/login");

	const areas = document.querySelectorAll(".profile_setting_sec w-50");

	const observer = new IntersectionObserver(entries => {
	    
	    entries.forEach(entry => {
	        entry.target.classList.toggle("show", entry.isIntersecting);

	        const id = entry.target.getAttribute('id');
	        
	        // if (entry.isIntersecting) {  
	        //     document.querySelector('a[href="#'+ id +'"]').classList.toggle("active-link");
	        // } else {
	        //     document.querySelector('a[href="#'+ id +'"]').classList.remove("active-link");
	        // }
	    
	    });
	});
	
	areas.forEach(area => {
	    observer.observe(area); 
	});

	const [toggle, setToggle] = useState(false);

	const toggler = ()=>{
		toggle?setToggle(false):setToggle(true);
	}

	const [activeLink, setActiveLink] = useState(null);

	const handleLinkClick = (index) => {
	    setActiveLink(index);
	};

	const [value, setvalue] = useState("");
	const handleOnchange = (val) => setvalue(val);

	const options = [
	  { label: "View", value: "view" },
	  { label: "Create", value: "create" },
	  { label: "Edit", value: "edit" },
	  { label: "Delete", value: "delete" }
	];

	const [windowHeight, setWindowHeight] = useState({ winHeight: window.innerHeight })

	const detectSize = () =>{
		setWindowHeight({
			winHeight: window.innerHeight 
		})
	}

	useEffect(() => {
	  window.addEventListener('resize', detectSize);
	  return () => {
	    window.removeEventListener('resize', detectSize)
	  }
	}, [windowHeight])

	/*show Multi-select when checkbox is checked*/

	const [showMultiSelect, setShowMultiSelect] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState([]);

	const handleInputChange = (event) => {
		setShowMultiSelect(event.target.checked);
	};

	const handleMultiSelectChange = (options) => {
		setSelectedOptions(options);
	};
	/*show Multi-select when checkbox is checked*/

		return (
		<div>
			
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        {/*<Sidebar />*/}

		        <div className="profile_setting_sidebar" style={{height : windowHeight.winHeight - 142 }}>
		        	<h6>Module Permissions</h6>
					<ul>
						<li className={activeLink === 0 ? 'active' : ''} onClick={() => handleLinkClick(0)}>
							<a href="#basic">Basic</a>
						</li>
						<li className={activeLink === 1 ? 'active' : ''} onClick={() => handleLinkClick(1)}>
							<a href="#importExport">Import/Export</a>
						</li>
						<li className={activeLink === 2 ? 'active' : ''} onClick={() => handleLinkClick(2)}>
							<a href="#sendEmail">Send Email</a>
						</li>
						<li className={activeLink === 3 ? 'active' : ''} onClick={() => handleLinkClick(3)}>
							<a href="#tools">Tools</a>
						</li>
						<li className={activeLink === 4 ? 'active' : ''} onClick={() => handleLinkClick(4)}>
							<a href="#basicOthers">Others</a>
						</li>
					</ul>
					<h6>Setup Permissions</h6>
					<ul>
						<li className={activeLink === 5 ? 'active' : ''} onClick={() => handleLinkClick(5)}>
							{/*<Link to="">Admin Level</Link>*/}
							<a href="#adminLevel">Admin Level</a>
						</li>
						<li className={activeLink === 6 ? 'active' : ''} onClick={() => handleLinkClick(6)}>
							<a href="#emailTemplate">Email & Templates</a>
						</li>
						<li className={activeLink === 7 ? 'active' : ''} onClick={() => handleLinkClick(7)}>
							<a href="#automation">Automation</a>
						</li>
						<li className={activeLink === 8 ? 'active' : ''} onClick={() => handleLinkClick(8)}>
							<a href="#webform">Webform</a>
						</li>
						<li className={activeLink === 9 ? 'active' : ''} onClick={() => handleLinkClick(9)}>
							<a href="#dataAdministration">Data Administration</a>
						</li>
						<li className={activeLink === 10 ? 'active' : ''} onClick={() => handleLinkClick(10)}>
							<a href="#zia">Zia</a>
						</li>
					</ul>
					<h6>Extension Permissions</h6>
					<ul>
						<li className={activeLink === 11 ? 'active' : ''} onClick={() => handleLinkClick(11)}>
							<a href="#zoho">Zoho</a>
						</li>
						<li className={activeLink === 12 ? 'active' : ''} onClick={() => handleLinkClick(12)}>
							<a href="#extensionOthers">Others</a>
						</li>
						<li className={activeLink === 13 ? 'active' : ''} onClick={() => handleLinkClick(13)}>
							<a href="#social">Social</a>
						</li>
					</ul>
					<h6>Developer Permissions</h6>
					<ul>
						<li className={activeLink === 14 ? 'active' : ''} onClick={() => handleLinkClick(14)}>
							<a href="#apiAccess">API Access</a>
						</li>
						<li className={activeLink === 15 ? 'active' : ''} onClick={() => handleLinkClick(15)}>
							<a href="#manageExtensibility">Manage Extensibility</a>
						</li>
						<li className={activeLink === 16 ? 'active' : ''} onClick={() => handleLinkClick(16)}>
							<a href="#cRMVariables">Manage Zoho CRM Variables</a>
						</li>
					</ul>
		        </div>

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />

		                    <div className="profile_setting_btns">
		                    	<Link to="/crm/security-control" className="btn btn-secondary">Back</Link>
		                    	<button type="button" className="btn btn-secondary">Clone</button>
		                    	<Link t0="" className="btn btn-secondary">View Users</Link>
		                    </div>
		                    
		                    <div className="row layout-top-spacing">

		                    <div className="d-flex align-items-center">
		                    	<h5><Link to="/crm/security-control"><AiOutlineLeft size={18}/>Manager</Link></h5>
					        </div>
		                        
		                    	<div className="profile_setting_rightSide">
									<div id="basic" className="profile_setting_sec w-50">
										<h6>Basic Permissions</h6>
										<ul>
											<li>
												<span>Lead</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Contact</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Meeting</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
										</ul>
									</div>
									<div id="importExport" className="profile_setting_sec w-50">
										<h6>Import/Export Permissions</h6>
										<ul>
											<li>
												<span>Import Records</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Export</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
										</ul>
									</div>
									<div id="sendEmail" className="profile_setting_sec w-50">
										<h6>Send Email Permissions</h6>
										<ul>
											<li>
												<span>Send Email</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Mass Email</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Delete Email</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Mail Merge</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
										</ul>
									</div>
									<div id="tools" className="profile_setting_sec w-50">
										<h6>Tool Permissions</h6>
										<ul>
											<li>
												<span>Mass Update</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Mass Delete</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Change Owner</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Mass Transfer</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Convert</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Mass Convert</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Share</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
										</ul>
									</div>
									<div id="basicOthers" className="profile_setting_sec w-50">
										<h6>Other Permissions</h6>
										<ul>
											<li>
												<span>Print View</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Sheet View</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Find and Merge</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Manage Custom View</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Tags</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
										</ul>
									</div>
									<div id="adminLevel" className="profile_setting_sec w-50">
										<h6>Admin Level Permissions</h6>
										<ul>
											<li>
												<span>User Management</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Manage Compliance Settings</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Modules Customization</span>
												<Switch onClick={toggler}/>
											   
											</li>
											<li>
												<span>Manage Portals</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Manage Portals Users</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Manage Calendar Booking</span>
												<Switch onClick={toggler}/>
											    
											</li>
										</ul>
									</div>
									<div id="emailTemplate" className="profile_setting_sec w-50">
										<h6>Email & Templates</h6>
										<ul>
											<li>
												<span>Email & Chat Settings</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Manage Templates</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
										</ul>
									</div>
									<div id="automation" className="profile_setting_sec w-50">
										<h6>Automation</h6>
										<ul>
											<li>
												<span>Manage Automation</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Case Escalation Rules</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Assignment Rules</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
										</ul>
									</div>
									<div id="webform" className="profile_setting_sec w-50">
										<h6>Webform</h6>
										<ul>
											<li>
												<span>Manage Webform</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>A/B Testing</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Approval</span>
												<div className="d-flex justify-content-start align-items-center w-50">
													<div className="form-check form-switch mx-3">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="11"
															style={{ width: '45px', height: '24px' }}
															onChange={handleInputChange}
														/>
													</div>

													<div className={`multi-select-container ${showMultiSelect ? 'show' : ''}`}>
														{showMultiSelect && (
															<MultiSelect
																className="multi-select"
																onChange={handleMultiSelectChange}
																options={options}
																value={selectedOptions}
																labelledBy="Select"
																customValue={true}
															/>
														)}
													</div>
												</div>
											</li>
											<li>
												<span>Unsubscription Link</span>
												<Switch onClick={toggler}/>
											    
											</li>
										</ul>
									</div>
									<div id="dataAdministration" className="profile_setting_sec w-50">
										<h6>Data Administration</h6>
										<ul>
											<li>
												<span>Migrate data from other CRM</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Import History</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>View Storage Details</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Manage Sandbox</span>
												<Switch onClick={toggler}/>
											    
											</li>
										</ul>
									</div>
									<div id="zia" className="profile_setting_sec w-50">
										<h6>Zia</h6>
										<ul>
											<li>
												<span>Prediction</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Image Validation</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Zia Voice</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Manage Data Enrichment Settings</span>
												<Switch onClick={toggler}/>
											    
											</li>
										</ul>
									</div>
									<div id="zoho" className="profile_setting_sec w-50">
										<h6>Zoho</h6>
										<ul>
											<li>
												<span>Phone Bridge</span>
												<Switch onClick={toggler}/>
											   
											</li>
										</ul>
									</div>
									<div id="extensionOthers" className="profile_setting_sec w-50">
										<h6>Others</h6>
										<ul>
											<li>
												<span>Plug-in for Microsoft-Word</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Zoho Mobile Apps Access</span>
												<Switch onClick={toggler}/>
											    
											</li>
										</ul>
									</div>
									<div id="social" className="profile_setting_sec w-50">
										<h6>Social</h6>
										<ul>
											<li>
												<span>Social Tab</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Social Admin</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Personal Social Profiles</span>
												<Switch onClick={toggler}/>
											    
											</li>
										</ul>
									</div>
									<div id="apiAccess" className="profile_setting_sec w-50">
										<h6>Developer Permissions</h6>
										<ul>
											<li>
												<span>Zoho CRM API Access</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Manage Extensibility</span>
												<Switch onClick={toggler}/>
											    
											</li>
											<li>
												<span>Manage Zoho CRM Variables</span>
												<Switch onClick={toggler}/>
											    
											</li>
										</ul>
									</div>
								</div>
		                        
		                       
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		</div>
	);
}

export default ProfileSetting