import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import shiftService from "./shiftService";

const initialState = {
    Shift: null,
    Shifts: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// Reusable function for handling error and toast notification
const handleError = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
};
// Async thunks
const createAsyncThunkWithHandler = (name, asyncThunk) => {
    return createAsyncThunk(name, async ({ id, ...rest }, thunkAPI) => {
        try {
            return await asyncThunk({ id, ...rest });
        } catch (error) {
            return thunkAPI.rejectWithValue({ message: handleError(error), id, ...rest });
        }
    });
};
export const addShift = createAsyncThunkWithHandler("addShift", shiftService.addShift);
export const getShift = createAsyncThunkWithHandler("getShift", shiftService.getShift);
export const updateShift = createAsyncThunkWithHandler("updateShift", shiftService.updateShift);
export const deleteShift = createAsyncThunkWithHandler("deleteShift", shiftService.deleteShift);

// Slice
const shiftSlice = createSlice({
    name: 'shift',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith("/pending") || action.type.endsWith("/fulfilled") || action.type.endsWith("/rejected"),
                (state, action) => {
                    state.isLoading = action.type.endsWith("/pending");
                    state.isSuccess = action.type.endsWith("/fulfilled");
                    state.isError = action.type.endsWith("/rejected");

                    if (action.type.endsWith("/fulfilled")) {
                        const { payload } = action;
                        state.Shifts = payload ? payload : state.Shifts;
                        state.Shift = payload ? payload : state.Shift;
                    }

                    if (action.type.endsWith("/rejected")) {
                        state.message = action.payload;
                        toast.error(action.payload);
                    }
                }
            );

    },
});

export const selectShift = (state) => state.calendar.Shift;

export default shiftSlice.reducer;
