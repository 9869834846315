import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import {
  getCourses,
}   from "../../redux/crm-features/course/courseSlice";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";

function HeaderAndFooterExample() {

    useRedirectLoggedOutUser("/crm/login");

    const dispatch = useDispatch();
    const { courses } = useSelector(
      (state) => state.course
  );

  useEffect(() => {
      dispatch(getCourses());
  }, [dispatch]);

    return (

        <>
            <div className="main-container px-5" id="container">
                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <div className="main-content" id="content">
                    <div className="layout-px-spacing">
                        <div className="middle-content container-xxl p-0">
                            <div className="row layout-top-spacing">
                                <div className="col-lg-3 col-md-3 col-sm-3 mb-4">
                                    <input className="form-control" id="t-text" name="txt" placeholder="Search" required type="text"/>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3 mb-4 ms-auto">
                                    <select aria-label="Default select example" className="form-select form-select">
                                        <option selected>All Category</option>
                                        <option value="3">Training</option>
                                        <option value="4">Work</option>
                                    </select>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3 mb-4">
                                    <select aria-label="Default select example" className="form-select form-select">
                                        <option selected>Sort By</option>
                                        <option value="1">Recent</option>
                                        <option value="2">Most Upvoted</option>
                                        <option value="3">Popular</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row py-4">
                                <h4>Your Enrolled Courses</h4>
                            </div>
                            <div className="row">
                            {
                                courses.map((course,index) => {
                                    return(
                                        <div key={index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-4">
                                            <Link to={`/crm/courseview/${course._id}`} className="card style-2 mb-md-0 mb-4">
                                                <img alt="..." className="card-img-top" src={course.courseThumbnail}/>
                                                <div className="card-body px-0 pb-0">
                                                    <h5 className="card-title mb-3">{course.courseTitle}</h5>
                                                    <div className="media mt-4 mb-0 pt-1">
                                                        <img alt="" className="card-media-image me-3" src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"/>
                                                        <div className="media-body">
                                                            <h4 className="media-heading mb-1">{course.courseInstructor}</h4>
                                                            <small className="media-text">{moment(course.createdAt).format("DD MMM")}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                                
                            }
                                
                                {/*<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-4">
                                    <Link to="/crm/courseview" className="card style-2 mb-md-0 mb-4">
                                        <img alt="..." className="card-img-top" src="https://dummyimage.com/600x400/000/fff"/>
                                        <div className="card-body px-0 pb-0">
                                            <h5 className="card-title mb-3">Accounts Assistant_LDN</h5>
                                            <div className="media mt-4 mb-0 pt-1">
                                                <img alt="" className="card-media-image me-3" src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"/>
                                                <div className="media-body">
                                                    <h4 className="media-heading mb-1">Shaun Park</h4>
                                                    <p className="media-text">01 May</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>*/}
                            </div>
                            <div className="row py-4">
                                <h4>Course Catalog</h4>
                            </div>
                            <div className="row">
                            {
                                courses.map((course,index) => {
                                    return(
                                        <div key={index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-4">
                                            <Link to={`/crm/courseview/${course._id}`} className="card style-2 mb-md-0 mb-4">
                                                <img alt="..." className="card-img-top" src={course.courseThumbnail}/>
                                                <div className="card-body px-0 pb-0">
                                                    <h5 className="card-title mb-3">{course.courseTitle}</h5>
                                                    <div className="media mt-4 mb-0 pt-1">
                                                        <img alt="" className="card-media-image me-3" src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"/>
                                                        <div className="media-body">
                                                            <h4 className="media-heading mb-1">{course.courseInstructor}</h4>
                                                            <small className="media-text">{moment(course.createdAt).format("DD MMM")}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                                
                            }
                                {/*
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-4">
                                    <Link to="/crm/courseview" className="card style-2 mb-md-0 mb-4">
                                        <img alt="..." className="card-img-top" src="https://dummyimage.com/600x400/5941c4/fff"/>
                                        <div className="card-body px-0 pb-0">
                                            <h5 className="card-title mb-3">Hybrid Bookkeeping and Payroll _Online</h5>
                                            <div className="media mt-4 mb-0 pt-1">
                                                <img alt="" className="card-media-image me-3" src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"/>
                                                <div className="media-body">
                                                    <h4 className="media-heading mb-1">Shaun Park</h4>
                                                    <p className="media-text">01 May</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>*/}
                            
                            </div>
                        </div>
                    </div>

                </div>
                {/*<Card style={{ width: '18rem' }}> <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> <Card.Body> <Card.Title>Special title treatment</Card.Title> <Card.Text> With supporting text below as a natural lead-in to additional content. </Card.Text> <Button variant="primary">Go somewhere</Button> </Card.Body> </Card>*/}
            </div>
        </>

    );
}

export default HeaderAndFooterExample;