import React,{useEffect,useState,useMemo} from 'react';
import ReactDOM from "react-dom";
import { Link,useNavigate } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { toast } from "react-toastify";
import MaterialReactTable from 'material-react-table';
import { AiOutlineSearch } from "react-icons/ai";

const globalSet = [
	{
		name: 'Source',

		usedIn: '-',

		modifyBy: '-',
		
		createBy: '-',
	},
	{
		name: 'Industry',

		usedIn: '-',

		modifyBy: '-',
		
		createBy: '-',
	},
];

const GlobalSet = () => {
	
	useRedirectLoggedOutUser("/crm/login");

	const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`, //alternate way
        id: 'name', //id required if you use accessorFn instead of accessorKey
        header: 'Global Set Name',
        Header: () => <span>Global Set Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          <span className=""><Link to={`/crm`}><b>{renderedCellValue}</b></Link>
		        
		      </span>
        ),
      },
      {
        accessorFn: (row) => row.usedIn, //alternate way
        id: 'usedIn', //id required if you use accessorFn instead of accessorKey
        header: 'Used In',
        Header: () => <span>Used In</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.createBy, //alternate way
        id: 'createBy', //id required if you use accessorFn instead of accessorKey
        header: 'Created By',
        Header: () => <span>Created By</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.modifyBy, //alternate way
        id: 'modifyBy', //id required if you use accessorFn instead of accessorKey
        header: 'Modify By',
        Header: () => <span>Modify By</span>, //optional custom header render
      },
    ],
    [],
  );

	return(
		<>

		    {/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />
		                    
		                    <div className="row layout-top-spacing">

		                    	<div className="tabMenu d-flex justify-content-between">
		                        	<ul>
		                        		<li><Link className="btn btn-secondary" to="/crm/modules">Modules</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/tab-group">Tab Groups</Link></li>
		                        		<li><Link className="btn btn-secondary" to="/crm/web-tab">Web Tabs</Link></li>
		                        		<li><Link className="btn btn-secondary active" to="/crm/global-set">Global Sets</Link></li>
		                        	</ul>
		               
		                        </div>

		                        <div className="col-12 mt-4">
			                        <div className="d-flex align-items-center justify-content-between">
			                        	<div>
			                        		<h5 className="">Global Sets</h5>
					                        <p>
					                        	Global Sets are custom picklist values set that could be associated with multiple picklist fields across modules.
					                        </p>
			                        	</div>
				                       
			                        </div>
			                        <div className="d-flex justify-content-between align-items-baseline flex-wrap mt-3">
																<div className="col-md-4 col-12">
																	<form className="custom-search">
																		<div className="position-relative">
																			<input type="text" placeholder="Search" className="form-control" />
																			<button><AiOutlineSearch size={18}/></button>
																		</div>
																	</form>
																</div>
																<button className="btn btn-secondary">Create Global Set</button>
															</div>
		                        </div>
		                        
			                    <div className="statbox widget box box-shadow">
		                            <div className="widget-content widget-content-area">
		                              
		                             	<MaterialReactTable columns={columns} data={globalSet} enableRowSelection={true}/>
		                              
		                            </div>
		                        </div>
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}

		    
		</>
	);
}

export default GlobalSet