import React,{useState,useEffect} from 'react';
import ReactDOM from "react-dom";
import { Link,useNavigate,useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCourse,
  getCourseByTitle,
}   from "../../redux/crm-features/course/courseSlice";

const CourseCurriculum = () => {

	useRedirectLoggedOutUser("/crm/login");
	
	const { courseTitle } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { course } = useSelector(
      (state) => state.course
  	);

  	useEffect(() => {
      dispatch(getCourseByTitle(courseTitle));
  	}, [dispatch, courseTitle]);

	const [courseAIModel, SetCourseAIModelShow] = useState(false)
	const handleCourseAIModelShow = () => { SetCourseAIModelShow(true) }
	const hanldeCourseAIModelClose = () => { SetCourseAIModelShow(false) }
	
	return (
		<>
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing d-flex flex-column justify-content-center align-items-center">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />

		                    <div className="row layout-top-spacing">

		                    	<div className="courseCurriculumHeading">
		                    		<span><Link to="/crm/all-courses">Course</Link> /  {courseTitle}</span>
		                    		<h3>Curriculum</h3>
		                    	</div>

		                    	<div className="courseCurriculumSec row">
		                    		<div className="col-12 text-center mb-2">
		                    			<h4 className="m-0">Build your course outline</h4>
		                    			<p>Choose the option that best suits your needs</p>
		                    		</div>
		                    		<div className="col-lg-4 col-md-6 col-sm-12 col-12">
			                            <div className="courseCurriculumBox">
			                            	<Link to={`/crm/course-from-scratch/${courseTitle}`}>
			                            		<img src="../../../src/assets/assets/img/01_create.png"/>
			                            		<h5>Build from scratch</h5>
			                            		<span>Manually enter your course outline</span>
			                            	</Link>
			                            </div>
			                        </div>
			                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
			                            <div className="courseCurriculumBox">
			                            	<Link to="#" onClick={() => { handleCourseAIModelShow() }}>
			                            		<img src="../../../src/assets/assets/img/06_easy.png"/>
			                            		<h5>Generate with AI</h5>
			                            		<span>Create your online by providing a simple description</span>
			                            	</Link>
			                            </div>
			                        </div>
			                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
			                            <div className="courseCurriculumBox">
			                            	<Link to="/crm/course/curriculum-import">
			                            		<img src="../../../src/assets/assets/img/07_directions.png"/>
			                            		<h5>Copy from</h5>
			                            		<span>Copy sections and lessons from an existing course</span>
			                            	</Link>
			                            </div>
			                        </div>
			                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
			                            <div className="courseCurriculumBox uploadCourseFiles">
			                            	<Link to="/">
			                            		<h5>Bulk upload</h5>
			                            		<span>upload multiple files to generate your outline</span>
			                            	</Link>
			                            </div>
			                        </div>

		                    	</div>
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}	

		    {/*<!-- Modal -->*/}
		    <Modal show={courseAIModel}
                    onHide={hanldeCourseAIModelClose}
                    backdrop="static"
                    keyboard={false}
                    className="courseAIModel"
                    >
					<h4>Jump-start your course</h4>	
					<p>Use the power of AI to generate an outline, lesson text content, and a sales page ba</p>
				
					<form >
				        <div className="single-form mb-3">
				        	<label>Describe your course</label>
				        	<span>Write a thorough description of what your course will contain, you can even include additional information like tone of voice.</span>
				        	<textarea className="form-control" type="text"></textarea>
				       	</div>
				        <button className="m-1 btn btn-secondary" type="submit">Generate Course</button>
				    </form>
					    
				<div className="form-group text-end">
					<button onClick={hanldeCourseAIModelClose} className="m-1 btn btn-secondary">Close</button>
				</div>
			</Modal>
		</>
	)
}

export default CourseCurriculum