import React,{useState,useRef,useEffect,useReducer,useLayoutEffect} from 'react';
import ReactDOM from "react-dom";
import { Link,useNavigate,useParams } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { toast } from "react-toastify";
import { Editor } from '@tinymce/tinymce-react';
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCourse,
  getCourses,
  getCourse,
  updateCourse,
}   from "../../redux/crm-features/course/courseSlice";

const cloud_name = process.env.REACT_APP_CLOUD_NAME;
const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;

const CourseDetail = () => {

	useRedirectLoggedOutUser("/crm/login");
	
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { courses, course } = useSelector(
      (state) => state.course
  );

  const initialState = {
    courseTitle: course?.courseTitle,
    courseQnA: course?.courseQnA,
    courseSkills: course?.courseSkills,
    courseOverview: course?.courseOverview,
    courseThumbnail: course?.courseThumbnail,
  }

  useEffect(() => {
      dispatch(getCourse(id));
  }, [dispatch, id]);

  const [courseFormData, setCourseFormData] = useState(initialState);
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	// if (courseFormData) {
	//   const { courseTitle, courseQnA, courseSkills, courseOverview, courseThumbnail } = courseFormData;
	//   // Use the destructured properties
	// }
	const [imagePreview, setImagePreview] = useState(null);
	const [profileImage, setProfileImage] = useState(null);

	const handleImageChange = (e) => {
		setProfileImage(e.target.files[0]);
    	setImagePreview(URL.createObjectURL(e.target.files[0]));
	}

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setCourseFormData({...courseFormData, [name]: value});
	}

	const [courseSkill, setCourseSkill] = useState("");
	const handleOnchange = (courseSkill) =>{
		setCourseSkill(courseSkill);
	} 
		// console.log('skills:',courseSkill)

	const courseOverviewEditorRef = useRef(null);
   const courseOverviewLog = () => {
     if (courseOverviewEditorRef.current) {
       console.log(courseOverviewEditorRef.current.getContent());
     }
   };

   const courseQnAEditorRef = useRef(null);
   const courseQnAlog = () => {
     if (courseQnAEditorRef.current) {
       console.log(courseQnAEditorRef.current.getContent());
     }
   };
   
	const courseEdit = async (e) => {
		e.preventDefault();

		let imageURL;

		if (profileImage !== null &&(profileImage.type === "image/jpeg" || profileImage.type === "image/jpg" ||
				 profileImage.type === "image/png"))
			{

				const image = new FormData();
		        image.append("file", profileImage);
		        image.append("cloud_name", cloud_name);
		        image.append("upload_preset", upload_preset);

		        // Save image to Cloudinary
		        const response = await fetch(
		          "https://api.cloudinary.com/v1_1/kbm/image/upload",
		          { method: "post", body: image }
		        );
		        const imgData = await response.json();
		        // console.log(imgData);
		        imageURL = imgData.url.toString();

			}

		const courseData = {
			courseTitle:courseFormData?.courseTitle, 
			courseQnA: courseQnAEditorRef.current.getContent(),  
			courseSkills: courseSkill, 
			courseOverview: courseOverviewEditorRef.current.getContent(), 
			courseThumbnail: profileImage ? imageURL : courseFormData?.courseThumbnail,
		};

		console.log(courseData)
		
		await dispatch(updateCourse({id, courseData}));
		await dispatch(getCourses());
		forceUpdate();
		navigate('/crm/all-courses');
		// window.location.reload();
		// await dispatch(sendVerificationEmail());
	};

	// console.log(users);

	
	useLayoutEffect(() => {
	    if (course) {
	      setCourseFormData({
	        ...courseFormData,
	        courseTitle: course?.courseTitle,
	        courseQnA: course?.courseQnA,
	        courseSkills: course?.courseSkills,
	        courseOverview: course?.courseOverview,
	        courseThumbnail: course?.courseThumbnail,
	        
	      });
	    }
	}, [course]);

	const linkOneRef = useRef(null);
  const linkTwoRef = useRef(null);

  const copyText = (textRef) => {
    const textToCopy = textRef.current.innerText;

    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    // Select the text within the textarea
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text to clipboard
    document.execCommand('copy');

    // Remove the temporary textarea
    document.body.removeChild(textarea);

    // Provide feedback to the user
    alert('Text copied to clipboard: ' + textToCopy);
  };

  const options = [
	  { label: "Demo1", value: "Demo1" },
	  { label: "Demo2", value: "Demo2" },
	  { label: "Demo3", value: "Demo3" },
	  { label: "Demo4", value: "Demo4" }
	];

	return (
		<>
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />

		                    <div className="row layout-top-spacing">

		                    	<div className="courseCurriculumHeading d-flex justify-content-between align-items-center">
		                    		<div className="">
		                    			<span><Link to="/crm/all-courses">Course</Link> / {course && (<>{course.courseTitle}</>) }</span>
		                    			<h3>Setup guide</h3>
		                    		</div>
		                    		<button onClick={courseEdit} className="btn btn-secondary _effect--ripple waves-effect waves-light">Save</button>
		                    	</div>

		                    	<div className="courseSetup">
		                    		<div className="row">
			                    		<div className="col-md-8 col-12">
			                    			<div className="courseSetupLeftSide">
			                    				<h4>Create your curriculum</h4>
			                    				<div className="curriculumSetupPanel">
			                    					<div className="d-flex justify-content-between align-items-baseline">
			                    						<h5>Curriculum Preview</h5>
			                    						<Link to="/crm/course/start-from-scratch" className="btn btn-secondary _effect--ripple waves-effect waves-light">Edit Curriculum</Link>
			                    					</div>
			                    					<div className="curriculumPanelList">
			                    						<div className="">
			                    							<h6>Bookkeeping transactions</h6>
			                    							<ul>
			                    								<li>
			                    									<span>Introduction & Books of prime entry</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    								<li>
			                    									<span>Sales related day books</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    								<li>
			                    									<span>Purchases related day books</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    								<li>
			                    									<span>Double entry</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    								<li>
			                    									<span>VAT</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    							</ul>
			                    						</div>
			                    						<div className="">
			                    							<h6>Accounting principles</h6>
			                    							<ul>
			                    								<li>
			                    									<span>SOFP Proforma</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    								<li>
			                    									<span>SOFP Proforma</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    								<li>
			                    									<span>Accounting Equation</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    								<li>
			                    									<span>Organisational Policies & Procedures</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    								<li>
			                    									<span>Ethical Fundamental principles</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    							</ul>
			                    						</div>
			                    						<div className="">
			                    							<h6>Purchase of non current assets</h6>
			                    							<ul>
			                    								<li>
			                    									<span>Introduction of non current assets</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    								<li>
			                    									<span>Capital Vs Revenue Expenditure</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    								<li>
			                    									<span>VAT on Non current asset</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    								<li>
			                    									<span>Authorising Capital expenditure</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    								<li>
			                    									<span>Test your learning</span>
			                    									<small>1 Video</small>
			                    								</li>
			                    							</ul>
			                    						</div>
			                    					</div>
			                    				</div>
			                    				<h4 className="mt-3">Sell your course</h4>
			                    				<div className="curriculumSetupPanel">
			                    					<div className="d-flex justify-content-between align-items-baseline">
			                    						<h5>Pricing</h5>
			                    						<Link to="/" className="btn btn-secondary _effect--ripple waves-effect waves-light">Manage Pricing</Link>
			                    					</div>
			                    					<div className="pricingPanel">
				                    					<div className="d-flex flex-column">
				                    						<span>One time payment</span>
	                    									<small>£99 one time payment</small>
				                    					</div>
                    									<small><i className="fa fa-clone"></i> Copy link</small>
			                    					</div>
			                    				</div>
			                    				<div className="curriculumSetupPanel">
			                    					<div className="d-flex justify-content-between align-items-baseline">
			                    						<h5>Sales page</h5>
			                    						<Link to="/" className="btn btn-secondary _effect--ripple waves-effect waves-light">Edit sales page</Link>
			                    					</div>
			                    					<div className="pricingPanel">
                    									<span ref={linkOneRef}>https://kbmtr.teachable.com/p/advanced-bookkeeping</span>
        															<small  onClick={() => copyText(linkOneRef)}><i className="fa fa-clone"></i> Copy link</small>
			                    					</div>
			                    				</div>
			                    				<div className="curriculumSetupPanel">
			                    					<div className="d-flex justify-content-between align-items-baseline">
			                    						<h5>Post-purchase page</h5>
			                    						<Link to="/" className="btn btn-secondary _effect--ripple waves-effect waves-light">Edit page</Link>
			                    					</div>
			                    					<div className="pricingPanel">
                    									<span ref={linkTwoRef}>https://kbmtr.teachable.com/p/advanced-bookkeeping-purchased</span>
        															<small onClick={() => copyText(linkTwoRef)}><i className="fa fa-clone"></i> Copy link</small>
			                    					</div>
			                    				</div>
			                    				<div className="curriculumSetupPanel">
			                    					<div className="d-flex justify-content-between align-items-baseline">
			                    						<h5>Overview</h5>
			                    					</div>
			                    					<div className="editorPanel">
																			<Editor
																				apiKey='511npkfmj2oio25sykoybbl32gny8yfwq0zbl3m5zrkzqoz7'
																				onInit={(evt, editor) => courseOverviewEditorRef.current = editor}
																				initialValue={courseFormData?.courseOverview}
																				init={{
																					height: 350,
																					menubar: true,
																					plugins: [
																						'advlist autolink lists link image charmap print preview anchor',
																						'searchreplace visualblocks code fullscreen',
																						'insertdatetime media table paste code help wordcount'
																					],
																					toolbar:
																						'undo redo | formatselect | bold italic backcolor | \
													                                    alignleft aligncenter alignright alignjustify | \
													                                    bullist numlist outdent indent | removeformat | help'
																				}}
																				// onKeyUp={handleEditorChange}
																			/>
																			{/*<button onClick={courseOverviewLog}>Log editor content</button>*/}
			                    					</div>
			                    				</div>
			                    				<div className="curriculumSetupPanel">
			                    					<div className="d-flex justify-content-between align-items-baseline">
			                    						<h5>Questions & Answers</h5>
			                    					</div>
			                    					<div className="editorPanel">
																			<Editor
																				apiKey='511npkfmj2oio25sykoybbl32gny8yfwq0zbl3m5zrkzqoz7'
																				onInit={(evt, editor) => courseQnAEditorRef.current = editor}
																				initialValue={courseFormData?.courseQnA}
																				init={{
																					height: 350,
																					menubar: true,
																					plugins: [
																						'advlist autolink lists link image charmap print preview anchor',
																						'searchreplace visualblocks code fullscreen',
																						'insertdatetime media table paste code help wordcount'
																					],
																					toolbar:
																						'undo redo | formatselect | bold italic backcolor | \
													                                    alignleft aligncenter alignright alignjustify | \
													                                    bullist numlist outdent indent | removeformat | help'
																				}}
																			/>
																			{/*<button onClick={courseQnAlog}>Log editor content</button>*/}
			                    					</div>
			                    				</div>
			                    				<div className="curriculumSetupPanel">
			                    					<div className="d-flex justify-content-between align-items-baseline">
			                    						<h5>Skills</h5>
			                    					</div>
			                    					<div className="skillsPanel">
																			<MultiSelect
																        className="multi-select"
																        onChange={(e) => {handleOnchange(e)}}
																        options={options}
																        customValue={true}
																      />
																      <span>{course && (<>{course.courseSkills}</>) }</span>
			                    					</div>
			                    				</div>
			                    			</div>
			                    		</div>
			                    		<div className="col-md-4 col-12">
			                    			<div className="courseSetupRightSide">
			                    				<h4>Customize your course</h4>
			                    				<div className="curriculumSetupPanel">
			                    					<div className="d-flex justify-content-between align-items-baseline">
			                    						<h5>Course title</h5>
			                    						<button onClick={courseEdit} className="btn btn-secondary _effect--ripple waves-effect waves-light">Edit title</button>
			                    					</div>
			                    					<div className="titlePanel">
			                    						<input name="courseTitle" type="text" value={courseFormData?.courseTitle} onChange={handleInputChange}/>
                    									{/*<span className="text-capitalize">{course && (<>{course.courseTitle}</>) }</span>*/}
			                    					</div>
			                    				</div>
			                    				<div className="curriculumSetupPanel">
			                    					<div className="d-flex justify-content-between align-items-baseline">
			                    						<h5>Thumbnail</h5>
			                    						<Link to="/" className="btn btn-secondary _effect--ripple waves-effect waves-light">Edit image</Link>
			                    					</div>
			                    					<div className="titlePanel">
			                    						<div className="profile_image_upload">
	                                    	<img style={{width : '100%'}} src={imagePreview === null ? course?.courseThumbnail : imagePreview} alt="avatar"/>
	                                    	<input type="file" accept="image/*" name="image" onChange={handleImageChange}/>
	                                    </div>
                    									{/*<img style={{width : '100%'}} src={courseData.courseThumbnail}/>*/}
			                    					</div>
			                    				</div>
			                    				<div className="curriculumSetupPanel">
			                    					<div className="d-flex justify-content-between align-items-baseline">
			                    						<h5>Curriculum layout</h5>
			                    						<Link to="/" className="btn btn-secondary _effect--ripple waves-effect waves-light">Edit template</Link>
			                    					</div>
			                    					<div className="titlePanel">
                    									<span>Classic</span>
			                    					</div>
			                    				</div>
			                    			</div>
			                    		</div>
			                    	</div>
		                    	</div>
		                    	
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}	
		</>
	)
}

export default CourseDetail