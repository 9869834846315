import React,{useState,useRef,useEffect} from 'react';
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { AiOutlineEllipsis,AiOutlineSearch } from "react-icons/ai";
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Editor } from '@tinymce/tinymce-react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const CourseContent = () => {

	const [textImageModal, SetTextImageModalShow] = useState(false)
	const handleTextImageModalShow = () => { SetTextImageModalShow(true) }
	const hanldeTextImageModalClose = () => { SetTextImageModalShow(false) }

	const [fileViewerModal, SetFileViewerModalShow] = useState(false)
	const handleFileViewerModalShow = () => { SetFileViewerModalShow(true) }
	const hanldeFileViewerModalClose = () => { SetFileViewerModalShow(false);setFile(null); }

	const [videoViewerModal, SetVideoViewerModalShow] = useState(false)
	const handleVideoViewerModalShow = () => { SetVideoViewerModalShow(true) }
	const hanldeVideoViewerModalClose = () => { SetVideoViewerModalShow(false) }

	const [quizModal, SetQuizModalShow] = useState(false)
	const handleQuizModalShow = () => { SetQuizModalShow(true) }
	const hanldeQuizModalClose = () => { SetQuizModalShow(false) }

	const handleEditorChange = (content, editor) => {
    	console.log('Content was updated:', content);
  	};

  const [videoUrl, setVideoUrl] = useState('');

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setVideoUrl(url);
  };

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const [sections, setSections] = useState([]);

	useEffect(() => {
	    // Initialize with one section when the component mounts
	    addSection();
	}, []);

	const addSection = () => {
	    const newSection = {
	      id: sections.length,
	      panels: [] // Each section starts with an empty list of panels
	    };
	    setSections([...sections, newSection]);
	};

	const deleteSection = (sectionId) => {
    	setSections(prevSections => prevSections.filter(section => section.id !== sectionId));
  	};

	const addPanel = (sectionId, panelContent) => {
	    setSections(prevSections => {
	      return prevSections.map(section => {
	        if (section.id === sectionId) {
	          // Add a new panel to the specified section
	          return {
	            ...section,
	            panels: [...section.panels, { id: section.panels.length, content: panelContent }]
	          };
	        }
	        return section;
	      });
	    });
	};

	const deletePanel = (sectionId, panelId) => {
	    setSections(prevSections => {
	        return prevSections.map(section => {
	            if (section.id === sectionId) {
	                // Filter out the panel with the specified panelId
	                const updatedPanels = section.panels.filter(panel => panel.id !== panelId);
	                // Return the updated section with filtered panels
	                return {
	                    ...section,
	                    panels: updatedPanels
	                };
	            }
	            return section;
	        });
	    });
	};

	const handleDragStart = (event, section) => {
  event.dataTransfer.setData('section', JSON.stringify(section));
};

const handleDragOver = (event) => {
  event.preventDefault();
};

const handleDrop = (event, targetIndex) => {
  const droppedItem = JSON.parse(event.dataTransfer.getData('section'));
  const newSections = [...sections];
  const currentIndex = newSections.findIndex((section) => section.id === droppedItem.id);
  newSections.splice(currentIndex, 1);
  newSections.splice(targetIndex, 0, droppedItem);
  setSections(newSections);
};



	return (
		<>
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
			<div className="main-container" id="container">

				<div className="overlay"></div>
				<div className="search-overlay"></div>

				<Sidebar/>

				{/*<!--  BEGIN CONTENT AREA  -->*/}
				<div id="content" className="main-content">
					<div className="layout-px-spacing">

						<div className="middle-content container-xxl p-0">

							<Breadcrumb/>

							<div className="row layout-top-spacing">

								<div className="courseCurriculumHeading col-12">
									<span><Link to="/">Course</Link> / <Link to="/">Test</Link> / New Lesson</span>
									<h3>Curriculum</h3>
									
								</div>

								<div className="lessonCOntentSec col-md-8 col-sm-12 col-12">
									<div className="lessonCOntent">
										<div className="lessonCOntentHead">
											<h4>New Lesson</h4>
											<button type="submit" className="btn btn-secondary mx-1">Preview</button>
											<div className="lessonCOntentDropdown">
												{['end'].map(
													(direction) => (
														<DropdownButton
															key={direction}
															id={`dropdown-button-drop-${direction}`}
															drop={direction}
															title={<i className="fa fa-ellipsis-v"></i>}
														>
															<li>
																<span>Rename Lesson</span>
															</li>
															<li>
																<span>Delete Lesson</span>
															</li>

														</DropdownButton>
													),
												)}
											</div>
										</div>
										<div className="lessonContentBody">
											<h5>You've got knowledge, its time to share it!</h5>
											<button type="submit" className="btn btn-secondary mt-3">Add Content
											</button>
										</div>
									</div>
								</div>
								<div className="addContentSec col-md-4 col-sm-12 col-12">
									<div className="addContentOption">
										<h4>Add Content</h4>
										<ul>
											<li onClick={() => {
												handleTextImageModalShow()
											}}>
			                      			<span>
			                      				<i className="fa fa-align-left"></i>
			                      				Text & Images
			                      			</span>
											</li>
											<li onClick={() => {
												handleVideoViewerModalShow()
											}}>
			                      			<span>
			                      				<i className="fa fa-youtube-play"></i>
			                      				Video
			                      			</span>
											</li>
											<li onClick={() => {
												handleFileViewerModalShow()
											}}>
			                      			<span>
			                      				<i className="fa fa-file-text-o"></i>
			                      				File Preview
			                      			</span>
											</li>
											<li onClick={() => {
												handleQuizModalShow()
											}}>
			                      			<span>
			                      				<i className="fa fa-table"></i>
			                      				Quiz
			                      			</span>
											</li>
										</ul>
									</div>
								</div>

							</div>

						</div>

					</div>

					<Footer/>

				</div>
				{/*<!--  END CONTENT AREA  -->*/}

			</div>
		    {/*<!-- END MAIN CONTAINER -->*/}	

		    {/*<!-- Modal -->*/}
		    <Modal show={textImageModal}
                    onHide={hanldeTextImageModalClose}
                    backdrop="static"
                    keyboard={false}
                    className="textImageModal modal-lg w-100"
                    >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>Text and images block</h4>	
                    </Modal.Title>
                    <span onClick={hanldeTextImageModalClose}><i className="fa fa-times"></i></span>
                </Modal.Header>
                <Modal.Body>
					<div className="customClassicEditor">
						<Editor
							apiKey='511npkfmj2oio25sykoybbl32gny8yfwq0zbl3m5zrkzqoz7'
							//initialValue="<p>This is the initial content of the editor</p>"
							init={{
								height: 500,
								menubar: true,
								plugins: [
									'advlist autolink lists link image charmap print preview anchor',
									'searchreplace visualblocks code fullscreen',
									'insertdatetime media table paste code help wordcount'
								],
								toolbar:
									'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | help'
							}}
							onEditorChange={handleEditorChange}
						/>
					</div>
                </Modal.Body>
				<Modal.Footer>
					<div className="form-group text-end">
						<button onClick={hanldeTextImageModalClose} className="m-1 btn btn-secondary">Close</button>
					</div>
				</Modal.Footer>
			</Modal>

			{/*<!-- Modal -->*/}
		    <Modal show={fileViewerModal}
                    onHide={hanldeFileViewerModalClose}
                    backdrop="static"
                    keyboard={false}
                    className="textImageModal modal-lg w-100"
                    >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>File Upload</h4>	
                    </Modal.Title>
                    <span onClick={hanldeFileViewerModalClose}><i className="fa fa-times"></i></span>
                </Modal.Header>
                <Modal.Body>
      							<div>
								      <input type="file" onChange={handleFileChange} />
								      {file && (
								        <div>
								          <DocViewer documents={[{ uri: URL.createObjectURL(file) }]} pluginRenderers={DocViewerRenderers} />
								        </div>
								      )}
								    </div>
                </Modal.Body>
				<Modal.Footer>
					<div className="form-group text-end">
						<button onClick={hanldeFileViewerModalClose} className="m-1 btn btn-secondary">Close</button>
					</div>
				</Modal.Footer>
			</Modal>

			{/*<!-- Modal -->*/}
		    <Modal show={videoViewerModal}
                    onHide={hanldeVideoViewerModalClose}
                    backdrop="static"
                    keyboard={false}
                    className="textImageModal modal-lg w-100"
                    >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>Video Upload</h4>	
                    </Modal.Title>
                    <span onClick={hanldeVideoViewerModalClose}><i className="fa fa-times"></i></span>
                </Modal.Header>
                <Modal.Body>
									<div>
							      <input type="file" accept="video/*" onChange={handleVideoChange} />
							      {videoUrl && (
							        <>
							          <video
							            src={videoUrl}
							            controls
							            // autoPlay={true}
							          />
							        </>
							      )}
							    </div>
                </Modal.Body>
				<Modal.Footer>
					<div className="form-group text-end">
						<button onClick={hanldeVideoViewerModalClose} className="m-1 btn btn-secondary">Close</button>
					</div>
				</Modal.Footer>
			</Modal>

			{/*<!-- Modal -->*/}
		    <Modal show={quizModal}
                    onHide={hanldeQuizModalClose}
                    backdrop="static"
                    keyboard={false}
                    className="textImageModal modal-lg w-100"
                    >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>Quiz block</h4>	
                    </Modal.Title>
                    <span onClick={hanldeQuizModalClose}><i className="fa fa-times"></i></span>
                </Modal.Header>
                <Modal.Body>
						<div className="">
						{sections.map((section, sectionIndex) => (
							<div key={section.id} className="quizSec section" draggable
        onDragStart={(event) => handleDragStart(event, section)}
        onDragOver={(event) => handleDragOver(event)}
        onDrop={(event) => handleDrop(event, sectionIndex)}>
					          	<div>
          							<button type="button" className="dndBtn"><i className="fa fa-th"></i></button>
					          	</div>
          						<div className="dndQuizSec">
								<div className="quizQuestion">
									<div className="d-flex align-items-center justify-content-between">
										<h6>QUESTION</h6>
										<ul className="quizHeadBtn">
											<li><button className="m-1 btn btn-secondary">Done</button></li>
											<li onClick={() => deleteSection(section.id)}><button className="m-1 btn btn-secondary"><i className="fa fa-trash-o"></i></button></li>
										</ul>
									</div>
									<div className="col-md-12">
		                                <div className="form-group">
		                                    <input type="text" className="form-control mt-3" placeholder="Write your question here" />
		                                </div>
		                            </div>
								</div>
						    	<div className="quizAnswer">
									<p>ANSWERS Choose single or multiple correct answers</p>
									{section.panels.map((panel, index) => (
							        <div key={panel.id} className="panel d-flex align-items-center">
							        <div>
	          							<button type="button" className="dndBtn"><i className="fa fa-th"></i></button>
						          	</div>
									<div className="quizAnswerInput">
										<div className="form-group w-100">
		                                    <input type="text" className="form-control" placeholder="Write your question here" />
		                                </div>
		                                <input className="form-check-input" type="checkbox" id="check1" name="option1"/>
		                                <button onClick={() => deletePanel(section.id, panel.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
									</div>
									</div>
							        ))}
									<div className="addAnswer" onClick={() => addPanel(section.id, `Panel ${section.panels.length}`)}>
										<i className="fa fa-plus-circle"></i><span>Add Answer</span>
									</div>
								</div>
								</div>
							</div>
						))}
						</div>
						<div className="addQuiz" onClick={addSection}>
							<i className="fa fa-plus-circle"></i><span> New Question</span>
						</div>
                </Modal.Body>
				<Modal.Footer>
					<div className="form-group text-end">
						<button onClick={hanldeQuizModalClose} className="m-1 btn btn-secondary">Close</button>
					</div>
				</Modal.Footer>
			</Modal>


		</>
	)
}

export default CourseContent