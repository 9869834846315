import React,{useState,useEffect,useReducer} from 'react';
import ReactDOM from "react-dom";
import { Modal } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import {
  upgradeCourse
}   from "../../redux/crm-features/course/courseSlice";

const classes = [
    { label: "Demo1", value: "Demo1" },
    { label: "Demo2", value: "Demo2" },
    { label: "Demo3", value: "Demo3" },
    { label: "Demo4", value: "Demo4" }
  ];

const CourseFormModal = (props) => {

    const [id, setId] = useState(props?._id);
    const [courseLevel, setCourseLevel] = useState(props?.courseLevel);
    const [courseAccess, setCourseAccess] = useState(props?.courseAccess);
    const [courseIpAddress, setCourseIpAddress] = useState(props?.courseIpAddress);
    const [courseType, setCourseType] = useState(props?.courseType);
    const [courseInstructor, setCourseInstructor] = useState(props?.courseInstructor);
    const [courseDuration, setCourseDuration] = useState(props?.courseDuration);
    const [courseFee, setCourseFee] = useState(props?.courseFee);
    const [courseDiscount, setCourseDiscount] = useState(props?.courseDiscount);
    const [courseTotalHours, setCourseTotalHours] = useState(props?.courseTotalHours);
    const [courseClasses, setCourseClasses] = useState(props?.courseClasses);
    const dispatch = useDispatch();
    const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	  const [courseFormModal, setCourseFormModalShow] = useState(false)
    const handleCourseFormModalShow = () => { setCourseFormModalShow(true) }
    const handleCourseFormModalClose = () => { setCourseFormModalShow(false) }

    const [value, setvalue] = useState("");
    const handleOnchange = (val) => setCourseClasses(val);
    console.log(courseClasses)

  	const handleSubmit = async (e) => {
	    e.preventDefault();
	    
      if (!courseFee) {
      toast.error("Please enter a course fee");
      return;
    }

    const courseData = {
      id: id,
      courseLevel: courseLevel,
      courseAccess: courseAccess,
      courseIpAddress: courseIpAddress,
      courseType: courseType,
      courseInstructor: courseInstructor,
      courseDuration: courseDuration,
      courseFee: courseFee,
      courseDiscount: courseDiscount,
      courseTotalHours: courseTotalHours,
      courseClasses: courseClasses
    };
    console.log(courseData)
    try {
      await dispatch(upgradeCourse(courseData));
      forceUpdate();
      setCourseFormModalShow(false);
    } catch (error) {
      // console.error("Error updating course:", error);
      toast.error("Error updating course. Please try again later.");
    }
	};

  // const [courseLevel, setCourseLevel] = useState('');
  const [showField, setShowField] = useState(false);

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    setCourseLevel(selectedOption);

    // If 'Online' is selected, set showField to true
    setShowField(selectedOption === 'center');
  };

  // const [ipAddress, setIpAddress] = useState('');

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setCourseIpAddress(data.ip))
      .catch(error => console.error('Error fetching IP address:', error));
  }, []);

	return (
		<>
		<span onClick={handleCourseFormModalShow}>Click me</span>
		
			{/*<!-- Modal -->*/}
        <Modal show={courseFormModal}
                    onHide={handleCourseFormModalClose}
                    backdrop="static"
                    keyboard={false}
                    className="textImageModal modal-lg w-100"
                    >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>Course Details</h4> 
                    </Modal.Title>
                    <span onClick={handleCourseFormModalClose}><i className="fa fa-times"></i></span>
                </Modal.Header>
                  <Modal.Body>
                        <div className="col-md-12">
                          <label>Course Level</label>
                          <select  name="courseLevel" className="form-select form-control-sm mb-3" value={courseLevel} onChange={(e) => setCourseLevel(e.target.value)}>
                            <option>Course Level</option>
                            <option value="beginner">Beginner</option>
                            <option value="intermediate">Intermediate</option>
                            <option value="advanced">Advanced</option>
                          </select>
                        </div>
                        <div className="col-md-12">
                        <label>Course Access</label>
                          <select name="courseAccess" className="form-select form-control-sm mb-3" value={courseAccess} onChange={(e) =>{ handleSelectChange(e); setCourseAccess(e.target.value);}}>
                            <option>Course Access</option>
                            <option value="online">Online</option>
                            <option value="center">Center</option>
                          </select>
                        </div>
                        {showField && (
                          <div className="col-md-12">
                            <div className="form-group">
                                <label>IP Address</label>
                                <input type="text" className="form-control mb-3" name="courseIpAddress" placeholder="IP Address" value={courseIpAddress} onChange={(e) => setCourseIpAddress(e.target.value)}/>
                            </div>
                          </div>
                        )}
                        <div className="col-md-12">
                          <label>Course Type</label>
                          <select name="courseType" className="form-select form-control-sm mb-3" value={courseType} onChange={(e) => setCourseType(e.target.value)}>
                            <option>Course Type</option>
                            <option value="training">Training</option>
                            <option value="beginner">Beginner</option>
                          </select>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                              {/*<Select
                                className="basic-single mb-3"
                                classNamePrefix="Instructor"
                                defaultValue={options[0]}
                                isLoading={loading}
                                isClearable={isClearable}
                                isSearchable={isSearchable}
                                name="color"
                                options={options}
                              />*/}
                          <label>Course Instructor</label>
                            <select name="courseInstructor" className="form-select form-control-sm mb-3" value={courseInstructor} onChange={(e) => setCourseInstructor(e.target.value)}>
                              <option>Instructor</option>
                              <option value="salman">Salman</option>
                              <option value="asad">Asad</option>
                              <option value="hasnain">Hasnain</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Course Duration</label>
                                <input type="text" name="courseDuration" className="form-control mb-3" placeholder="Course Duration (in Month)" value={courseDuration} onChange={(e) => setCourseDuration(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Course Fee</label>
                                <input type="text" className="form-control mb-3" placeholder="Course Fee" name="courseFee" value={courseFee} onChange={(e) => setCourseFee(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                              <label>Course Discount</label>
                              <input type="text" name="courseDiscount" className="form-control mb-3" placeholder="Max Discount" value={courseDiscount} onChange={(e) => setCourseDiscount(e.target.value)}/>
                          </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Course Learning Hours</label>
                                <input type="text" name="courseTotalHours" className="form-control mb-3" placeholder="Total Learning Hours" value={courseTotalHours} onChange={(e) => setCourseTotalHours(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Course Classes</label>
                                <MultiSelect
                                  className="multi-select w-100"
                                  onChange={(e) => {handleOnchange(e)}}
                                  options={classes}
                                  customValue={true}
                                  value={courseClasses}
                                />
                                <span>{courseClasses}</span>
                            </div>
                        </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="form-group text-end">
                      <button onClick={handleSubmit} type="submit" className="m-1 btn btn-secondary">Update</button>
                      <button onClick={handleCourseFormModalClose} className="m-1 btn btn-secondary">Close</button>
                    </div>
                  </Modal.Footer>
      </Modal>
		</>
	)
}

export default CourseFormModal