import React,{useEffect,useLayoutEffect} from 'react';
import { useDispatch,useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// KBM-CRM
import CourseFormModal from "./CRM/pages/CourseFormModal";
import Login from "./CRM/pages/auth/Login";
import Register from "./CRM/pages/auth/Register";
import Forgot from "./CRM/pages/auth/Forgot";
import Reset from "./CRM/pages/auth/Reset";
import LoginWithCode from "./CRM/pages/auth/LoginWithCode";
import Layout from "./CRM/components/Layout";
import Dashboard from "./CRM/pages/Dashboard";
import ProfileAccount from "./CRM/pages/ProfileAccount";
import ChangePassword from "./CRM/pages/ChangePassword";
import Verify from "./CRM/pages/auth/Verify";
import MasterData from "./CRM/pages/MasterData";
import MasterDataDetail from "./CRM/pages/MasterDataDetail";
import User from "./CRM/pages/User";
import EditUser from "./CRM/pages/EditUser";
import EditCompany from "./CRM/pages/EditCompany";
import UserList from "./CRM/pages/UserList";
import PersonalSetting from "./CRM/pages/PersonalSetting";
import CompanyDetail from "./CRM/pages/CompanyDetail";
import SecurityControl from "./CRM/pages/SecurityControl";
import EditProfile from "./CRM/pages/EditProfile";
import ProfileSetting from "./CRM/pages/ProfileSetting";
import Role from "./CRM/pages/Role";
import DataSharing from "./CRM/pages/DataSharing";
import EditDataSharing from "./CRM/pages/EditDataSharing";
import ComplianceSetting from "./CRM/pages/ComplianceSetting";
import Chat from "./CRM/pages/Chat";
import EmailConfig from "./CRM/pages/EmailConfig";
import Modules from "./CRM/pages/Modules";
import Calendar from "./CRM/pages/Calendar";
import Course from "./CRM/pages/Course";
import CourseDetail from "./CRM/pages/CourseDetail";
import CourseContent from "./CRM/pages/CourseContent";
import AllCourses from "./CRM/pages/AllCourses";
import CourseCurriculum from "./CRM/pages/CourseCurriculum";
import CourseFromScratch from "./CRM/pages/CourseFromScratch";
import CourseCurriculumImport from "./CRM/pages/CourseCurriculumImport";
import TabGroup from "./CRM/pages/TabGroup";
import WebTab from "./CRM/pages/WebTab";
import GlobalSet from "./CRM/pages/GlobalSet";
import EditModule from "./CRM/pages/EditModule";
import ViewModule from "./CRM/pages/ViewModule";
import ViewUser from "./CRM/pages/ViewUser";
import EditLayout from "./CRM/pages/EditLayout";
import ModuleSideBar from "./CRM/pages/ModuleSideBar";
import CustomizationPage from "./CRM/pages/CustomizationPage";
import PageSetting from "./CRM/pages/PageSetting";
import CompanyData from "./CRM/pages/CompanyData";
import CompanyDetailsById from "./CRM/pages/CompanyDetailsById";
import Reports from "./CRM/pages/Reports";
import ReportDetail from "./CRM/pages/ReportDetail";
import ReportEdit from "./CRM/pages/ReportEdit";
import ReportModule from "./CRM/pages/ReportModule";
import ModuleRecord from "./CRM/pages/ModuleRecord";
import EditModuleRecord from "./CRM/pages/EditModuleRecord";
import ModuleRecordDetail from "./CRM/pages/ModuleRecordDetail";
import UserGroup from "./CRM/pages/UserGroup";
import UserActive from "./CRM/pages/UserActive";
import UserInActive from "./CRM/pages/UserInActive";
import ModuleLayoutSetting from "./CRM/pages/ModuleLayoutSetting";
import ModuleFormData from "./CRM/pages/ModuleFormData";
import ModuleLayoutQuick from "./CRM/pages/ModuleLayoutQuick";
import ModuleLayoutView from "./CRM/pages/ModuleLayoutView";
import ProSide from "./CRM/pages/ProSide";
import ChatSystem from "./CRM/pages/ChatSystem";
import DragNDrop from "./CRM/components/DragNDrop";
// KBM-CRM-END

// KBM-ONE
import KbmOneLogin from "./KBMONE/pages/auth/KbmOneLogin";
import KbmOneRegister from "./KBMONE/pages/auth/KbmOneRegister";
import KbmOneForgot from "./KBMONE/pages/auth/KbmOneForgot";
import KbmOneReset from "./KBMONE/pages/auth/KbmOneReset";
import KbmOneVerify from "./KBMONE/pages/auth/KbmOneVerify";
import KbmOneDashboard from "./KBMONE/pages/KbmOneDashboard";
import KbmOneLayout from "./KBMONE/components/KbmOneLayout";
import AssignedApps from "./KBMONE/pages/AssignedApps";
import KbmOneChangePassword from "./KBMONE/pages/KbmOneChangePassword";
import KbmOneLoginWithCode from "./KBMONE/pages/auth/KbmOneLoginWithCode";
import KbmOneProfileAccount from "./KBMONE/pages/KbmOneProfileAccount";
import KbmOneUser from "./KBMONE/pages/KbmOneUser";
import KbmOneEditUser from "./KBMONE/pages/KbmOneEditUser";
// KBM-ONE-END

import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getLoginStatus,getUser,selectIsLoggedIn,selectUser,
} from "./redux/crm-features/auth/authSlice";
import CourseView from "./CRM/pages/CourseView";
import CoursesMainPage from "./CRM/pages/CoursesMainPage";
// import {
//   getLoginStatus,getUser,selectIsLoggedIn,selectUser,
// } from "./redux/kbmone-features/auth/authSlice";
// import { GoogleOAuthProvider } from "@react-oauth/google";

axios.defaults.withCredentials = true;

function App() {

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(getLoginStatus());
    if (isLoggedIn && user === null) {
      dispatch(getUser());
    }

  }, [dispatch, isLoggedIn, user]);

  useLayoutEffect(() => {
    document.body.classList.remove(
      'dark',
    )
  })

  return (
    <>
      <BrowserRouter>
      <ToastContainer />
      
        {/*<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>*/}
        <Routes>

        {/****KBM-ONE****/}

          <Route path="/courseFormModal" element={
              <CourseFormModal />
          }/>

           <Route path="/login" element={
              <KbmOneLogin />
          }/>
          
          <Route path="/register" element={
              <KbmOneRegister />
          }/>

          <Route path="/forgot" element={
              <KbmOneForgot />
          }/>

          <Route path="/" element={
            <KbmOneLayout>
              <KbmOneDashboard />
            </KbmOneLayout>
          }/>

          <Route path="/more-apps" element={
            <KbmOneLayout>
              <AssignedApps />
            </KbmOneLayout>
          }/>

          <Route path="/change-password" element={
            <KbmOneLayout>
              <KbmOneChangePassword />
            </KbmOneLayout>
          }/>

          <Route path="/resetPassword/:resetToken" element={
              <KbmOneReset />
          }/>

          <Route path="/loginWithCode/:email" element={
              <KbmOneLoginWithCode />
          }/>

          <Route path="/profile-account" element={
            <KbmOneLayout>
              <KbmOneProfileAccount />
            </KbmOneLayout>
          }/>

          <Route path="/verify/:verificationToken" element={
            <KbmOneLayout>
              <KbmOneVerify />
            </KbmOneLayout>
          }/>

          <Route path="/user" element={
            <KbmOneLayout>
              <KbmOneUser />
            </KbmOneLayout>
          }/>

          <Route path="/edit-user/:id" element={
            <KbmOneLayout>
              <KbmOneEditUser />
            </KbmOneLayout>
          }/>
      
        {/****KBM-ONE-END****/}

      {/****KBM-CRM****/}

          <Route exact path="/crm" element={
            <Layout>
              <Dashboard />
            </Layout>
          }/>

          <Route path="/crm/login" element={
              <Login />
          }/>
          
          <Route path="/dnd" element={
              <DragNDrop />
          }/>

          <Route path="/crm/chat-system" element={  
            <Layout>
              <ChatSystem />
            </Layout>
          }/>

          <Route path="/pro" element={
              <ProSide />
          }/>

          <Route path="/crm/register" element={
              <Register />
          }/>

          <Route path="/crm/forgot" element={
              <Forgot />
          }/>

          <Route path="/crm/resetPassword/:resetToken" element={
              <Reset />
          }/>

          <Route path="/crm/loginWithCode/:email" element={
              <LoginWithCode />
          }/>

          <Route path="/crm/profile-account" element={
            <Layout>
              <ProfileAccount />
            </Layout>
          }/>

          <Route path="/crm/change-password" element={
            <Layout>
              <ChangePassword />
            </Layout>
          }/>

          <Route path="/crm/verify/:verificationToken" element={
            <Layout>
              <Verify />
            </Layout>
          }/>

          <Route path="/crm/master-data" element={
            <Layout>
              <MasterData />
            </Layout>
          }/>

          <Route path="/crm/master-data-detail" element={
            <Layout>
              <MasterDataDetail />
            </Layout>
          }/>

          <Route path="/crm/user" element={
            <Layout>
              <User />
            </Layout>
          }/>

          <Route path="/crm/user-active" element={
            <Layout>
              <UserActive />
            </Layout>
          }/>

          <Route path="/crm/user-inactive" element={
            <Layout>
              <UserInActive />
            </Layout>
          }/>

          <Route path="/crm/users-group" element={
            <Layout>
              <UserGroup />
            </Layout>
          }/>

          <Route path="/crm/edit-user/:id" element={
            <Layout>
              <EditUser />
            </Layout>
          }/>

          <Route path="/crm/edit-company/:id" element={
            <Layout>
              <EditCompany />
            </Layout>
          }/>

          <Route path="/crm/users-list" element={
            <Layout>
              <UserList />
            </Layout>
          }/>

          <Route path="/crm/personal-setting" element={
            <Layout>
              <PersonalSetting />
            </Layout>
          }/>

          <Route path="/crm/company-detail" element={
            <Layout>
              <CompanyDetail />
            </Layout>
          }/>

          <Route path="/crm/security-control" element={
            <Layout>
              <SecurityControl />
            </Layout>
          }/>

          <Route path="/crm/edit-profile/:id" element={
            <Layout>
              <EditProfile />
            </Layout>
          }/>

          <Route path="/crm/profile-setting" element={
            <Layout>
              <ProfileSetting />
            </Layout>
          }/>

          <Route path="/crm/user-role" element={
            <Layout>
              <Role />
            </Layout>
          }/>

          <Route path="/crm/data-sharing-setting" element={
            <Layout>
              <DataSharing />
            </Layout>
          }/>

          <Route path="/crm/edit-data-sharing-setting" element={
            <Layout>
              <EditDataSharing />
            </Layout>
          }/>

          <Route path="/crm/compliance-setting" element={
            <Layout>
              <ComplianceSetting />
            </Layout>
          }/>

          <Route path="/crm/visit" element={
            <Layout>
              <Chat />
            </Layout>
          }/>

          <Route path="/crm/email-settings" element={
            <Layout>
              <EmailConfig />
            </Layout>
          }/>

          <Route path="/crm/modules" element={
            <Layout>
              <Modules />
            </Layout>
          }/>

          <Route path="/crm/calendar" element={
            <Layout>
              <Calendar />
            </Layout>
          }/>

          <Route path="/crm/tab-group" element={
            <Layout>
              <TabGroup />
            </Layout>
          }/>

          <Route path="/crm/web-tab" element={
            <Layout>
              <WebTab />
            </Layout>
          }/>

          <Route path="/crm/global-set" element={
            <Layout>
              <GlobalSet />
            </Layout>
          }/>

          <Route path="/crm/edit-module/:id" element={
            <Layout>
              <EditModule />
            </Layout>
          }/>

          <Route path="/crm/module/:id" element={
            <Layout>
              <ViewModule />
            </Layout>
          }/>
          <Route path="/crm/user/:id" element={
            <Layout>
              <ViewUser />
            </Layout>
          }/>

          <Route path="/crm/edit-module-layout/:id" element={
            <Layout>
              <EditLayout />
            </Layout>
          }/>

          <Route path="/crm/module-menu" element={
            <Layout>
              <ModuleSideBar />
            </Layout>
          }/>

          <Route path="/crm/home-customization" element={
            <Layout>
              <CustomizationPage />
            </Layout>
          }/>

          <Route path="/crm/customization-page" element={
            <Layout>
              <PageSetting />
            </Layout>
          }/>

          <Route path="/crm/company-data/:id" element={
            <Layout>
              <CompanyData />
            </Layout>
          }/>

          <Route path="/crm/company-detail-by/:id" element={
            <Layout>
              <CompanyDetailsById />
            </Layout>
          }/>

          <Route path="/crm/reports" element={
            <Layout>
              <Reports />
            </Layout>
          }/>
          {/*routes for courses*/}
          <Route path="/crm/courses-main-page" element={
            <Layout>
              <CoursesMainPage />
            </Layout>
          }/>

          <Route path="/crm/courseview/:id" element={
            <Layout>
              <CourseView />
            </Layout>
          }/>

          {/*routes for courses*/}

          <Route path="/crm/course" element={
            <Layout>
              <Course />
            </Layout>
          }/>

          <Route path="/crm/course-detail/:id" element={
            <Layout>
              <CourseDetail />
            </Layout>
          }/>

          <Route path="/crm/lesson-content" element={
            <Layout>
              <CourseContent />
            </Layout>
          }/>

          <Route path="/crm/all-courses" element={
            <Layout>
              <AllCourses />
            </Layout>
          }/>

          <Route path="/crm/course/:courseTitle" element={
            <Layout>
              <CourseCurriculum />
            </Layout>
          }/>

          <Route path="/crm/course-from-scratch/:courseTitle" element={
            <Layout>
              <CourseFromScratch />
            </Layout>
          }/>

          <Route path="/crm/course/curriculum-import" element={
            <Layout>
              <CourseCurriculumImport />
            </Layout>
          }/>

          <Route path="/crm/report/id" element={
            <Layout>
              <ReportDetail />
            </Layout>
          }/>
          
          <Route path="/crm/report/edit" element={
            <Layout>
              <ReportEdit />
            </Layout>
          }/>

          <Route path="/crm/report/module" element={
            <Layout>
              <ReportModule />
            </Layout>
          }/>

          <Route path="/crm/module-record/:id" element={
            <Layout>
              <ModuleRecord />
            </Layout>
          }/>

          <Route path="/crm/edit-record/:id" element={
            <Layout>
              <EditModuleRecord />
            </Layout>
          }/>

          <Route path="/crm/module-record-detail" element={
            <Layout>
              <ModuleRecordDetail />
            </Layout>
          }/>

          <Route path="/crm/module-layout-setting" element={
            <Layout>
              <ModuleLayoutSetting />
            </Layout>
          }/>

          <Route path="/crm/module-form-data" element={
            <Layout>
              <ModuleFormData />
            </Layout>
          }/>

          <Route path="/crm/module-layout-quick" element={
            <Layout>
              <ModuleLayoutQuick />
            </Layout>
          }/>

          <Route path="/crm/module-layout-view" element={
            <Layout>
              <ModuleLayoutView />
            </Layout>
          }/>

        {/****KBM-CRM-END****/}

        </Routes>
        {/*</GoogleOAuthProvider>*/}
        
      </BrowserRouter>
    </>
  );
}

export default App;
