import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/calendar/crm/`;


// add course
const addShift = async(calenderShiftData) =>{
    const response = await axios.post(API_URL + "addShift", calenderShiftData);
    return response.data;
};

// Get all courses
/*const getCourses = async () => {
    const response = await axios.get(API_URL + "getShift");
    return response.data;
};*/

// get getShift
const getShift = async(id) =>{
    const response = await axios.get(API_URL + id);
    return response.data;
};

// upgrade course
/*const upgradeCourse = async(calenderData) =>{
    const response = await axios.put(API_URL + "upgradeCourse", calenderData);
    return response.data.message;
};*/

// update course
const updateShift = async(id, calenderShiftData) =>{
    const response = await axios.patch(`${API_URL}${id}`, calenderShiftData);
    return response.data;
};

// delete course
const deleteShift = async(id) =>{
    const response = await axios.delete(API_URL + id);
    return response.data.message;
};


const shiftService = {
    addShift,
    getShift,
    updateShift,
    deleteShift,
}

export default shiftService;