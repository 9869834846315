import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Col, Offcanvas, Row, Table} from 'react-bootstrap';
import { Form, Button } from 'react-bootstrap';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import './../../formBuilder/css/calendar.css';
import {toast} from "react-toastify";
import {addClass} from "../../redux/crm-features/calendar/calendarSlice";
import {addShift} from "../../redux/crm-features/calendar/shifts/shiftSlice";
import {VscPreview} from "react-icons/vsc";
import {RiSendPlaneLine} from "react-icons/ri";
import {CgTranscript} from "react-icons/cg";
import Accordion from 'react-bootstrap/Accordion';
import {Link} from "react-router-dom";

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const dayNameArr = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

const header = dayNameArr.map((day, i) => (
    <th key={i} style={{ height:'', fontSize:'10px', fontWeight:'bold', textAlign:'center', borderBottom:'0px', padding:'5px 0px 0px', margin:'' }}>{day}</th>
))

class LocalCalendar extends Component {

  /*constructor(props) {
    super(props);
    this.state = {
      id: props && props.id ? props.id : '',
      eventType: props && props.eventType ? props.eventType : '',
      classLocation: props && props.classLocation ? props.classLocation : '',
      classRoom: props && props.classRoom ? props.classRoom : '',
      className: props && props.className ? props.className : '',
      classInstructor: props && props.classInstructor ? props.classInstructor : '',
      classLimit: props && props.classLimit ? props.classLimit : 0,
      classLink: props && props.classLink ? props.classLink : '',
      classDate: props && props.classDate ? props.classDate : '',
      classStartTime: props && props.classStartTime ? props.classStartTime : '',
      classEndTime: props && props.classEndTime ? props.classEndTime : '',
      classStatus: props && props.classStatus ? props.classStatus : '',
      eventRecurrence: props && props.eventRecurrence ? props.eventRecurrence : '',

    };
  }*/
  state = {
    w: document.body.clientWidth,
    h: document.body.clientHeight,
    currentData: '',
    targetDate: '',
    year: 0,
    month: 0,
    currentYear: 0,
    currentMonth: 0,
    currentDay: 0,
    body: [],
    dateArray: [],
    eventArr: [],
    newSubject:'',
    newPriority:'',
    isChecked: false,
    radioValue: '',
    eventRecurrence: 'no_repeat',
    eventType: '',
    type: '',
    priority: 0,
    showCanvas: false,
    showCanvasShifts: false,
    ShowCanvasLocationNClass: false,
    startTime: null,
    endTime: null,
    limitType: '',
    shiftLocation: '',
    shiftStartTime: '',
    shiftEndTime: '',
    shiftLimit: '',
    shiftStatus: '',
    shiftNote:'',
  }
  handleLimitTypeChange = (event) => {
    this.setState({ limitType: event.target.value }); // Update state with selected value
  };
  handleToolTipClick = () => {
    console.log('Div clicked');
  };
  /*START - 24 hours clock time with 15 in interval*/
  /*getTimeOptions = (startTime = 0, endTime = 23) => {
    const options = [];
    for (let i = startTime; i <= endTime; i += 1) {
      for (let j = 0; j < 60; j += 15) {
        const formattedTime = `${i.toString().padStart(2, '0')}:${j.toString().padStart(2, '0')}`;
        const amPm = i < 12 ? 'AM' : 'PM';
        const hour = i === 0 || i === 12 ? 12 : i % 12;
        const displayTime = `${hour.toString().padStart(2, '0')}:${j.toString().padStart(2, '0')} ${amPm}`;
        options.push({ value: formattedTime, label: displayTime });
      }
    }
    return options;
  };

  getNextTimeSlot = (selectedTime) => {
    const [hours, minutes] = selectedTime.split(':');
    const nextMinutes = minutes === '45' ? '00' : (parseInt(minutes, 10) + 15).toString().padStart(2, '0');
    return `${hours}:${nextMinutes}`;
  };

  filterEndTimeOptions = (options, startTime) =>
      options.filter((option) => {
        const [optionHour, optionMinute] = option.value.split(':');
        // Handle potential null startTime case
        if (!startTime) return false;  // No valid start time, no options

        const [startHour, startMinute] = startTime.split(':');
        return (optionHour > startHour ||  // Option hour is after start hour
            (optionHour === startHour && optionMinute >= startMinute));  // Same hour, option minute is after or equal to start minute
      });

  handleStartTimeChange = (event) => {
    const selectedTime = event.target.value;
    const nextTime = this.getNextTimeSlot(selectedTime);
    this.setState({ startTime: selectedTime, endTime: nextTime });
  };

  handleEndTimeChange = (event) => {
    this.setState({ endTime: event.target.value });
  };*/

  /*END - 24 hours clock time with 15 in interval*/


  handleCloseCanvas = () => {
    this.setState(
        {
          showCanvas: false,
          newSubject: '',
          newPriority: '',
          type: '',
          eventType: '',
          priority: 0,
          classLocation: '',
          classRoom: '',
          className: '',
          classInstructor: '',
          classLimit: '',
          classLink: '',
          classDate: '',
          classStartTime: '',
          classEndTime: '',
          classStatus: '',
          eventRecurrence: '',
          Monday: '',
          Tuesday: '',
          Wednesday: '',
          Thursday: '',
          Friday: '',
          Saturday: '',
          Sunday: '',
        });
  };
  handleTextFieldChange = (day, value) => {
    this.setState({ [day]: value });
  };
  handleShowCanvas = () => {
    this.setState({ showCanvas: true });
  };
  handleCloseCanvasShifts = () => {
    this.setState(
        {
          showCanvasShifts: false,
        });
  };
  handleShowCanvasShifts = () => {
    this.setState({ showCanvasShifts: true });
  };
  handleCloseCanvasLocationNClass = () => {
    this.setState(
        {
          ShowCanvasLocationNClass: false,
        });
  };
  handleShowCanvasLocationNClass = () => {
    this.setState({ ShowCanvasLocationNClass: true });
  };
  /*handleToggle = () => {
    this.setState(prevState => ({
      isChecked: !prevState.isChecked
    }));
  };*/

  handleRadioChange = (event) => {
    this.setState({
      radioValue: event.target.value
    });
  };
  handleOptionChange = (event) => {
    this.setState({
      eventRecurrence: event.target.value,
    });
  };

  dateSplit = (date) => {
    return date.split("T")[0];
  }
  handleShiftSubmit = async (e) => {
    e.preventDefault();
    const { shiftLocation, shiftLimit, shiftStartTime, shiftEndTime,
      shiftStatus, shiftNote } = this.state;

    if (!shiftLocation) {
      toast.error("Please select Location");
      return;
    }
    if (shiftEndTime < shiftStartTime) {
      toast.error("Selected Time is not Correct");
      return;
    }

    const calendarShift = {
      type: 'calendarShifts',
      locationShifts:[
        {
          shiftLocation,
          shiftStartTime,
          shiftEndTime,
          shiftLimit: Number(shiftLimit),
          shiftStatus: Boolean(shiftStatus),
          shiftNote,
        }
      ]
    };

    //console.log(calendarClass)
    try {
      await this.props.dispatch(addShift(calendarShift)).then( res => {

        this.setState({
          type: '',
          shiftLocation: '',
          shiftStartTime: '',
          shiftEndTime: '',
          shiftLimit: '',
          shiftStatus: '',
          shiftNote: '',
        })
      });
      //this.forceUpdate();
      toast.success("Shift Scheduled Successfully");
    } catch (error) {
      toast.error("Error creating Shift. Please try again later.");
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { classLocation, classRoom, className, classInstructor,
      classLimit, classLink, classDate, classStartTime, classEndTime,
      classStatus, eventRecurrence } = this.state;

    if (!className) {
      toast.error("Please enter a class Name");
      return;
    }
    if (classEndTime < classStartTime) {
      toast.error("Selected Time is not Correct");
      return;
    }

    const calendarClass = {
      type: 'C',
      eventType: 'Special_Class',
      priority: 1,
      classLocation,
      classRoom,
      className,
      classInstructor,
      classLimit,
      classLink,
      classDate,
      classStartTime,
      classEndTime,
      classStatus,
      eventRecurrence
    };

    this.setState({
      targetDate: this.dateSplit(classDate)
    })
    //console.log(calendarClass)
    try {
      await this.props.dispatch(addClass(calendarClass)).then(async res => {
        //console.log(444, res.payload)
        const datePart = this.dateSplit(res.payload.classDate);
        //console.log(datePart);
        const { eventArr, newSubject, newPriority, targetDate, dateArray, weeks, dateBMonth, dateCMonth, today } = this.state
        //const { type, priority, className, classStartTime } = res.payload
        const checkDate = eventArr.some(item => item.date === targetDate)
        //const eventSummary = { classStartTime ,type, subject: className, priority: priority, }
        if(checkDate) {
          for(let i=0; i<eventArr.length; i++) {
            if(targetDate===eventArr[i].date) {
              eventArr[i].event.push(res.payload)
            }
          }
        } else {
          eventArr.push({date: targetDate, event: [res.payload]})
        }

        await this.setEventArr(eventArr, dateArray)
        this.setBody(weeks, dateBMonth, dateCMonth, today)

        //this.closeModal('modalEvent')
        this.setState({
          newSubject: '',
          newPriority: '',
          type: '',
          eventType: '',
          priority: 0,
          classLocation: '',
          classRoom: '',
          className: '',
          classInstructor: '',
          classLimit: '',
          classLink: '',
          classDate: '',
          classStartTime: '',
          classEndTime: '',
          classStatus: '',
          eventRecurrence: '',
          showCanvas: false,
        })
      });
      //this.forceUpdate();
      toast.success("Class Scheduled Successfully");
    } catch (error) {
      toast.error("Error creating class. Please try again later.");
    }
  };
  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }
  componentDidMount = async () => {
    window.addEventListener("resize", this.onResize)
    document.addEventListener('click', this.onMoreAction);
    this.getToday()
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onMoreAction);
  }

  getToday = async() => {
    const today = new Date();
    var currentYear = today.getFullYear();
    var currentMonth = today.getMonth() + 1;
    var currentDay = today.getDate();

    await this.setState({
      today: this.makeDate(currentYear, this.addZiro(currentMonth), this.addZiro(currentDay)),
      currentDay,
      currentMonth,
      currentYear,
      year: currentYear,
      month: currentMonth,
    })

    this.getCurrent(currentYear, currentMonth, currentDay)
  }

  setday = (date) => {
    var n = date.getDay()
    return n===0 ? 6 : n - 1
  }

  addZiro = (n) => {
    return n<10 ? '0' + n : n
  }

  makeDate = (y, m, d) => {
    return y + '-' + m + '-' + d
  }

  getDaysN = (year, monthIndex) => {
    return new Date(year, monthIndex + 1, 0).getDate();
  }

  getNext = async () => {
    const { year, month } = this.state

    const newMonth = month === 12 ? 1 : month + 1
    const newYear = month === 12 ? year + 1 : year

    await this.setState({
      year: newYear,
      month: newMonth
    });

    this.getCurrent(newYear, newMonth)
  }

  getPrevious = () => {
    var { year, month } = this.state

    const newYear = month === 1 ? year - 1 : year
    const newMonth = month === 1 ? 12 : month - 1

    this.setState({
      year: newYear,
      month: newMonth
    })

    this.getCurrent(newYear, newMonth);
  }

  getCurrent = async (y, m) => {
    var dateA, dateAIndex, dateAMonthIndex, dateAMonth, dateAYear, dateADaysN
    var dateB, dateBIndex, dateBMonthIndex, dateBMonth, dateBYear, dateBDaysN
    var dateC, dateCIndex, dateCMonthIndex, dateCMonth, dateCYear, dateCDaysN
    const { today, eventArr } = this.state
    let dateArray = [], arrA = [], arrB = [], arrC = [], weeks = [], week = []
    dateB = new Date(y, m-1, 1);
    dateBIndex = dateB.getDate();
    dateBMonthIndex = dateB.getMonth();
    dateBMonth = months[dateBMonthIndex];
    dateBYear = dateB.getFullYear();
    this.setState({
      currentData: dateBMonth + ' ' + dateBYear,
      dateBMonth,
    })

    // تعداد روزهای ماه جاری
    dateBDaysN = this.getDaysN(dateBYear, dateBMonthIndex)

    // make arrB
    var mB = this.addZiro(dateBMonthIndex + 1)
    for (let i = 1; i <= dateBDaysN; i++) {
      var dB = this.addZiro(i)
      arrB.push({ type:'B', date: this.makeDate(dateBYear, mB, dB), event:[] });
    }

    // ابندکس اولین روز ماه جاری
    const dateBFirstDayIndex = this.setday(dateB)

    console.log(dateB)

    // نام اولین روز ماه جاری
    const dateBDayName = dayNameArr[dateBFirstDayIndex]

    // ابندکس آخرین روز ماه جاری

    const dateBL = new Date(y, m-1, dateBDaysN);

    const dateBLastDayIndex = this.setday(dateBL)

    // نام آخرین روز ماه جاری
    const dateBLastDayName = dayNameArr[dateBLastDayIndex]

    if(dateBFirstDayIndex>0) {
      // تعداد روزهای ماه قبل
      const preYear = m === 1 ? y - 1 : y
      const preMonth = m === 1 ? 12 : m - 1

      dateA = new Date(preYear, preMonth - 1, 1);
      // console.log(dateA)

      dateAYear = dateA.getFullYear();
      dateAMonthIndex = dateA.getMonth();
      dateAMonth = months[dateAMonthIndex];
      dateADaysN = this.getDaysN(dateAYear, dateAMonthIndex)

      // make arrA
      var mA = this.addZiro(dateAMonthIndex + 1)
      for (let i = dateADaysN - dateBFirstDayIndex + 1; i <= dateADaysN; i++) {
        var dA = this.addZiro(i)
        arrA.push({ type:'A', date: this.makeDate(dateAYear, mA, dA), event:[] });
      }

      // تعداد روزهای ماه بعد
      const nextYear = m === 12 ? y + 1 : y
      const nextMonth = m === 12 ? 1 : m + 1

      dateC = new Date(nextYear, nextMonth - 1, 1);
      dateCYear = dateC.getFullYear();
      dateCMonthIndex = dateC.getMonth();
      dateCMonth = months[dateCMonthIndex];
      dateCDaysN = this.getDaysN(dateCYear, dateCMonthIndex)

      this.setState({ dateCMonth })

    }

    // make arrC
    var mC = this.addZiro(dateCMonthIndex + 1)

    if(dateBLastDayIndex!==6) {
      for (let i = 1; i <= 6 - dateBLastDayIndex; i++) {
        var dC = this.addZiro(i)
        arrC.push({ type:'C', date: this.makeDate(dateCYear, mC, dC), event:[] });
      }
    }
    dateArray = [...arrA, ...arrB, ...arrC]
    await this.setState({ dateArray })

    dateArray.forEach((day, index) => {
      week.push(day);
      if ((index + 1) % 7 === 0 || index === dateArray.length - 1) {
        weeks.push(week);
        week = [];
      }
    });
    this.setState({ weeks })

    await this.setEventArr(eventArr, dateArray)
    this.setBody(weeks, dateBMonth, dateCMonth, today)
  }

  onMore = async (id, date) => {
    const more = document.getElementById(id);
    more.classList.remove('obj-child');

    const rect = more.getBoundingClientRect();

    const moreList = document.getElementById('moreList');
    moreList.style.display = "block";
    moreList.style.borderRadius = "5px";
    moreList.style.position = 'fixed';
    moreList.style.top = `${rect.bottom+5}px`;
    moreList.style.left = `${rect.left-150}px`;

    await this.setState((prevState) => {
      return { preSelectedId: prevState.selectedId };
    });
    this.setState({
      selectedId: id,
      targetDate: date
    })
  }

  onMoreAction = async (e) => {
    const { preSelectedId, selectedId, targetDate } = this.state
    const name = document.getElementById(e.target.id)?.getAttribute('name')
    await this.onCloseMoreList(e)
    if(name === 'more') {
      if(preSelectedId!==selectedId) {
        this.onMore(e.target.id, targetDate)
      } else {
        this.setState({
          selectedId: undefined,
        })
      }
    }
  }

  onCloseMoreList = async (e) => {
    const moreList = document.getElementById('moreList');
    if(moreList) moreList.style.display = "none";
    const more = document.getElementById(this.state.preSelectedId);
    if(more) more.classList.add('obj-child');
  }

  onModal = async (id) => {
    const modal = document.getElementById(id);
    modal.classList.remove('fadeOutUp');
    modal.style.display = "block";
  }

  closeModal = async (id) => {
    const modal = document.getElementById(id);
    if(modal) {
      modal.classList.add('fadeOutUp');
      setTimeout(() => {
        modal.style.display = "none";
      }, 1000);
    }
  }

  onChangeSubject = async (e) => {
    this.setState({ newSubject: e.target.value })
  }

  onChangePriority = async (e) => {
    this.setState({ newPriority: e.target.value })

  }

  onSaveDate = async (e) => {
    const { eventArr, newSubject, newPriority, targetDate, dateArray, weeks, dateBMonth, dateCMonth, today } = this.state
    console.log(targetDate)
    var checkDate = eventArr.some(item => item.date === targetDate)
    if(checkDate) {
      for(let i=0; i<eventArr.length; i++) {
        if(targetDate===eventArr[i].date) {
          eventArr[i].event.push({ type:'A', subject: newSubject, priority: newPriority })
        }
      }
    } else {
      eventArr.push({date: targetDate, event: [{ type:'A', subject: newSubject, priority: newPriority }]})
    }

    await this.setEventArr(eventArr, dateArray)
    this.setBody(weeks, dateBMonth, dateCMonth, today)

    this.closeModal('modalEvent')
    this.setState({
      newSubject: '',
      newPriority: '',
    })
  }

  setEventArr = async () => {
    const { eventArr, dateArray } = this.state
    eventArr.forEach(item => {
      const dateObject = dateArray.find(day => day.date === item.date);
      if (dateObject) {
        dateObject.event=item.event;
      }
    });
  }

  setBody = (weeks, dateBMonth, dateCMonth, today) => {
    function addDayName(type, n) {
      if (n === 1) {
        return type === 'B' ? dateBMonth.slice(0, 3) : dateCMonth?.slice(0, 3);
      }
    }

    function setColor(priority) {
      var classX
      switch(priority) {
        case '1' : classX = 'green'; break;
        case '2' : classX = 'yellow'; break;
        case '3' : classX = 'red'; break;
        default: classX = 'grey'
      }
      return classX
    }
    const todayStyle = {width:'25px', height:'25px', margin:'-3px 0px -4px', borderRadius:'100px', alignItems:'center', backgroundColor:'#1373f2', color:'#ffffff'}
    var todayCheck, dayN

    const body = weeks.map(
        (item, i) => (
            // console.log(item),
            <tr key={i}>
              {
                item.map(
                    (itemx, ix) => (
                        // console.log(itemx),
                        todayCheck = itemx.type==='B' && itemx.date===today ? true : false,
                            dayN = Number(itemx.date.split("-")[2]),
                            <td key={ix} id='calendarTD' className='obj-parent'
                                style={{ width:'100px', height:'160px', fontWeight:'', textAlign:'center', verticalAlign:'top', borderTop: i===0 ? '0px solid' : '', padding:'5px' }}
                            >
                              <div className='d-flex' style={{ position:'relative', flexDirection:'column' }}>
                                <div className='d-flex' style={{width:'100%', height:'100%', alignItems:'flex-start', justifyContent:'center', marginBottom:'5px'}}>
                                  <span>{addDayName(itemx.type, dayN)}</span>
                                  <div className='center' style={todayCheck ? {...todayStyle} : {width:'15px'}}>{dayN}</div>
                                </div>
                                <div style={{width:'100%', overflow:'hidden', cursor: 'pointer'}} onClick={this.handleToolTipClick}>
                                  {
                                    itemx.event.map(
                                        (event, px) => (
                                            <div key={px} title={event.subject}
                                                 style={{textAlign:'left',fontSize:'small',paddingLeft:'5px',paddingTop:'1px',fontWeight:'600'}}
                                                 className={`event ${setColor(event.priority)}`}>
                                              {event.classStartTime} - {event.className}
                                            </div>
                                        )
                                    )
                                  }
                                </div>
                                <div id={`more-${i}-${ix}`} name='more' className='center obj-child more' onClick={() => this.onMore(`more-${i}-${ix}`, itemx.date)}
                                     style={{position:'absolute', right:0, top:i===0 ? -20 : 0, width:'25px', height:'25px', alignItems:'center', padding:'0px'}}>
                                  <div id={`more-${i}-${ix}`} name='more' style={{fontSize:'20px', marginTop:'-10px'}}>...</div>
                                </div>
                              </div>
                            </td>
                    )
                )
              }
            </tr>
        )
    )
    this.setState({ body })
  }

  render() {
    const { eventType,showCanvas , showCanvasShifts, ShowCanvasLocationNClass, classLocation, classRoom, className, classInstructor,
      classLimit, classLink, classDate, classStartTime, classEndTime,
      classStatus, eventRecurrence, radioValue, isChecked, shiftLocation, shiftLimit, shiftStartTime, shiftEndTime,
      shiftStatus, shiftNote } = this.state;
    const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    const { Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday } = this.state;
    {/*START - 24 hours clock time with 15 in interval*/}
    /*const optionsStart = this.getTimeOptions();
    const filteredEndOptions = this.filterEndTimeOptions(optionsStart, this.state.startTime);*/
    {/*END - 24 hours clock time with 15 in interval*/}

    const { currentData, targetDate, body, newSubject, newPriority } = this.state
    const indicatorStyle = {borderRadius:'100px', padding:'5px 7px', color:'', backgroundColor:'#ffffff'}
    const moreList = (
        <div id='moreList'
             className='popupContainer animated fadeInTopRight more-list'>
          <div onClick={this.handleShowCanvas}>Schedule Class</div>
          <div onClick={() => this.onModal('modalEvent')}>Add Event</div>
          {/*<div onClick={this.handleShowCanvasShifts}>Manage Shifts</div>*/}
          <div onClick={() => this.onModal('modalTimeOff')}>Add Time Off</div>
          <div onClick={() => this.onModal('modalSpecialWorkingHours')}>Add Special Working Hours</div>
          <div className="font-red" onClick={() => this.onModal('modalBlockDay')}>Mark day as Closed</div>
        </div>
    )

    const offCanvasCreateClass = (
        <Offcanvas placement={"end"} show={showCanvas} className="w-50" onHide={this.handleCloseCanvas} backdrop="static">
          <Offcanvas.Header className="bg-light" closeButton>
            <Offcanvas.Title>Schedule Class</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-3 pt-5">
            <Form>

              {/*START - 24 hours clock time with 15 in interval*/}
              {/*<div className="container mt-3">   Add container class for centering and margin
                <div className="row">
                  <div className="col-md-6">   Use col-md-6 for half column on medium screens
                    <label htmlFor="startTime" className="form-label">Start Time:</label>
                    <select
                        id="startTime"
                        value={this.state.startTime}
                        onChange={this.handleStartTimeChange}
                        className="form-control"
                    >
                      {optionsStart.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">   Use col-md-6 for half column on medium screens
                    <label htmlFor="endTime" className="form-label">End Time:</label>
                    <select
                        id="endTime"
                        value={this.state.endTime}
                        disabled={!this.state.startTime}
                        onChange={this.handleEndTimeChange}
                        className="form-control"
                    >
                      {filteredEndOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>*/}
              {/*END - 24 hours clock time with 15 in interval*/}

              <div className="row container">
                <div className="col-md-6 px-4">
                  <Form.Group className="mb-3" controlId="classLocation">
                    <Form.Label>Location</Form.Label>
                    <Form.Select aria-label="classLocation" name="classLocation" value={classLocation} onChange={this.handleOnChange}>
                      <option value="">Please Select</option>
                      <option value="Park Royal">Park Royal</option>
                      <option value="Barking">Barking</option>
                      <option value="Croydon">Croydon</option>
                      <option value="Birmingham">Birmingham</option>>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="classRoom">
                    <Form.Label>Room</Form.Label>
                    <Form.Select aria-label="classRoom" name="classRoom" value={classRoom} onChange={this.handleOnChange}>
                      <option value="">Please Select</option>
                      <option value="Online">Online</option>
                      <option value="Board Room">Board Room</option>
                      <option value="Room No 2">Room No 2</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="className" >
                    <Form.Label>Class</Form.Label>
                    <Form.Select aria-label="className" name="className" value={className} onChange={this.handleOnChange}>
                      <option value="">Please Select</option>
                      <option value="Payroll">Payroll</option>
                      <option value="CV Review">CV Review</option>
                      <option value="Advanced Bookkeeping">Advanced Bookkeeping</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="classInstructor" >
                    <Form.Label>Instructor</Form.Label>
                    <Form.Select aria-label="classInstructor" name="classInstructor" value={classInstructor} onChange={this.handleOnChange}>
                      <option value="">Please Select</option>
                      <option value="5c4eaab7a6ed482a748ce9e0">Portal Support</option>
                      <option value="5d72432027a61f20e01acc24">Kamran Khan</option>
                      <option value="5dc0178127a61f08b0710fc2">Waqas Yaqoob</option>
                      <option value="5df5010d27a61f7877428944">Abdullah Khan</option>
                      <option value="5e8b6d5327a61f0e3d633bb4">Ilias Rubel</option>
                      <option value="5ea01c4d27a61f1e8f0d3ff8">Sajid Islam</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="classLimit" >
                    <Form.Label>Booking Limit</Form.Label>
                    <Form.Control type="number" name="classLimit" autocomplete="off" value={classLimit} onChange={this.handleOnChange}/>
                  </Form.Group>

                </div>
                <div className="col-md-6 px-4">
                  <Form.Group className="mb-3" controlId="classLink" >
                    <Form.Label>Class Link</Form.Label>
                    <Form.Control type="text" name="classLink" placeholder="zoom.com/meeting" value={classLink} onChange={this.handleOnChange}/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="classDate">
                    <Form.Label>Class Date</Form.Label>
                    <Form.Control type="date"  name="classDate" value={classDate} onChange={this.handleOnChange} />
                  </Form.Group>
                  <div className="row">
                    <div className="col-6" >
                      <Form.Group className="mb-3" controlId="classStartTime" >
                        <Form.Label>Start Time</Form.Label>
                        <Form.Control type="time" step="1800" name="classStartTime" value={classStartTime} onChange={this.handleOnChange} />
                      </Form.Group>
                    </div>
                    <div className="col-6" >
                      <Form.Group className="mb-3" controlId="classEndTime">
                        <Form.Label>End Time</Form.Label>
                        <Form.Control type="time"  name="classEndTime"  value={classEndTime} onChange={this.handleOnChange}/>
                      </Form.Group>
                    </div>
                  </div>
                  <Form.Group className="mb-3" controlId="classStatus">
                    <Form.Label>Class Status</Form.Label>
                    <Form.Select aria-label="classStatus" name="classStatus" value={classStatus} onChange={this.handleOnChange}>
                      <option value="">Please Select</option>
                      <option value="true">Active</option>
                      <option value="false">InActive</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId="eventRecurrence">
                    <Form.Label>Event Recurrence</Form.Label>
                    <Form.Select value={eventRecurrence} name="eventRecurrence" onChange={this.handleOptionChange} aria-label="eventRecurrence">
                      <option value="no_repeat">Do not repeat</option>
                      <option value="EveryDay">Every Day</option>
                      <option value="EveryWeek">Every Week on Wednesday</option>
                      <option value="EveryMonth">Month</option>
                      <option value="custom">Custom</option>
                    </Form.Select>
                  </Form.Group>
                </div>

                <div className="row container px-4">
                  {/*<Form.Check*/}
                  {/*    type="switch"*/}
                  {/*    id="custom-switch"*/}
                  {/*    label="Custom Recurrence"*/}
                  {/*    checked={isChecked} onChange={this.handleToggle}*/}
                  {/*/>*/}
                  {eventRecurrence === 'custom' && (
                      <div id="calendarDetailForm" className="show"  /*className={`form ${isChecked ? 'show' : 'hide'}`}*/>
                        <div className="col-md-8">
                          <div className="d-inline-flex align-content-center mb-3 align-items-center" >
                            <label>Repeat Every </label>
                            <Form.Group controlId="" name="">
                              <Form.Control className="mx-2" style={{width:'100px'}} type="number" placeholder="1" />
                            </Form.Group>
                            <Form.Group controlId="" name="">
                              <Form.Select style={{width:'100px'}} aria-label="">
                                <option value="Day">Day</option>
                                <option value="Week">Week</option>
                                <option value="Month">Month</option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                          <br/>
                          <label className="my-2">Repeat on</label>
                          <div className="weekdays">
                            {days.map((day, index) => (
                                <div key={index} className="day-checkbox mx-1">
                                  <input type="checkbox" id={`day-${index}`} />
                                  <label htmlFor={`day-${index}`} data-day={day}></label>
                                </div>
                            ))}
                          </div>
                          <div>
                            <label className="my-3">Ends</label><br/>

                            <div id="ckboxNever" className="form-check d-inline-flex align-items-center">
                              <input className="form-check-input" type="radio" name="exampleRadios" id="NeverRadio" value="option1" checked={radioValue === "option1"} onChange={this.handleRadioChange} />
                              <label className="form-check-label mx-2" htmlFor="NeverRadio">
                                Never
                              </label>
                            </div>
                            <br/>

                            <div id="ckboxOn" className="form-check d-inline-flex align-items-center">
                              <input className="form-check-input" type="radio" name="exampleRadios" id="OnRadio" value="option2" checked={radioValue === "option2"} onChange={this.handleRadioChange} />
                              <label className="form-check-label mx-2" htmlFor="OnRadio">
                                On
                              </label>
                              <input id="OnRadioDate" type="date" style={{width:'125px',marginLeft:'50px'}} className={`form-control ml-2 ${radioValue !== "option2" && "disabled"}`} disabled={radioValue !== "option2"} />
                            </div>
                            <br/>

                            <div id="ckboxAfter" className="form-check d-inline-flex align-items-center">
                              <input className="form-check-input" type="radio" name="exampleRadios" id="AfterRadio" value="option3" checked={radioValue === "option3"} onChange={this.handleRadioChange} />
                              <label className="form-check-label mx-2" htmlFor="AfterRadio">
                                After
                              </label>
                              <input id="AfterRadioNumber" type="number" style={{width:'100px',marginLeft:'33px'}} className={`form-control ml-2 ${radioValue !== "option3" && "disabled"}`} disabled={radioValue !== "option3"} />
                            </div>
                          </div>
                        </div>
                      </div>
                  )}
                </div>
              </div>
              <div className="form-group row mb-0">
                <div className="col-md-3 mx-3 mt-lg-5">
                  <button onClick={this.handleSubmit} type="submit" className="btn btn-primary btn-sm">Create Class</button>
                </div>
              </div>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
    )

    const offCanvasManageShits = (
        <Offcanvas placement={"bottom"} show={showCanvasShifts} className="w-100 h-75" onHide={this.handleCloseCanvasShifts} backdrop="static">
          <Offcanvas.Header className="bg-light" closeButton>
            <Offcanvas.Title>Manage Shifts</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-3">
            <div className="row">
              <ul className="nav nav-tabs justify-content-center align-items-center" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="overview-tab" data-bs-toggle="tab"
                          data-bs-target="#overview-tab-pane" type="button" role="tab"
                          aria-controls="overview-tab-pane" aria-selected="true">
                    <span className="fs-4 text-muted mx-2"><VscPreview/></span>Create Shift
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="QandA-tab" data-bs-toggle="tab"
                          data-bs-target="#QandA-tab-pane" type="button" role="tab"
                          aria-controls="QandA-tab-pane" aria-selected="false">
                    <span className="fs-4 text-muted mx-2"><RiSendPlaneLine/></span>Create Custom Limit
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="transcript-tab" data-bs-toggle="tab"
                          data-bs-target="#transcript-tab-pane" type="button" role="tab"
                          aria-controls="transcript-tab-pane" aria-selected="false">
                    <span className="fs-4 text-muted mx-2"><CgTranscript/></span>Transcript
                  </button>
                </li>
              </ul>
                <div className="mb-4 d-flex flex-column align-items-center">
                  <div className="middle-content container-xxl p-0">
                    <div className="simple-tab">
                      <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="overview-tab-pane" role="tabpanel"
                             aria-labelledby="overview-tab" tabIndex={0}>
                            <div className="row py-5 justify-content-center">
                              <div className="col-md-5 mx-3">
                                <div className="row">
                                  <div className="col-md-6">
                                    <Form.Group className="mb-3" controlId="shiftLocation">
                                      <Form.Label>Shift Location</Form.Label>
                                      <Form.Select aria-label="shiftLocation" name="shiftLocation" value={shiftLocation}
                                                   onChange={this.handleOnChange}>
                                        <option value="">Please Select</option>
                                        <option value="Park Royal">Park Royal</option>
                                        <option value="Barking">Barking</option>
                                        <option value="Croydon">Croydon</option>
                                        <option value="Birmingham">Birmingham</option>
                                        >
                                      </Form.Select>
                                    </Form.Group>

                                    <div className="row">
                                      <div className="col-6">
                                        <Form.Group className="mb-3" controlId="shiftLimit">
                                          <Form.Label>Booking Limit</Form.Label>
                                          <Form.Control type="number" name="shiftLimit" autocomplete="off" value={shiftLimit}
                                                        onChange={this.handleOnChange}/>
                                        </Form.Group>
                                      </div>
                                      <div className="col-6">
                                        <Form.Group className="mb-3" controlId="shiftStatus">
                                          <Form.Label>Shift Status</Form.Label>
                                          <Form.Select aria-label="shiftStatus" name="shiftStatus" value={shiftStatus}
                                                       onChange={this.handleOnChange}>
                                            <option value="">Please Select</option>
                                            <option value="true">Active</option>
                                            <option value="false">InActive</option>
                                          </Form.Select>
                                        </Form.Group>
                                      </div>
                                    </div>


                                  </div>
                                  <div className="col-md-6">
                                    <div className="row">
                                      <div className="col-6">
                                        <Form.Group className="mb-3" controlId="shiftStartTime">
                                          <Form.Label>Start Time</Form.Label>
                                          <Form.Control type="time" name="shiftStartTime" value={shiftStartTime}
                                                        onChange={this.handleOnChange}/>
                                        </Form.Group>
                                      </div>
                                      <div className="col-6">
                                        <Form.Group className="mb-3" controlId="shiftEndTime">
                                          <Form.Label>End Time</Form.Label>
                                          <Form.Control type="time" name="shiftEndTime" value={shiftEndTime}
                                                        onChange={this.handleOnChange}/>
                                        </Form.Group>
                                      </div>
                                    </div>
                                    <Form.Group className="mb-3" controlId="shiftNote">
                                      <Form.Label>Note (Optional)</Form.Label>
                                      <Form.Control type="text" name="shiftNote" value={shiftNote}
                                                    onChange={this.handleOnChange}/>
                                    </Form.Group>
                                    <div className="form-group row mb-0">
                                      <div className="col-md-3 mx-3 mt-lg-5">
                                        <button onClick={this.handleShiftSubmit} type="submit" className="btn btn-primary btn-sm">Save</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-5 mx-3">
                                <Table striped bordered hover>
                                  <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Shift Location</th>
                                    <th>Start - End (Time)</th>
                                    <th>Limit</th>
                                    <th>Status</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>Park Royal</td>
                                    <td>09:00 - 13:00</td>
                                    <td>12</td>
                                    <td>Active</td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>Barking</td>
                                    <td>09:00 - 13:00</td>
                                    <td>15</td>
                                    <td>InActive</td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Birmingham</td>
                                    <td>09:00 - 13:00</td>
                                    <td>12</td>
                                    <td>Active</td>
                                  </tr>

                                  </tbody>
                                </Table>
                              </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="QandA-tab-pane" role="tabpanel" aria-labelledby="QandA-tab"
                             tabIndex={0}>
                          <div className="row py-5 justify-content-center">
                            <div className="col-md-5 px-3">
                              <div className="row">
                                <div className="col-md-6">
                                  <Form.Group className="mb-3" controlId="shiftLocation">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Select aria-label="shiftLocation" name="shiftLocation" value=""
                                                 onChange={this.handleOnChange}>
                                      <option value="">Please Select</option>
                                      <option value="Park Royal">Park Royal</option>
                                      <option value="Barking">Barking</option>
                                      <option value="Croydon">Croydon</option>
                                      <option value="Birmingham">Birmingham</option>
                                      >
                                    </Form.Select>
                                  </Form.Group>
                                  <Form.Group className="mb-3" controlId="shift">
                                    <Form.Label>Shift</Form.Label>
                                    <Form.Select aria-label="shift" name="shift" value=""
                                                 onChange={this.handleOnChange}>
                                      <option value="">Please Select</option>
                                      <option value="shiftId">Park Royal (09:00 - 13:00)</option>
                                      <option value="shiftId">Park Royal (13:00 - 17:00)</option>
                                      <option value="shiftId">Barking (09:00 - 13:00)</option>
                                    </Form.Select>
                                  </Form.Group>
                                  <Form.Group className="mb-3" controlId="limitType">
                                    <Form.Label>Limit on</Form.Label>
                                    <Form.Select aria-label="limitType" name="limitType" value={this.state.limitType} onChange={this.handleLimitTypeChange}>
                                      <option value="">Please Select</option>
                                      <option value="Date">Date</option>
                                      <option value="WeekDay">Week Day</option>
                                    </Form.Select>
                                  </Form.Group>

                                  {this.state.limitType === 'WeekDay' && (
                                      <div id="limitByWeekday">
                                        <Table striped bordered hover>
                                          <tbody>
                                          <tr>
                                            <td>
                                              <Row>
                                                <Col><b>Monday</b></Col>
                                                <Col>
                                                  <Form.Control
                                                      type="number"
                                                      value={Monday}
                                                      onChange={(e) => this.handleTextFieldChange('Monday', e.target.value)}
                                                  />
                                                </Col>
                                              </Row>
                                            </td>
                                            <td>
                                              <Row>
                                                <Col><b>Tuesday</b></Col>
                                                <Col>
                                                  <Form.Control
                                                      type="number"
                                                      value={Tuesday}
                                                      onChange={(e) => this.handleTextFieldChange('Tuesday', e.target.value)}
                                                  />
                                                </Col>
                                              </Row>
                                            </td>
                                            <td>
                                              <Row>
                                                <Col><b>Wednesday</b></Col>
                                                <Col>
                                                  <Form.Control
                                                      type="number"
                                                      value={Wednesday}
                                                      onChange={(e) => this.handleTextFieldChange('Wednesday', e.target.value)}
                                                  />
                                                </Col>
                                              </Row>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <Row>
                                                <Col><b>Thursday</b></Col>
                                                <Col>
                                                  <Form.Control
                                                      type="number"
                                                      value={Thursday}
                                                      onChange={(e) => this.handleTextFieldChange('Thursday', e.target.value)}
                                                  />
                                                </Col>
                                              </Row>
                                            </td>
                                            <td>
                                              <Row>
                                                <Col><b>Friday</b></Col>
                                                <Col>
                                                  <Form.Control
                                                      type="number"
                                                      value={Friday}
                                                      onChange={(e) => this.handleTextFieldChange('Friday', e.target.value)}
                                                  />
                                                </Col>
                                              </Row>
                                            </td>
                                            <td>
                                              <Row>
                                                <Col><b>Saturday</b></Col>
                                                <Col>
                                                  <Form.Control
                                                      type="number"
                                                      value={Saturday}
                                                      onChange={(e) => this.handleTextFieldChange('Saturday', e.target.value)}
                                                  />
                                                </Col>
                                              </Row>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <Row>
                                                <Col><b>Sunday</b></Col>
                                                <Col>
                                                  <Form.Control
                                                      type="number"
                                                      value={Sunday}
                                                      onChange={(e) => this.handleTextFieldChange('Sunday', e.target.value)}
                                                  />
                                                </Col>
                                              </Row>
                                            </td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                          </tbody>
                                        </Table>
                                      </div>
                                  )}
                                  <div className="form-group row mb-0">
                                    <div className="col-md-3 mx-3 mt-lg-5">
                                      <button type="submit" className="btn btn-primary btn-sm">Save</button>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  {this.state.limitType === 'Date' && (
                                      <div id="limitByDate">
                                        <Form.Group className="mb-3" controlId="classStartTime" >
                                          <Form.Label>Date</Form.Label>
                                          <Form.Control type="date" name="classStartTime" value="" onChange={this.handleOnChange} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="shiftLimit">
                                          <Form.Label>Booking Limit</Form.Label>
                                          <Form.Control type="number" name="shiftLimit" autocomplete="off" value="" onChange={this.handleOnChange}/>
                                        </Form.Group>
                                      </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5 px-3">
                              <div className="shitLimitBydateAndWeekDay">
                                <div className="shiftLimitWeekDay">
                                  <h5 className="mb-3">Week Day Limits the Selected Shift</h5>
                                  <Table striped bordered hover>
                                    <tbody>
                                    <tr>
                                      <td>
                                        <Row>
                                          <Col><b>Monday</b></Col>
                                          <Col>
                                            <span id="Monday">12</span>
                                          </Col>
                                        </Row>
                                      </td>
                                      <td>
                                        <Row>
                                          <Col><b>Tuesday</b></Col>
                                          <Col>
                                            <span id="Tuesday"></span>
                                          </Col>
                                        </Row>
                                      </td>
                                      <td>
                                        <Row>
                                          <Col><b>Wednesday</b></Col>
                                          <Col>
                                            <span id="Wednesday">16</span>
                                          </Col>
                                        </Row>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Row>
                                          <Col><b>Thursday</b></Col>
                                          <Col>
                                            <span id="Thursday">14</span>
                                          </Col>
                                        </Row>
                                      </td>
                                      <td>
                                        <Row>
                                          <Col><b>Friday</b></Col>
                                          <Col>
                                            <span id="Friday">12</span>
                                          </Col>
                                        </Row>
                                      </td>
                                      <td>
                                        <Row>
                                          <Col><b>Saturday</b></Col>
                                          <Col>
                                            <span id="Saturday">12</span>
                                          </Col>
                                        </Row>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Row>
                                          <Col><b>Sunday</b></Col>
                                          <Col>
                                            <span id="Sunday"></span>
                                          </Col>
                                        </Row>
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    </tbody>
                                  </Table>
                                </div>
                                <div className="shiftLimitDates">
                                  <Accordion defaultActiveKey={['0']} alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                      <Accordion.Header>2025 - Custom Date Limits (3)</Accordion.Header>
                                      <Accordion.Body>
                                        <div className="px-2 py-2" style={{width:'100px', backgroundColor:'#bfc9d4', borderRadius:'10px', cursor:'pointer'}}>
                                           <span className="align-self-sm-start bg-light text-dark mb-1" style={{fontSize:'13px', borderRadius:'50%', padding:'4px'}}>24</span>
                                          <hr className="my-1"/>
                                           <span className="text-dark" style={{fontSize:'12px'}}>16 Apr 2024</span>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                      <Accordion.Header>2024 - Custom Date Limits (13)</Accordion.Header>
                                      <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*<div className="row py-5">
                            <div className="col-md-12 px-2">
                              <Table striped bordered hover>
                                <thead>
                                <tr>
                                  <th>#</th>
                                  <th>First Name</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>Mark</td>
                                </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>*/}
                        </div>
                        <div className="tab-pane fade" id="transcript-tab-pane" role="tabpanel"
                             aria-labelledby="transcript-tab" tabIndex={0}>
                          <p className="mt-3">Nullam feugiat, sapien a lacinia condimentum, libero nibh fermentum
                            lectus, eu dictum justo ex sit amet neque. Sed felis arcu, efficitur eget diam eget, maximus
                            dapibus enim. Sed vitae varius lorem. Fusce non accumsan diam, quis porttitor dolor. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

            </div>
          </Offcanvas.Body>
        </Offcanvas>
    )

    const offCanvasLocationNClass = (
        <Offcanvas placement={"bottom"} show={ShowCanvasLocationNClass} className="w-100 h-75" onHide={this.handleCloseCanvasLocationNClass} backdrop="static">
          <Offcanvas.Header className="bg-light" closeButton>
            <Offcanvas.Title>Manage Locations and Classes</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-3 pt-5">

          </Offcanvas.Body>
        </Offcanvas>
    )

    const modalEvent = (

        <div id='modalEvent' className='popupContainer animated fadeInDown modal-medium'>
          <div className='d-flex justify-content-between'>
            <div className='modal-title'>Add Event</div>
            <div className='close-btn' onClick={() => this.closeModal('modalEvent')}>×</div>
          </div>
          <hr className='modal-hr'/>

          <div className='modal-date'>{targetDate}</div>

          <br/>
          <div>Enter the subject:</div>
          <Form.Control type="text" value={newSubject} size='sm' onChange={(e) => this.onChangeSubject(e)}/>
          <br/>
          <div>Enter Priority:</div>
          <Form.Control type="number" value={newPriority} size='sm' onChange={(e) => this.onChangePriority(e)}/>
          <br/>
          <Button onClick={() => this.onSaveDate()}>save</Button>
        </div>
    )

    const modalTimeOff = (
        <div id='modalTimeOff' className='popupContainer animated fadeInDown modal-medium'>
          <div className='d-flex justify-content-between'>
            <div className='modal-title'>Add Time Off</div>
            <div className='close-btn' onClick={() => this.closeModal('modalTimeOff')}>×</div>
          </div>
          <hr className='modal-hr'/>
          <div>Add Special Working Hours" refers to a feature</div>
        </div>
    )
    const modalBlockDay = (
        <div id='modalBlockDay' className='popupContainer animated fadeInDown modal-sm-auto-height'>
          <div className='d-flex justify-content-between'>
            <div className='modal-title'>Block {targetDate} </div>
            <div className='close-btn' onClick={() => this.closeModal('modalBlockDay')}>×</div>
          </div>
          <hr className='modal-hr'/>
          <div className="mx-3 my-4">
            <Form.Group className="mb-3" controlId="BlockDay" >
              <Form.Label>Reason</Form.Label>
              <Form.Control type="text" name="BlockDay" autocomplete="off"/>
            </Form.Group>
            <br/>
            <button type="button" className="btn btn-secondary">save</button>
          </div>
        </div>
    )

    const modalSpecialWorkingHours = (
        <div id='modalSpecialWorkingHours' className='popupContainer animated fadeInDown modal-medium'>
          <div className='d-flex justify-content-between'>
            <div className='modal-title'>Add Special Working Hours</div>
            <div className='close-btn' onClick={() => this.closeModal('modalSpecialWorkingHours')}>×</div>
          </div>
          <hr className='modal-hr'/>
          <div>"Add Special Working Hours" refers to a feature commonly found in scheduling or time management applications, designed to accommodate irregular or customized work schedules. This functionality allows users to define and incorporate specific time slots or periods when they are available for work outside of their regular working hours. Users typically interact with this feature by specifying the start and end times of these special working hours, along with any relevant details such as the reason for the deviation from the standard schedule. Whether for overtime, part-time shifts, or temporary adjustments, "Add Special Working Hours" provides users with flexibility and control over their work schedules, ensuring accurate time tracking and efficient resource allocation within their workflow management systems.</div>
        </div>
    )

    return (
        (
            <div className='' style={{marginTop:'30px', fontSize:'12px', height:''}}>
              <div className='d-flex justify-content-between px-4 py-2'>
                <div className='d-flex'>
                  <button onClick={() => { this.getToday() }} className="btn btn-default mx-1">Today</button>
                  <button style={indicatorStyle} onClick={() => { this.getPrevious() }} className="btn mx-1"><FaChevronLeft/></button>
                  <button style={indicatorStyle} onClick={() => { this.getNext() }} className="btn mx-1"><FaChevronRight/></button>
                  <span className="align-self-center" style={{fontSize:'20px', color:'#000000'}}>{currentData}</span>
                </div>
                <div onClick={this.handleShowCanvasShifts} className="btn btn-light-primary">Manage Shifts</div>
                {/*<div onClick={this.handleShowCanvasLocationNClass} className="btn btn-light-primary">Locations & Classes </div>*/}
              </div>
              <Table bordered responsive className='' style={{border:'1px solid #99999950', borderRadius:'11px'}}>
                <thead >
                <tr id='tr'>
                  {header}
                </tr>
                </thead>
                <tbody>
                {body}
                </tbody>
              </Table>
              {moreList}
              {offCanvasCreateClass}
              {offCanvasManageShits}
              {offCanvasLocationNClass}
              {modalEvent}
              {modalTimeOff}
              {modalBlockDay}
              {modalSpecialWorkingHours}
            </div>
        )
    );
  }
}

const mapStateToProps = (state) => {
  return {

  }
}

export default connect (mapStateToProps)(LocalCalendar);

