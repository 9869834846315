import React,{useState,useRef,useEffect,useMemo,useReducer} from 'react';
import ReactDOM from "react-dom";
import { Link,useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import {MaterialReactTable} from 'material-react-table';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  getCourses,
  getCourse,
  upgradeCourse,
  deleteCourse,
  addLocationClassSection,
}   from "../../redux/crm-features/course/courseSlice";
import CourseFormModal from "./CourseFormModal";

const data = [
  {
    id: '1',
    image: 'https://www.filepicker.io/api/file/zpegYr24TqW4swupyNGm',
    name: 'Book 1',
    author: 'Author 1',
    createdDate: '2023-01-15',
    sales: 100,
    status: 'Active',
    enrollments: 50,
  },
  {
    id: '2',
    image: 'https://www.filepicker.io/api/file/zpegYr24TqW4swupyNGm',
    name: 'Book 2',
    author: 'Author 2',
    createdDate: '2022-11-20',
    sales: 80,
    status: 'Inactive',
    enrollments: 30,
  },
  {
    id: '3',
    image: 'https://www.filepicker.io/api/file/zpegYr24TqW4swupyNGm',
    name: 'Book 3',
    author: 'Author 3',
    createdDate: '2024-02-10',
    sales: 120,
    status: 'Active',
    enrollments: 70,
  },
  // Add more data objects as needed
];

const options = [
  { value: 'Salman', label: 'Salman' },
  { value: 'Asad', label: 'Asad' },
  { value: 'Hasnain', label: 'Hasnain' }
]

const classes = [
    { label: "Demo1", value: "Demo1" },
    { label: "Demo2", value: "Demo2" },
    { label: "Demo3", value: "Demo3" },
    { label: "Demo4", value: "Demo4" }
  ];

const initialState ={
  locationSection : '',
  classSection : '',
}

const AllCourses = () => {

  useRedirectLoggedOutUser("/crm/login");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [locationClassData, setLocationClassData] = useState(initialState);
  const {locationSection, classSection} = locationClassData;

  const [ViewLocationModal, SetLocationModalShow] = useState(false)
  const handleLocationModalShow = () => { SetLocationModalShow(true) }
  const hanldeLocationModalClose = () => { SetLocationModalShow(false) }

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [loading, setLoading] = useState(false);

  const [value, setvalue] = useState("");
  const handleOnchange = (val) => setvalue(val);

  const { courses, course } = useSelector(
      (state) => state.course
  );

  useEffect(() => {
      dispatch(getCourses());
  }, [dispatch]);

  const removeCourse = async (id) => {
      await dispatch(deleteCourse(id));
      dispatch(getCourses());
  };

  const confirmDelete = (id) => {
      confirmAlert({
        title: "Delete This Course",
        message: "Are you sure to do delete this course?",
        buttons: [
          {
            label: "Delete",
            onClick: () => removeCourse(id),
          },
          {
            label: "Cancel",
            // onClick: () => alert("Click No"),
          },
        ],
      });
  };

	const columns = useMemo(
    () => [
      {
        accessorFn: (row) => <> <span><img src={row.courseThumbnail} style={{width : '100px'}}/></span></>, //alternate way
        id: 'courseThumbnail', //id required if you use accessorFn instead of accessorKey
        header: 'Thumbnail',
        Header: () => <span>Thumbnail</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.courseTitle, //alternate way
        id: 'courseTitle', //id required if you use accessorFn instead of accessorKey
        header: 'Name',
        Header: () => <span>Name</span>, //optional custom header render
        Cell: ({ renderedCellValue, row }) => (
          <span className="d-flex align-items-center"><Link to={`/crm/course-detail/${row.original._id}`}><b>{renderedCellValue}</b></Link>
            <div className="customDropDownButton">
              {['end'].map(
                (direction) => (
                  <DropdownButton
                    key={direction}
                    id={`dropdown-button-drop-${direction}`}
                    drop={direction}
                    title={`...`}
                  >
                    {/*<li><Link className="dropdown-item" onClick={() => {handleCourseFormModalShow()}}>Click me</Link></li>*/}
                    <li><Link className="dropdown-item">
                    <CourseFormModal 
                    _id={row.original._id} 
                    courseLevel={row.original.courseLevel}
                    courseAccess={row.original.courseAccess}
                    courseIpAddress={row.original.courseIpAddress}
                    courseType={row.original.courseType}
                    courseInstructor={row.original.courseInstructor}
                    courseDuration={row.original.courseDuration}
                    courseFee={row.original.courseFee}
                    courseDiscount={row.original.courseDiscount}
                    courseTotalHours={row.original.courseTotalHours}
                    courseClasses={row.original.courseClasses}
                    />
                    </Link></li>
                  </DropdownButton>
                ),
              )}
            </div>
          </span>
        ),
      },
      {
        accessorFn: (row) => row.courseInstructor, //alternate way
        id: 'courseInstructor', //id required if you use accessorFn instead of accessorKey
        header: 'Author',
        Header: () => <span>Author</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.createdAt, //alternate way
        id: 'createdAt', //id required if you use accessorFn instead of accessorKey
        header: 'Creation Date',
        Header: () => <span>Creation Date</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.courseSales, //alternate way
        id: 'courseSales', //id required if you use accessorFn instead of accessorKey
        header: 'Sales',
        Header: () => <span>Sales</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.coursePublished, //alternate way
        id: 'coursePublished', //id required if you use accessorFn instead of accessorKey
        header: 'Status',
        Header: () => <span>Status</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.courseEnrollments, //alternate way
        id: 'courseEnrollments', //id required if you use accessorFn instead of accessorKey
        header: 'Enrollments',
        Header: () => <span>Enrollments</span>, //optional custom header render
      },
      {
        accessorFn: (row) => <span>
                            <FaTrashAlt
                              onClick={() => confirmDelete(row._id)}
                              size={18}
                              cursor="pointer"
                              color="rgb(0,0,0,.8)"
                            />
                            &nbsp;
                            <span to={`/crm/course-detail/${row._id}`}>
	                            <AiFillEdit
	                              size={20}
	                              cursor="pointer"
	                              color="rgb(0,0,0,.8)"
	                            />
                            </span>
                          </span>, //alternate way
        id: 'action', //id required if you use accessorFn instead of accessorKey
        header: 'Action',
        Header: () => <span>Action</span>, //optional custom header render
      },
    ],
    [],
  );

  const isFirstRender = useRef(true);
	const [columnVisibility, setColumnVisibility] = useState({});

	useEffect(() => {
	    const columnVisibility = localStorage.getItem(
	      'mrt_columnVisibility_table_1',
	    );
	    
	    if (columnVisibility) {
	      setColumnVisibility(JSON.parse(columnVisibility));
	    }
	    
	    isFirstRender.current = false;
	  }, []);

	useEffect(() => {
	    if (isFirstRender.current) return;
	    localStorage.setItem(
	      'mrt_columnVisibility_table_1',
	      JSON.stringify(columnVisibility),
	    );
	  }, [columnVisibility]);

  // const [courseLevel, setCourseLevel] = useState('');
  // const [showField, setShowField] = useState(false);

  // const handleSelectChange = (event) => {
  //   const selectedOption = event.target.value;
  //   setCourseLevel(selectedOption);

  //   // If 'Online' is selected, set showField to true
  //   setShowField(selectedOption === 'center');
  // };

  // const [ipAddress, setIpAddress] = useState('');

  // useEffect(() => {
  //   fetch('https://api.ipify.org?format=json')
  //     .then(response => response.json())
  //     .then(data => setIpAddress(data.ip))
  //     .catch(error => console.error('Error fetching IP address:', error));
  // }, []);

  const [sections, setSections] = useState([]);
  const [secondSections, setSecondSections] = useState([]);

  useEffect(() => {
      if(sections.length===0) {
        addSection();
      } else {

      }
      if(sections.length===0) {
        addSecondSection();
      } else {

      }
  }, []);

  const addSection = () => {
      const newSection = {
        id: sections.length,
        // panels: [] // Each section starts with an empty list of panels
      };
      setSections([...sections, newSection]);
  };

  const addSecondSection = () => {
      const secSection = {
        id: secondSections.length,
        // panels: [] // Each section starts with an empty list of panels
      };
      setSecondSections([...secondSections, secSection]);
  };

  const deleteSection = (sectionId) => {
      setSections(prevSections => prevSections.filter(section => section.id !== sectionId));
  };

  const deleteSecondSection = (sectionId) => {
      setSecondSections(prevSections => prevSections.filter(section => section.id !== sectionId));
  };

  const locationHandler = (e, i) => {
    var locationVal = e.target.value
    var locationX = JSON.parse(JSON.stringify(sections));
    locationX[i].title = locationVal
      // console.log(sections[i])
      setSections(locationX);
  };

  const classHandler = (e, i) => {
    var classVal = e.target.value
    var classX = JSON.parse(JSON.stringify(secondSections));
    classX[i].title = classVal
      // console.log(sections[i])
      setSecondSections(classX);
  };

  const courseSectionAdd = async (e) => {
    e.preventDefault();
    var locationClassDataArr = JSON.parse(JSON.stringify(locationClassData));
    locationClassDataArr.locationSection = sections
    locationClassDataArr.classSection = secondSections
    console.log(90, locationClassDataArr);
    await dispatch(addLocationClassSection(locationClassDataArr));
    // setSections({locationSection: ''});
    // setSecondSections({classSection: ''});
    hanldeLocationModalClose();
    // forceUpdate();
  };
	
	return (
		<>

    
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />

		                    <div className="row layout-top-spacing">

		                    	<div className="col-md-12">
                              <div className="form-group text-end">
                                  <Link to="/crm/course" className="btn btn-secondary _effect--ripple waves-effect waves-light">New Course</Link>
                                  <Link onClick={handleLocationModalShow} to="" className="btn btn-secondary _effect--ripple waves-effect waves-light mx-1">Locations & Classes</Link>
                              </div>
                          </div>

                          <div className="statbox widget box box-shadow moduleData">
                              <div className="widget-content widget-content-area">
                              {courses.length === 0 ? (
                                      <p>Course not found...</p>
                                    ):(
                              	<MaterialReactTable 
                              	columns={columns} 
                              	data={courses} 
                              	onColumnVisibilityChange={setColumnVisibility}
                              	state={{columnVisibility}}
                              	options={{
												          filtering: true // Enable filtering globally
												        }}
												        enableRowSelection={true}
                              	enableRowNumbers 
                              	rowNumberMode="original"
                              	/>
                              )}
                              </div>
                          </div>
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}	

        
        {/*<!-- Modal -->*/}
        <Modal show={ViewLocationModal}
                    onHide={hanldeLocationModalClose}
                    backdrop="static"
                    keyboard={false}
                    className="modal-xl w-100"
                    >

        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="courseCurriculumSec">
              <h4>Locations</h4>
              <div  className="section d-flex align-items-baseline">
               
              <div className="newSecPanel">
              
              <div className="panels">
                  {sections && sections.map((section, i) => (
                    <div key={section.id} className="panel d-flex align-items-baseline">
                    <div>
                      <button type="button" className="dndBtn"><i className="fa fa-th"></i></button>
                    </div>
                    <div className="newLessonPanel d-flex align-items-center justify-content-between">
                  <div className="col-8">
                  
                      <form className="d-flex align-items-center justify-content-between">
                        <div className="form-group w-100">
                                  <input type="text" className="form-control" placeholder="New Location" defaultValue={sections[i].title} name={`location-${i}`} onChange={(e) => locationHandler(e, i)}/>
                              </div>
                              <div className="d-flex">
                                <button type="button" className="btn btn-secondary mx-1">Save</button>
                                <button type="button" className="btn btn-secondary">Cancel</button>
                              </div>
                      </form>
                      </div>
                      <div className="newSecPanelDropDown">
                      {['end'].map(
                        (direction) => (
                          <DropdownButton
                            key={direction}
                            id={`dropdown-button-drop-${direction}`}
                            drop={direction}
                            title={<i className="fa fa-ellipsis-v"></i>}
                          >
                          <li>
                        <span>Set as a public preview</span>
                            </li>
                            <li>
                        <span>Rename Location</span>
                            </li>
                            <li>
                        <span>Duplicate Location</span>
                            </li>
                            <li onClick={() => deleteSection(section.id)}>
                        <span>Delete Location</span>
                            </li>
                            
                          </DropdownButton>
                        ),
                      )}
                  </div>
                </div>
                    </div>
                  ))}
                </div>
                <div className="newSecPanelBottom">
                  <ul>
                    <li onClick={addSection}>
                      <i className="fa fa-plus-circle"></i>
                      <span>New Location</span>
                    </li>
                    <li>
                      <span>Bulk upload</span>
                    </li>
                    <li>
                      <span>Location summary</span>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-12">
            <div className="courseCurriculumSec">
            <h4>Classes</h4>
              <div  className="section d-flex align-items-baseline">
               
              <div className="newSecPanel">
                
              <div className="panels">
                  {secondSections && secondSections.map((secondSection, i) => (
                    <div key={secondSection.id} className="panel d-flex align-items-baseline">
                    <div>
                      <button type="button" className="dndBtn"><i className="fa fa-th"></i></button>
                    </div>
                    <div className="newLessonPanel d-flex align-items-center justify-content-between">
                  <div className="col-8">
                  
                      <form className="d-flex align-items-center justify-content-between">
                        <div className="form-group w-100">
                                  <input type="text" className="form-control" placeholder="New Class" defaultValue={secondSections[i].title} name={`class-${i}`} onChange={(e) => classHandler(e, i)}/>
                              </div>
                              <div className="d-flex">
                                <button type="button" className="btn btn-secondary mx-1">Save</button>
                                <button type="button" className="btn btn-secondary">Cancel</button>
                              </div>
                      </form>
                      </div>
                      <div className="newSecPanelDropDown">
                      {['end'].map(
                        (direction) => (
                          <DropdownButton
                            key={direction}
                            id={`dropdown-button-drop-${direction}`}
                            drop={direction}
                            title={<i className="fa fa-ellipsis-v"></i>}
                          >
                          <li>
                        <span>Set as a public preview</span>
                            </li>
                            <li>
                        <span>Rename Class</span>
                            </li>
                            <li>
                        <span>Duplicate Class</span>
                            </li>
                            <li onClick={() => deleteSecondSection(secondSection.id)}>
                        <span>Delete Class</span>
                            </li>
                            
                          </DropdownButton>
                        ),
                      )}
                  </div>
                </div>
                    </div>
                  ))}
                </div>
                <div className="newSecPanelBottom">
                  <ul>
                    <li onClick={addSecondSection}>
                      <i className="fa fa-plus-circle"></i>
                      <span>New Class</span>
                    </li>
                    <li>
                      <span>Bulk upload</span>
                    </li>
                    <li>
                      <span>Class summary</span>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
          
        <div className="form-group text-end">
          <button type="submit" onClick={courseSectionAdd} className="m-1 btn btn-secondary">Save</button>
          <button onClick={hanldeLocationModalClose} className="m-1 btn btn-secondary">Close</button>
        </div>
      </Modal>
		</>
	)
}

export default AllCourses