import React,{useState} from 'react';
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Breadcrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const options = [
  { value: 'Advanced Bookkeeping (AVBK)', label: 'Advanced Bookkeeping (AVBK)' },
  { value: 'AAT Professional Diploma in Accounting (Level 4)', label: 'AAT Professional Diploma in Accounting (Level 4)' },
  { value: 'Final Accounts Preparation (FAPR)', label: 'Final Accounts Preparation (FAPR)' },
  { value: 'Indirect Tax (IDRX)', label: 'Indirect Tax (IDRX)' },
  { value: 'Financial Statements of Limited Companies', label: 'Financial Statements of Limited Companies' },
  { value: 'Management Accounting Costing (MMAC)', label: 'Management Accounting Costing (MMAC)' },
  { value: 'Bookkeeping Controls (BKCL)', label: 'Bookkeeping Controls (BKCL)' },
  { value: 'AAT L3 Advanced Synoptic (ADSY) Ethics for Accountants', label: 'AAT L3 Advanced Synoptic (ADSY) Ethics for Accountants' },
  { value: 'Bookkeeping Transactions (BTRN)', label: 'Bookkeeping Transactions (BTRN)' },
  { value: 'Spreadsheet for Accounting', label: 'Spreadsheet for Accounting' },
  { value: 'AAT level 4 - Personal tax', label: 'AAT level 4 - Personal tax' },
]


const CourseCurriculumImport = () => {
	// const [isClearable, setIsClearable] = useState(true);
	// const [isSearchable, setIsSearchable] = useState(true);
	const [loading, setLoading] = useState(false);

	const [selectedOption, setSelectedOption] = useState(null);
  
	const handleSelectChange = (selectedOption) => {
	    setSelectedOption(selectedOption);
	};
		
	const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

  const nodes = [
    {
      value: 'first-sec',
      label: 'First Section',
      children: [
        { value: 'first-lec', label: 'First Lecture' },
        { value: 'second-lec', label: 'Second Lecture' },
      ],
    },
    {
      value: 'second-sec',
      label: 'Second Section',
      children: [
        { value: 'third-lec', label: 'Third Lecture' },
        { value: 'forth-lec', label: 'Forth Lecture' },
      ],
    },
  ];

  const nodes2 = [
    {
      value: 'third-sec',
      label: 'Third Section',
      children: [
        { value: 'fifth-lec', label: 'Fifth Lecture' },
        { value: 'sixth-lec', label: 'Sixth Lecture' },
      ],
    },
    {
      value: 'forth-sec',
      label: 'Forth Section',
      children: [
        { value: 'seventh-lec', label: 'Seventh Lecture' },
        { value: 'eight-lec', label: 'Eight Lecture' },
      ],
    },
  ];

  const onCheck = checked => {
    setChecked(checked);
  };

  const onExpand = expanded => {
    setExpanded(expanded);
  };

	return (
		<>
			{/*<!--  BEGIN MAIN CONTAINER  -->*/}
		    <div className="main-container" id="container">

		        <div className="overlay"></div>
		        <div className="search-overlay"></div>

		        <Sidebar />

		        {/*<!--  BEGIN CONTENT AREA  -->*/}
		        <div id="content" className="main-content">
		            <div className="layout-px-spacing">

		                <div className="middle-content container-xxl p-0">

		                    <Breadcrumb />

		                    <div className="row layout-top-spacing">

		                    	<div className="courseCurriculumImportHeading">
		                    		<h6>Import content</h6>
		                    	</div>

		                    	<div className="courseCurriculumImportSec">
		                    		<h4>Import content from another course</h4>
		                    		<p>Copy content from another course into this course. Once your content has been imported you can easily edit and rearrange it.</p>
		                    		<div className="col-md-9 col-12">
                                        <div className="form-group">
                                        	<label>Select Course</label>
                                            <Select
												className="basic-single"
												classNamePrefix="All Records"
												value={selectedOption}
												isLoading={loading}
												isClearable={true}
												isSearchable={true}
												name="color"
												options={options}
												onChange={handleSelectChange}
											/>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4 customReactCheckTree">
																		    {[
																		        {
																		            condition: selectedOption?.value === 'Advanced Bookkeeping (AVBK)',
																		            nodes: nodes,
																		        },
																		        {
																		            condition: selectedOption?.value === 'AAT Professional Diploma in Accounting (Level 4)',
																		            nodes: nodes2,
																		        },
																		        {
																		            condition: selectedOption?.value === 'Final Accounts Preparation (FAPR)',
																		            nodes: nodes,
																		        }
																		    ].map(({ condition, nodes }, index) => 
																		        condition && 
																		            <CheckboxTree
																		                key={index}
																		                nodes={nodes}
																		                checked={checked}
																		                expanded={expanded}
																		                onCheck={onCheck}
																		                onExpand={onExpand}
																		            />
																		    )}
																		</div>
                                    <div className="col-md-12 mt-5">
                                        <div className="form-group text-end">
                                            <Link to="" className="btn btn-secondary _effect--ripple waves-effect waves-light">Import</Link>
			                											<Link to="/crm/course/curriculum" className="btn btn btn-light-dark mx-3">Back</Link>
                                        </div>
                                    </div>
		                    	</div>
		                        
		                    </div>

		                </div>

		            </div>

		           <Footer />

		        </div>
		        {/*<!--  END CONTENT AREA  -->*/}			

		    </div>
		    {/*<!-- END MAIN CONTAINER -->*/}	
		</>
	)
}

export default CourseCurriculumImport