import React,{useState,useEffect} from 'react';
import { Link,useParams } from 'react-router-dom';
import {AiOutlineLeft} from 'react-icons/ai';
import { useDispatch, useSelector } from "react-redux";
import {
  	getModules,
} 	from "../../redux/crm-features/moduleItem/moduleSlice";
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";

const ModuleSideBar = () => {

    const dispatch = useDispatch();
	const { id } = useParams();

	const { moduleItems } = useSelector(
	    (state) => state.moduleItem
	);

	useEffect(() => {
	    dispatch(getModules());
	}, [dispatch]);
	
	return(
		<div style={{ display: "flex", height: "100vh", position: 'sticky', top: 0 }}>
			<Sidebar className="custom_sidebar">
				<Menu>
					{
						moduleItems.map((item, index)=>{
							return (
								<MenuItem key={index} component={<Link to={`/crm/module/${item._id}`} className="link" />}> {item.title} </MenuItem>
							)
						})
					}
				</Menu>
			</Sidebar>
		</div>
	);
}

export default ModuleSideBar