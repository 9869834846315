import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import calendarService from "./calendarService";

const initialState = {
    class: null,
    classes: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// Reusable function for handling error and toast notification
const handleError = (error) => {
    return (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
};
// Async thunks
const createAsyncThunkWithHandler = (name, asyncThunk) => {
    return createAsyncThunk(name, async ({ id, ...rest }, thunkAPI) => {
        try {
            return await asyncThunk({ id, ...rest });
        } catch (error) {
            return thunkAPI.rejectWithValue({ message: handleError(error), id, ...rest });
        }
    });
};
export const addClass = createAsyncThunkWithHandler("addClass", calendarService.addClass);
export const getClass = createAsyncThunkWithHandler("getClass", calendarService.getClass);
export const updateClass = createAsyncThunkWithHandler("updateClass", calendarService.updateClass);
export const deleteClass = createAsyncThunkWithHandler("deleteClass", calendarService.deleteClass);

// Slice
const calendarSlice = createSlice({
    name: 'calendar', // Changed from 'class' to 'calendar'
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith("/pending"),
                (state) => {
                    state.isLoading = true;
                    state.isSuccess = false;
                    state.isError = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith("/fulfilled"),
                (state, action) => {
                    state.isLoading = false;
                    state.isSuccess = true;
                    state.isError = false;
                    if (action.payload) {
                        state.classes = action.payload; // Changed from `courses` to `classes`
                        state.class = action.payload; // Changed from `course` to `class`
                    }
                }
            )
            .addMatcher(
                (action) => action.type.endsWith("/rejected"),
                (state, action) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.message = action.payload;
                    toast.error(action.payload);
                }
            );
    },
});

export const selectClass = (state) => state.calendar.class; // Changed from `course` to `class`

export default calendarSlice.reducer;
