import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/calendar/crm/`;


// add Class
const addClass = async(calenderData) =>{
    const response = await axios.post(API_URL + "addClass", calenderData);
    return response.data;
};

// Get all courses
/*const getCourses = async () => {
    const response = await axios.get(API_URL + "getClass");
    return response.data;
};*/

// get getClass
const getClass = async(id) =>{
    const response = await axios.get(API_URL + id);
    return response.data;
};

// upgrade course
/*const upgradeCourse = async(calenderData) =>{
    const response = await axios.put(API_URL + "upgradeCourse", calenderData);
    return response.data.message;
};*/

// update Class
const updateClass = async(id, calenderData) =>{
    const response = await axios.patch(`${API_URL}${id}`, calenderData);
    return response.data;
};

// delete Class
const deleteClass = async(id) =>{
    const response = await axios.delete(API_URL + id);
    return response.data.message;
};


const calendarService = {
    addClass,
    getClass,
    updateClass,
    deleteClass,
}

export default calendarService;