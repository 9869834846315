import React,{useState,useEffect} from "react";
import { Link,useParams } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import Footer from "../components/Footer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { VscPreview } from "react-icons/vsc";
import { PiNotebook } from "react-icons/pi";
import { CgTranscript } from "react-icons/cg";
import { RiSendPlaneLine } from "react-icons/ri";
import { Button, Container, Row, Col } from 'react-bootstrap';
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectCourse,
  getCourses,
  getCourse,
}   from "../../redux/crm-features/course/courseSlice";
/*const docs = [
    {
        uri: "https://crmone.s3.us-east-2.amazonaws.com/uploads/1709894835668-SampleVideo_1280x720_10mb.mp4",
        fileType: "video/mp4",
        fileName: "SampleVideo_1280x720_10mb.mp4",
    }, // Remote file
    {
        uri: "https://crmone.s3.us-east-2.amazonaws.com/uploads/Registration+Process+Sage+Awards.pdf",
        fileType: "application/pdf",
        fileName: "Registration+Process+Sage+Awards.pdf",
    },
    {
        uri: "https://crmone.s3.us-east-2.amazonaws.com/uploads/Schedule.xlsx",
        fileType: "xlsx",
        fileName: "Schedule.xlsx",
    }, // Remote file
    {
        uri: "https://crmone.s3.us-east-2.amazonaws.com/uploads/Dickinson_Sample_Slides.pptx",
        fileType: "pptx",
        fileName: "Dickinson_Sample_Slides.pptx",
    },
    {
        uri: "https://crmone.s3.us-east-2.amazonaws.com/uploads/ufqekd9f.png",
        fileType: "png",
        fileName: "ufqekd9f.png",
    }, // Remote file
    {
        uri: "https://crmone.s3.us-east-2.amazonaws.com/uploads/Developer+job+Posting.docx",
        fileType: "docx",
        fileName: "Developer+job+Posting.docx",
    },
    //{ uri: require("./example-files/pdf.pdf") }, // Local File
];*/

const Slider = ({ items }) => {
    const [index, setIndex] = useState(0);

    const handlePrevious = () => {
        setIndex(prevIndex => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setIndex(prevIndex => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <Container>
            <Row>
                <Col className="text-right mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="fw-bold align-self-start">Sage50_introduction</h4>
                        <div className="" style={{ marginTop: '-10px' }}>
                            <span className="fs-2 text-black mx-1" style={{cursor:'pointer'}} onClick={handlePrevious}><FaArrowAltCircleLeft/></span>
                            <span className="fs-2 text-black mx-1" style={{cursor:'pointer'}} onClick={handleNext}><FaArrowAltCircleRight/></span>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                {items.map((item, idx) => (
                    <Col key={idx} style={{ display: index === idx ? 'block' : 'none' }}>
                        {item}
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

const CoursesView = () => {

    useRedirectLoggedOutUser("/crm/login");

    const { id } = useParams();
    const dispatch = useDispatch();
    const { courses, course } = useSelector(
      (state) => state.course
    );

    useEffect(() => {
      dispatch(getCourse(id));
    }, [dispatch, id]);

    /*disable right click context menu*/
    useEffect(() => {
        const disableRightClick = (event) => {
            if (event.button === 2) {
                event.preventDefault();
                //alert("Right-click context menu is disabled.");
            }
        };

        document.body.addEventListener('contextmenu', disableRightClick);

        return () => {
            document.body.removeEventListener('contextmenu', disableRightClick);
        };
    }, []);
    /*disable right click context menu*/
    const items = [
        <video controls width="100%" height="600px" className="videoPlayer">
            <source src="https://crmone.s3.us-east-2.amazonaws.com/uploads/1709894835668-SampleVideo_1280x720_10mb.mp4"></source>
        </video>,
        //<DisableLeftClickIframe width="100%" height="600px" src="https://crmone.s3.us-east-2.amazonaws.com/uploads/Registration+Process+Sage+Awards.pdf" />,
            <iframe style={{pointerEvents:'none',userSelect:'none'}} width="100%" height="600px"
                    src="https://crmone.s3.us-east-2.amazonaws.com/uploads/Registration+Process+Sage+Awards.pdf"></iframe>,
        <iframe style={{pointerEvents:'none',userSelect:'none'}} width="100%" height="600px" frameBorder="0" scrolling="no"
                src="https://view.officeapps.live.com/op/embed.aspx?src=https://crmone.s3.us-east-2.amazonaws.com/uploads/Schedule.xlsx&waccluster=PIE1"></iframe>,
        <iframe style={{pointerEvents:'none',userSelect:'none'}} src="https://view.officeapps.live.com/op/embed.aspx?src=https%3A%2F%2Fcrmone%2Es3%2Eus%2Deast%2D2%2Eamazonaws%2Ecom%3A443%2Fuploads%2FDickinson%5FSample%5FSlides%2Epptx&amp;wdAr=1.3333333333333333&amp;Embed=1&amp;hideControls=true"
            width="100%" height="600px">
        </iframe>,
        <iframe style={{pointerEvents:'none',userSelect:'none'}} width="100%" height="600px"
                src="https://crmone.s3.us-east-2.amazonaws.com/uploads/ufqekd9f.png"></iframe>,
        <iframe style={{pointerEvents:'none',userSelect:'none'}} width="100%" height="600px"
                src="https://view.officeapps.live.com/op/embed.aspx?src=https://crmone.s3.us-east-2.amazonaws.com/uploads/Developer+job+Posting.docx&embedded=true">
        </iframe>
    ];

    return (
        <>
            {/*<!--  BEGIN MAIN CONTAINER  -->*/}
            <div className="main-container" id="container">

                <div className="overlay"></div>
                <div className="search-overlay"></div>

                <div style={{ display: "flex", height: "100vh", position: 'sticky', top: 0 }}>
                    <Sidebar style={{width:"320px"}} className="custom_sidebar">
                        <h5 className="fw-bold pb-2 px-4 border-bottom text-capitalize">{course && (<>{course.courseTitle}</>) }</h5>
                        <Menu>
                            <SubMenu label="Starlite_Bookkeeping" title="Starlite_Bookkeeping" className="border-bottom">
                                <MenuItem title="Sage50_Introduction" component={<Link to="/crm" className="link" />}> Sage50_Introduction </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> Inhouse outsourced perspective </MenuItem>
                            </SubMenu>
                            <SubMenu label="Business Orientation and VAT Class" className="border-bottom" >
                                <MenuItem component={<Link to="/crm" className="link" />}>  Business Orientation and VAT Class_Presentation </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> VAT Class_Presentation </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> Business Orientation </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> Sage Backup </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> Intro to Xero </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> Business Tax</MenuItem>
                            </SubMenu>
                            <SubMenu label="Top Handy_Bookkeeping" className="border-bottom">
                                <MenuItem component={<Link to="/crm" className="link" />}> Top Handy Company Background </MenuItem>
                                <MenuItem component={<Link to="/crm" className="link" />}> Top Handy_Processing Invoices under Reverse Charge </MenuItem>
                            </SubMenu>
                        </Menu>
                    </Sidebar>

                </div>

                {/*<!--  BEGIN CONTENT AREA  -->*/}
                <div id="content" className="main-content px-lg-5">
                    <div className="p-3 mb-2 d-flex flex-column justify-content-center align-items-center">

                        <div className="middle-content container px-3" style={{maxHeight:'670px',overflow:'auto'}}>

                            <div>
                                <Slider items={items} />
                            </div>
                            {/*<DocViewer style={{width: "auto",minHeight: "580px", maxHeight:"630px"}} documents={docs} pluginRenderers={DocViewerRenderers} />

                            <video controls width="100%" height="600px" className="videoPlayer">
                                <source src="https://crmone.s3.us-east-2.amazonaws.com/uploads/1709894835668-SampleVideo_1280x720_10mb.mp4"></source>
                            </video>
                            <iframe width="100%" height="600px" src="https://crmone.s3.us-east-2.amazonaws.com/uploads/Registration+Process+Sage+Awards.pdf"></iframe>

                            <iframe width="100%" height="600px" frameBorder="0" scrolling="no"
                                    src="https://view.officeapps.live.com/op/embed.aspx?src=https://crmone.s3.us-east-2.amazonaws.com/uploads/Schedule.xlsx&waccluster=PIE1"></iframe>

                            <iframe
                                src="https://view.officeapps.live.com/op/embed.aspx?src=https%3A%2F%2Fcrmone%2Es3%2Eus%2Deast%2D2%2Eamazonaws%2Ecom%3A443%2Fuploads%2FDickinson%5FSample%5FSlides%2Epptx&amp;wdAr=1.3333333333333333&amp;Embed=1&amp;hideControls=true"
                                width="100%" height="600px">
                            </iframe>

                            <iframe width="100%" height="600px" style={{objectFit:'contain'}} src="https://crmone.s3.us-east-2.amazonaws.com/uploads/ufqekd9f.png"></iframe>

                            <iframe width="100%" height="600px"
                                src="https://docs.google.com/gview?url=https://crmone.s3.us-east-2.amazonaws.com/uploads/Developer+job+Posting.docx&embedded=true">
                            </iframe>*/}
                        </div>
                    </div>
                    <div className="p-3 mb-4 d-flex flex-column align-items-center">
                        <div className="middle-content container-xxl p-0">
                            <div className="simple-tab">
                                <ul className="nav nav-tabs justify-content-center align-items-center" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="overview-tab" data-bs-toggle="tab"
                                                data-bs-target="#overview-tab-pane" type="button" role="tab" aria-controls="overview-tab-pane" aria-selected="true">
                                            <span className="fs-4 text-muted mx-2"><VscPreview /></span>Overview</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="QandA-tab" data-bs-toggle="tab"
                                                data-bs-target="#QandA-tab-pane" type="button" role="tab" aria-controls="QandA-tab-pane" aria-selected="false">
                                            <span className="fs-4 text-muted mx-2"><RiSendPlaneLine /></span>Q & A</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="notebook-tab" data-bs-toggle="tab"
                                                data-bs-target="#notebook-tab-pane" type="button" role="tab" aria-controls="notebook-tab-pane" aria-selected="false" disabled>
                                            <span className="fs-4 text-muted mx-2"><PiNotebook /></span>Notebook</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="transcript-tab" data-bs-toggle="tab"
                                                data-bs-target="#transcript-tab-pane" type="button" role="tab" aria-controls="transcript-tab-pane" aria-selected="false" disabled>
                                            <span className="fs-4 text-muted mx-2"><CgTranscript /></span>Transcript</button>
                                    </li>
                                </ul>
                                <div className="tab-content container" id="myTabContent">
                                    <div className="tab-pane fade show active" id="overview-tab-pane" role="tabpanel" aria-labelledby="overview-tab" tabIndex={0}>
                                        <div className="row pt-4">
                                            <div className=" style-4">
                                                <p className="text-muted">INSTRUCTOR</p>
                                                <div className="media mt-0 mb-3 pt-3">
                                                    <div className="avatar avatar-md avatar-indicators me-3">
                                                        <img alt="avatar" src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                                                             className="rounded-circle"/>
                                                    </div>
                                                    <div className="media-body">
                                                        <h4 className="media-heading mb-0">{course?.courseInstructor}</h4>
                                                        <p className="media-text">Training Manager</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row pt-4">
                                            <h6 className="fs-4 fw-bold">Course details</h6>
                                            <div className="text-muted">
                                                <span className="px-1">{course?.courseTotalHours}<span className="px-1">-</span></span>
                                                <span className="px-1">{course?.courseLevel}<span className="px-1">-</span></span>
                                                <span className="px-1">Released: {moment(course?.createdAt).format("DD MMM")}</span>
                                            </div>
                                            <p className="mt-3" dangerouslySetInnerHTML={{ __html: course?.courseOverview }}>
                                            {/*{course?.courseOverview}*/}
                                            </p>
                                        </div>
                                        <div className="row pt-4">
                                            <h6 className="fs-4 fw-bold">Skills covered</h6>
                                            <div className="p-2">
                                                <span className="badge badge-light-dark mb-2 me-4">{course?.courseSkills}</span>
                                                {/*<span className="badge badge-light-dark mb-2 me-4">Payroll</span>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="QandA-tab-pane" role="tabpanel" aria-labelledby="QandA-tab" tabIndex={0}>
                                        <div className="row pt-4" dangerouslySetInnerHTML={{ __html: course?.courseQnA }}>
                                            {/*<h6 className="fs-4 fw-bold">What are the Financial Statements?</h6>
                                            <p className="mb-4">The financial statements are the three primary reports that summarise a company’s financial position, results of operations and cash flows. The accounting statements are the balance sheet, profit and loss (income statement), and cash flow statement.</p>
                                            <h6 className="fs-4 fw-bold">What is the Easiest Way to Produce the Financial Statements?</h6>
                                            <p className="mb-4">Using accounting software is by far the easiest way to process your transactions and prepare financial statements. The software will automate many tasks for you, such as recording transactions, producing reports, and calculating totals. This leaves you more time to focus on running your business.</p>*/}
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="notebook-tab-pane" role="tabpanel" aria-labelledby="notebook-tab" tabIndex={0}>
                                        <p className="mt-3">Using accounting software is by far the easiest way to process your transactions and prepare financial statements. The software will automate many tasks for you, such as recording transactions, producing reports, and calculating totals. This leaves you more time to focus on running your business.</p>
                                    </div>
                                    <div className="tab-pane fade" id="transcript-tab-pane" role="tabpanel" aria-labelledby="transcript-tab" tabIndex={0}>
                                        <p className="mt-3">Nullam feugiat, sapien a lacinia condimentum, libero nibh fermentum lectus, eu dictum justo ex sit amet neque. Sed felis arcu, efficitur eget diam eget, maximus dapibus enim. Sed vitae varius lorem. Fusce non accumsan diam, quis porttitor dolor. </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <Footer />

                </div>
                {/*<!--  END CONTENT AREA  -->*/}

            </div>
            {/*<!-- END MAIN CONTAINER -->*/}
        </>
    )
}

export default CoursesView;