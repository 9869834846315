import React from 'react';
import LocalCalendar from '../components/LocalCalendar';

const Modules = () => {
	
	return(
		<div style={{padding:'40px 10px 10px'}}>
			<LocalCalendar />
		</div>
	);
}

export default Modules