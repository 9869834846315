import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import courseService from './courseService';

const initialState = {
	course: null,
	courses: [],
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: "",
};

// add course
export const addCourse = createAsyncThunk (
	"course/addCourse",
	async (courseData, thunkAPI) => {
		try{
			return await courseService.addCourse(courseData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//get all courses
export const getCourses = createAsyncThunk (
	"course/getCourses",
	async (_, thunkAPI) => {
		try{
			return await courseService.getCourses();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get course
export const getCourse = createAsyncThunk (
	"course/getCourse",
	async (id, thunkAPI) => {
		try{
			return await courseService.getCourse(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get course by title
export const getCourseByTitle = createAsyncThunk (
	"course/getCourseByTitle",
	async (title, thunkAPI) => {
		try{
			return await courseService.getCourseByTitle(title);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// upgrade user
export const upgradeCourse = createAsyncThunk (
	"course/upgradeCourse",
	async (courseData, thunkAPI) => {
		try{
			return await courseService.upgradeCourse(courseData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// update course
export const updateCourse = createAsyncThunk (
	"course/updateCourse",
	async ({id, courseData}, thunkAPI) => {
		try{
			return await courseService.updateCourse(id, courseData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// update course section
export const updateCourseSection = createAsyncThunk (
	"course/updateCourseSection",
	async (courseData, thunkAPI) => {
		try{
			return await courseService.updateCourseSection(courseData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// addLocationClassSection
export const addLocationClassSection = createAsyncThunk (
	"course/addLocationClassSection",
	async (locationClassData, thunkAPI) => {
		try{
			return await courseService.addLocationClassSection(locationClassData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//delete course
export const deleteCourse = createAsyncThunk (
	"course/deleteCourse",
	async (id, thunkAPI) => {
		try{
			return await courseService.deleteCourse(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

const courseSlice = createSlice ({
	name: 'course',
	initialState,
	reducers: {
		
	},
	extraReducers: (builder) =>{
		builder
			
			//add course
			.addCase(addCourse.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(addCourse.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.courses = action.payload;
				toast.success('Course Add Successful');
			})
			.addCase(addCourse.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			// get course
			.addCase(getCourse.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getCourse.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.course = action.payload;
			})
			.addCase(getCourse.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			// get course by tilte
			.addCase(getCourseByTitle.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getCourseByTitle.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.course = action.payload;
			})
			.addCase(getCourseByTitle.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//upgrade course
			.addCase(upgradeCourse.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(upgradeCourse.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.courses = action.payload;
				// toast.success(action.payload);
				toast.success('Course Add Successful');
			})
			.addCase(upgradeCourse.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//update course
			.addCase(updateCourse.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(updateCourse.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.course = action.payload;
				toast.success("Course is Updated");
			})
			.addCase(updateCourse.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//update course
			.addCase(updateCourseSection.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(updateCourseSection.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.course = action.payload;
				toast.success("Course is Updated");
			})
			.addCase(updateCourseSection.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//addLocationClassSection
			.addCase(addLocationClassSection.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(addLocationClassSection.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.course = action.payload;
				toast.success("Add Successfully");
			})
			.addCase(addLocationClassSection.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//get all courses
			.addCase(getCourses.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(getCourses.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.courses = action.payload;
			})
			.addCase(getCourses.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

			//delete course
			.addCase(deleteCourse.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(deleteCourse.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = action.payload;
				toast.success(action.payload);
			})
			.addCase(deleteCourse.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

	}
});

export const selectCourse = (state) => state.course.course;

export default courseSlice.reducer;